import { useNewThreadCallback } from "../../../state/api/threads";
import { useEffect } from "react";
import Loading from "../Loading";
import { useParams } from "react-router-dom";
import { useCheckAndSetWorkspace } from "utils/Utils";
import LinkError from "./LinkError";

var creating = false;

export default function CreateChat({ collectionId }) {
    const newThread = useNewThreadCallback();
    const { orgId, collectionId: collId } = useParams();

    const { loading, valid, message } = useCheckAndSetWorkspace(orgId, collId);

    const cId = collId ?? collectionId;
    useEffect(() => {
        async function create() {
            if (!creating) {
                creating = true;
                await newThread(cId);
                creating = false;
            }
        }
        if (valid) create();
    }, [cId, newThread, valid])

    return <>
        {!loading && !valid
            ? <LinkError message={message} />
            : <Loading />
        }
    </>
}
