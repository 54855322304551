import { useGetAllCollectionsQuery } from "state/api/collections";
import Loading from "../Loading";

export default function CollectionsList({ orgId }) {
    const { data: collections, isFetching: isFetchingCollections } = useGetAllCollectionsQuery({ org: orgId, validate_admin: true }, { skip: !orgId });

    return <div className="mt-3">
        {isFetchingCollections
            ? <Loading />
            : <div className="flex flex-wrap gap-y-1 text-blue-lightest">
                {collections.map((c, idx) => <span className="w-1/2" key={idx}>{c.name}</span>)}
            </div>
        }
    </div>
}