import { useState, useRef, useCallback, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder-2";
import WaveVisualizer from "./WaveVisualizer";
import { PlayIcon, PauseIcon, MicrophoneIcon, PauseCircleIcon } from '@heroicons/react/24/outline'
import Crunker from "crunker";
import microphoneService from "../utils/MicrophoneService";
import ProgressBar from "./ProgressBar";

function parseDuration(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = (duration % 60).toFixed(0);
    return String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
}

export default function AudioRecorder({ isRecording = false, onRecordingStarted = () => { }, onRecordingStopped = () => { }, onLoadedMetadata = () => { } }) {
    const audioRef = useRef();
    const timer = useRef();
    const [blobUrl, setBlobUrl] = useState();
    const [blobDuration, setBlobDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playingTime, setPlayingTime] = useState(0);

    const onStart = useCallback(() => {
        //setBlobDuration(0);
        timer.current = setInterval(() => {
            setBlobDuration((prev) => prev + 1);
        }, 1000);
        onRecordingStarted();
    }, [onRecordingStarted])

    const onStop = useCallback((newUrl) => {
        clearInterval(timer.current);
        const audios = blobUrl ? [blobUrl, newUrl] : [newUrl];
        const crunker = new Crunker();
        crunker
            .fetchAudio(...audios)
            .then((buffers) => crunker.concatAudio(buffers))
            .then((merged) => crunker.export(merged, "audio/webm"))
            .then((output) => { setBlobUrl(output.url); onRecordingStopped(output.url, output.blob) })
            .catch((error) => {
                console.error(error);
            });
    }, [blobUrl, onRecordingStopped]);

    const { status, startRecording, stopRecording, previewAudioStream, clearBlobUrl } =
        useReactMediaRecorder({ audio: true, video: false, onStart: onStart, onStop: onStop });


    useEffect(() => {
        if (isRecording && microphoneService.isMicrophoneAvailable()) {
            microphoneService.setMicrophoneAvailable(false);
            startRecording();
        }
    }, [isRecording, startRecording]);


    useEffect(() => {
        return () => {
            stopRecording();
            clearBlobUrl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <div className="text-white flex items-center gap-x-1 w-full h-8">
        <audio
            src={blobUrl}
            ref={audioRef}
            preload="metadata"
            onLoadedMetadata={(e) => { setBlobDuration(e.target.duration); onLoadedMetadata(blobDuration); }}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onTimeUpdate={() => setPlayingTime(audioRef.current.currentTime)}
        />

        <button
            type="button"
            className="text-blue-lightest hover:text-white disabled:text-gray-dark"
            disabled={status === 'recording' || blobDuration === 0}
            onClick={() => isPlaying ? audioRef.current.pause() : audioRef.current.play()}
        >
            {isPlaying
                ? <PauseIcon className="w-6" />
                : <PlayIcon className="w-6" />
            }
        </button>
        <div className="mr-1 select-none">{parseDuration(isPlaying ? audioRef.current.currentTime : blobDuration)}</div>
        <div className="w-full h-full py-1">
            {status === 'recording'
                ? <WaveVisualizer audioStream={previewAudioStream} />
                : <div className="w-full h-full rounded-full bg-blue-light flex items-center">
                    <ProgressBar height="h-[4px]" color="bg-black" showValue={false} transition={false} value={playingTime} max={blobDuration} onSeeked={(p) => audioRef.current.currentTime = blobDuration * p} />
                </div>
            }
        </div>
        <button type="button" className="text-orange hover:text-orange-pastel">
            {status === 'recording'
                ? <PauseCircleIcon onClick={() => { stopRecording(); }} className="w-6" />
                : <MicrophoneIcon onClick={() => { startRecording(); }} className="w-6" />
            }
        </button>
    </div>
};