import React, { useCallback, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useUploadFileMutation, useUploadRecMutation, useUploadWorkingMemoryFileMutation } from "../../../state/api/collections";
import Buttoon from "../../Buttoon";
import { useCurrentCollectionId, useCurrentOrganizationId } from "../../../state/GeneralSlice";
import TasksViewer from "../tasks/TasksViewer";
import { getNameAndExtension } from "utils/Utils";

const MAX_CONCURRENT_UPLOADS = 3;
const semaphore = require("semaphore")(MAX_CONCURRENT_UPLOADS);

const docFiles = ["PDF", "DOCX", "PPTX", "TXT", "MD", "XLSX", "CSV"];
const audioFiles = ["MP3"];
const fileTypes = [...docFiles, ...audioFiles];

const docDescription = "PDF - DOCX - PPTX - TXT - MarkDown - XLSX - CSV";
const audioDescription = "MP3";

function readFilename(file) {
    const res = getNameAndExtension(file.name);
    return {
        document_title: res.name,
        document_type: res.extension,
    }
}

function DragDrop({ onClose = () => { }, threadId = null }) {
    const [dragClasses, setDragClasses] = useState("");
    const [files, setFiles] = useState([]);
    const [filenames, setFilenames] = useState([]);

    const handleChange = useCallback((file) => {
        const newFiles = Object.values(file);
        setFiles((prev) => {
            if (prev) {
                return [...prev, ...newFiles];
            }
            else
                return newFiles;
        });
        const newNames = [];
        newFiles.forEach((file) => {
            newNames.push(readFilename(file));
        })
        setFilenames((prev) => {
            if (prev) {
                return [...prev, ...newNames];
            }
            else {
                return newNames;
            }
        })
    }, [setFiles]);

    const handleDelete = useCallback((idx) => {
        setFilenames((prev) => prev.filter((el, i) => i !== idx));
        setFiles((prev) => prev.filter((el, i) => i !== idx));
    }, [])

    const orgId = useCurrentOrganizationId();
    const collId = useCurrentCollectionId();
    const [uploadFile] = useUploadFileMutation();
    const [uploadRec] = useUploadRecMutation();
    const [otherUploadFile] = useUploadWorkingMemoryFileMutation();
    const handleUpload = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        files.forEach((item, idx) => {
            semaphore.take(() => {
                const isAudio = audioFiles.includes(filenames[idx].document_type.toUpperCase());

                if (threadId !== null)
                    otherUploadFile({ collection_id: collId, params: { current_org_uuid: orgId, thread_uuid: threadId, audio: isAudio }, body: { title: filenames[idx].document_title.trim(), file: item } });
                else {
                    if (isAudio) uploadRec({ current_org_uuid: orgId, collection_id: collId, title: filenames[idx].document_title.trim(), rec: item })
                    else uploadFile({ current_org_uuid: orgId, collection_id: collId, title: filenames[idx].document_title.trim(), file: item });
                }
                semaphore.leave();
            });
        })
        onClose();
    }, [collId, filenames, files, onClose, orgId, otherUploadFile, threadId, uploadFile, uploadRec]);

    return (
        <div className="text-white">
            <FileUploader multiple={true} handleChange={handleChange} name="file" types={fileTypes} maxSize={10 * 1024} minSize={0} classes="disabled:opacity-20">
                <div className="flex items-center justify-center w-full">
                    <label htmlFor="dropzone-file"
                        className={"flex flex-col items-center justify-center w-full h-64 border-2 border-white border-dashed rounded-lg cursor-pointer hover:bg-blue-light bg-blue " + dragClasses}
                        onDragEnter={() => setDragClasses("bg-blue-light")}
                        onDragLeave={() => setDragClasses("")}
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6 pointer-events-none">
                            <svg aria-hidden="true" className="w-10 h-10 mb-3" fill="none"
                                stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p className="mb-2 text-sm">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p className="text-xs mb-1">{docDescription}</p>
                            <p className="text-xs">{audioDescription}</p>
                        </div>
                        <input name="dropzone-file" id="dropzone-file" type="file" className="hidden" multiple={true} />
                    </label>
                </div>
            </FileUploader>
            {files.length > 0 &&
                <div className="mt-4">
                    <p className="font-bold text-sm">Selected Files:</p>
                    <TasksViewer files={filenames} setFiles={setFilenames} onDelete={handleDelete} />
                </div>
            }
            <form onSubmit={handleUpload} className="text-center mt-4">
                <div className="flex items-center justify-end mt-2 gap-x-2">
                    <Buttoon
                        type="submit"
                        disabled={files.length === 0}
                        width="w-16"
                        className="shrink-0"
                    >
                        Upload
                    </Buttoon>
                    <Buttoon
                        type="button"
                        onClick={onClose}
                        width="w-16"
                        className="shrink-0"
                        disabled={false}
                    >
                        Cancel
                    </Buttoon>
                </div>
            </form>
        </div>
    );
}

export default DragDrop;