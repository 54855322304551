import React, { useCallback } from 'react';
import Modal from './Modal';
import Buttoon from '../Buttoon';

export default function ConfirmationDialog({ title, text, confirmText, cancelText, show, setShow, onClose, onConfirm }) {
    const titleString = title || "Confirm";
    const textString = text || "Are you sure you want to proceed?";
    const confirmString = confirmText || "Delete";
    const cancelString = cancelText || "Cancel";

    const handleAction = useCallback((confirm) => {
        setShow?.(false);
        onClose?.(confirm);
        if (confirm) onConfirm?.();
    }, [onClose, onConfirm, setShow]);

    return <Modal show={show}>
        <form onSubmit={() => handleAction(true)} className="w-auto mx-auto">
            <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">{titleString}</p>
            <div className="border-t-2 border-blue my-2"></div>

            <p className="mt-3 text-blue-lightest">{textString}</p>

            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="button"
                    width="w-16"
                    classNameOverride="bg-blue text-sm text-white rounded-md w-16 py-2 bg-orange hover:bg-orange-pastel shrink-0"
                    onClick={() => handleAction(true)}
                >
                    {confirmString}
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => handleAction(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    {cancelString}
                </Buttoon>
            </div>
        </form>
    </Modal>
}