import { useMemo } from "react";
import { useCurrentCollectionId } from "../../../state/GeneralSlice";
import { useGetThreadFromUuidQuery } from "../../../state/api/threads";
import { useIsWorkingDocs, useGetCollectionFeedbackThreadQuery } from "../../../state/api/collections";
import ScrollToBottom from 'react-scroll-to-bottom';
import WorkingFileList from "../chat/WorkingFileList";
import Message from "./Message";
import Loading from "../Loading";

export default function History({ uuid, feedbackThreadId, editorRef }) {
    const { data: chat, isLoading: isLoadingChat } = useGetThreadFromUuidQuery({ thread_uuid: uuid }, { skip: !uuid })
    const isWorkingDocs = useIsWorkingDocs();

    const collId = useCurrentCollectionId();
    const { data: feedbacks, isLoading: isLoadingFeedbacks } = useGetCollectionFeedbackThreadQuery({ collection_id: collId, thread_uuid: feedbackThreadId }, { skip: !collId || !feedbackThreadId })

    const isLoading = useMemo(() => isLoadingChat || isLoadingFeedbacks, [isLoadingChat, isLoadingFeedbacks]);
    const history = useMemo(() => chat?.messages || feedbacks?.messages || [], [chat, feedbacks]);
    const userId = useMemo(() => chat?.user_uuid || feedbacks?.user_uuid || "", [chat, feedbacks]);

    return <ScrollToBottom debounce={50} id="messages" className="flex-grow flex-shrink space-y-4 overflow-y-auto text-white">
        {isLoading
            ? <Loading />
            : <>
                {isWorkingDocs && <WorkingFileList threadID={uuid} collectionID={collId} />}
                {history && history.map((item, idx) => <Message key={idx} userId={userId} item={item} history={history} checkFeedbacks={!!feedbackThreadId} editorRef={editorRef} />)}
            </>}
    </ScrollToBottom>
}