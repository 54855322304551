import { ClipboardDocumentListIcon, ClipboardDocumentIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { TrashIcon } from '@heroicons/react/24/solid'
import { SenderType, useCurrentCollectionId } from "../../../state/GeneralSlice";
import { useCopyAsTableQuery, useDeleteMessageMutation, useKillThreadMutation, useIsArchivedThread } from "../../../state/api/threads";
import classNames from "classnames";
import { useGetModelNameQuery, useGetModelPicQuery, useIsExcel } from "../../../state/api/collections";
import { useGetUserDetailsQuery } from "state/api/users";
import ConfirmationDialog from "../ConfirmationDialog";
import Loading from "../Loading";
import MarkdownMessage from "../markdown/MarkdownMessage";
import { INSERT_QUOTE_COMMAND } from "components/lexical/plugin/quote/QuotePlugin";
import toast from 'react-hot-toast';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Sources from "./Sources";
import MessageFeedback from './MessageFeedback';
import Recommendations from './Recommendations';
import MessageActions from './MessageActions';
import EditMessageButton from './EditMessageButton';

const MessageProgress = ({ className, progress = 0.5 }) => {
    const ref = useRef();

    useEffect(() => {
        const context = ref.current.getContext('2d');

        const a = 2 * Math.PI * progress;
        const x = 50 + Math.sin(a) * 50;
        const y = 50 - Math.cos(a) * 50;

        context.clearRect(0, 0, 100, 100);
        context.beginPath();
        context.moveTo(50, 50);
        context.lineTo(x, y);
        context.arc(50, 50, 50, a - Math.PI / 2., Math.PI * 1.5);
        context.closePath();

        context.fillStyle = "#000000D0";
        context.fill();
    }, [progress]);

    return <canvas
        ref={ref}
        width={100}
        height={100}
        alt="Progress ndicator"
        className={classNames(className, "w-8 h-8 rounded-full")}
    />
}

function MessageSelection({ message, senderIsUser, children }) {
    const enabled = useMemo(() => message.cmetadata?.selection?.enabled, [message.cmetadata?.selection?.enabled])
    const description = useMemo(() => message.cmetadata?.selection?.description, [message.cmetadata?.selection?.description])

    const selectionColor = enabled ? "border-yellow" : "border-transparent";
    const corner = senderIsUser === undefined ? "rounded-b-lg" : (senderIsUser ? "rounded-bl-lg" : "rounded-br-lg");
    const backgroundColor = "bg-blue";
    const height = "h-2";

    return <div className="w-full">
        {!!description && <div className={classNames("flex items-end border-transparent", { "border-x-2": !enabled })}>
            <div className={classNames("flex-1 border-2 border-b-0 border-r-0 rounded-tl-lg", height, backgroundColor, selectionColor)} />
            <div className={classNames("relative", { "px-1": !!description })}>
                <p className="text-sm">{description}</p>
                <div className={classNames("w-full left-0 bottom-0 -z-10 absolute", height, backgroundColor)} />
            </div>
            <div className={classNames("w-4 border-2 border-b-0 border-l-0 rounded-tr-lg", height, backgroundColor, selectionColor)} />
        </div>
        }
        <div className={classNames("border-2", { "rounded-t-lg": !description }, { "border-t-0": description }, corner, selectionColor)}>
            {children}
        </div>
    </div >
}

//****************************
const ChatMessage = ({ userId, item, history, checkFeedbacks = false, editorRef }) => {
    const { data: user } = useGetUserDetailsQuery({ user_uuid: userId }, { skip: !userId });
    const collId = useCurrentCollectionId();
    const { data: modelPic } = useGetModelPicQuery({ collection_id: collId });
    const { data } = useGetModelNameQuery({ collection_id: collId });

    const senderIsUser = item.sender_type === SenderType.USER;
    const senderClassNamesFirstDiv = senderIsUser ? "justify-end" : "";
    const senderClassNamesSecondDiv = senderIsUser ? "order-1 items-end" : "order-2 items-start";
    const senderClassNamesMessage = senderIsUser ? "rounded-br-none pb-2" : "rounded-bl-none";
    const senderClassNamesImage = senderIsUser ? "order-2" : "order-1";

    const senderName = useMemo(() => {
        return data || item.sender
    }, [data, item.sender]);

    const userName = useMemo(() => checkFeedbacks ? `${user?.name}` : "You", [checkFeedbacks, user?.name])

    const hasFeedback = useMemo(() => !senderIsUser && !(item.feedback_value === null || item.feedback_value === undefined), [item.feedback_value, senderIsUser]);

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteMessage] = useDeleteMessageMutation();
    const onConfirmDelete = useCallback((confirm) => {
        if (!confirm) return;
        deleteMessage({ thread_uuid: item.thread_uuid, message_uuid: item.uuid });

        let idx = history.indexOf(item) + 1;
        let answer = history[idx];
        while (answer && answer.sender_type === "llm") {
            deleteMessage({ thread_uuid: answer.thread_uuid, message_uuid: answer.uuid });
            answer = history[++idx];
        }
    }, [deleteMessage, history, item]);

    const [killThread] = useKillThreadMutation();

    const streamEnded = useMemo(() => !senderIsUser && (item.created || item.ended === true), [item.created, item.ended, senderIsUser]);

    const isExcel = useIsExcel();
    const { data: dataAsTable } = useCopyAsTableQuery({ thread_uuid: item.thread_uuid, message_uuid: item.uuid }, { skip: !isExcel || !streamEnded });

    const avatarSrc = useMemo(() => senderIsUser ? user?.picture : modelPic, [modelPic, senderIsUser, user?.picture]);

    const senderColor = useMemo(() => senderIsUser ? "text-azure-pastel" : "text-orange-pastel", [senderIsUser])

    const canDelete = item.cmetadata?.can_delete ?? true;

    const ref = useRef();
    const reply = useCallback((e) => {
        e.preventDefault();
        var messageText = ref.current.textContent;

        [...document.querySelectorAll("#message_" + item.uuid + " blockquote")]
            .forEach(e => messageText = messageText.replace(e.textContent, ""));

        const selection = document.getSelection().toString() || messageText.trim();

        editorRef.current.dispatchCommand(INSERT_QUOTE_COMMAND, {
            text: selection,
            messageId: item.uuid
        });
    }, [editorRef, item.uuid])

    const { data: isArchived } = useIsArchivedThread(item.thread_uuid);

    const copyMessage = useCallback((message, text) => {
        navigator.clipboard.writeText(text);
        toast(message);
    }, [])

    const selectionDescription = item.cmetadata?.selection?.description;

    const editable = item.cmetadata?.editable;
    const edited = item.cmetadata?.edited;

    return <div className="chat-message px-2 pb-2" >
        <div className={"flex items-end " + senderClassNamesFirstDiv}>
            <div className={classNames("relative flex flex-col space-y-2 text-sm max-w-[75%] mx-2", senderClassNamesSecondDiv, checkFeedbacks && hasFeedback ? "w-[75%]" : "max-w-[75%]")}>
                <MessageSelection message={item} senderIsUser={senderIsUser} >

                    <div className={classNames(
                        "px-4 pt-2 pb-1 rounded-b-lg inline-block w-full relative group",
                        {
                            "rounded-t-lg": !selectionDescription,
                            "bg-container-chat-message-you": senderIsUser,
                            "bg-container-chat-message-not-you": !senderIsUser
                        },
                        senderClassNamesMessage
                    )}
                    >
                        {senderIsUser && canDelete && !isArchived &&
                            <div className="absolute right-full top-0 h-full flex items-center invisible group-hover:visible">
                                <TrashIcon className="w-6 h-6 mr-1 text-blue-light hover:text-orange cursor-pointer" onClick={() => setShowConfirmDelete(true)} />
                                <ConfirmationDialog show={showConfirmDelete} setShow={setShowConfirmDelete} onClose={onConfirmDelete} />
                            </div>
                        }
                        <div className="flex gap-x-4">
                            <p className={"text-xs font-bold " + senderColor}>
                                {senderIsUser ? userName : senderName}
                            </p>
                            {(streamEnded || senderIsUser) && !isArchived &&
                                <ArrowUturnLeftIcon
                                    title="Reply"
                                    className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                                    onMouseDown={reply}
                                />
                            }
                            {streamEnded && editable && <EditMessageButton message={item} content={editable} />}
                            <span className="flex-grow" />
                            <div className="flex gap-x-1">
                                {streamEnded && <>
                                    {item?.cmetadata?.sources && <Sources sources={item.cmetadata.sources} />}
                                    {item?.cmetadata?.recommendations && <Recommendations recommendations={item.cmetadata.recommendations} />}
                                    {dataAsTable && <ClipboardDocumentListIcon
                                        title="Copy as Table"
                                        className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                                        onClick={() => { copyMessage("Message copied as table", dataAsTable) }}
                                    />}
                                </>}
                                {(streamEnded || senderIsUser) && <ClipboardDocumentIcon
                                    title="Copy"
                                    className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                                    onClick={() => { copyMessage("Message copied", ref.current.textContent.trim()) }}
                                />}
                            </div>
                        </div>
                        {!item.body && <p className="font-thin text-sm italic">{senderName} is thinking...</p>}
                        <span id={"message_" + item.uuid} ref={ref}><MarkdownMessage md={item.body} metadata={item.cmetadata} /></span>
                        <div className="flex justify-between items-end">
                            {!streamEnded && !senderIsUser && <button
                                title="Cancel task"
                                className="text-xs text-blue-lightest -translate-x-3 hover:text-white"
                                onClick={() => killThread({ thread_uuid: item.thread_uuid })}
                            >
                                Cancel
                            </button>}
                            {!senderIsUser && edited && <span className='text-xs italic flex-1 text-gray-dark'>Edited by user</span>}
                            {!senderIsUser && <MessageFeedback item={item} hasFeedback={hasFeedback} checkFeedbacks={checkFeedbacks} />}
                        </div>
                        <div className="flex items-center gap-x-2 p-2">
                            {!senderIsUser && <div className='flex-1' />}
                            <MessageActions message={item} />
                            {senderIsUser && <div className='flex-1' />}
                        </div>
                    </div>
                </MessageSelection>
            </div>
            <div className={classNames("relative", senderClassNamesImage)}>
                {avatarSrc
                    ? <img
                        src={avatarSrc}
                        alt={item.sender}
                        className="w-8 h-8 rounded-full"
                    />
                    : <Loading size="w-[32px] h-[32px]" stroke="border-[3px]" />
                }
                {item.progress && item.progress.finished === false && <>
                    <MessageProgress className="absolute top-0" progress={item.progress.value} />
                    <Loading className="absolute z-50 -top-[2px] -left-[2px]" size="w-[36px] h-[36px]" stroke="border-[3px]" />
                </>}
            </div>
        </div>
    </div>
}

const Empty = ({ children }) => {
    return <>
        {children}
    </>
}

const Banner = ({ item }) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [deleteMessage] = useDeleteMessageMutation();

    const onConfirmDelete = useCallback((confirm) => {
        if (!confirm) return;
        deleteMessage({ thread_uuid: item.thread_uuid, message_uuid: item.uuid });
    }, [deleteMessage, item]);


    const { data: isArchived } = useIsArchivedThread(item.thread_uuid);
    const canDelete = item.cmetadata?.can_delete ?? true;

    const isSelected = item.cmetadata?.selection?.enabled;
    const selectionDescription = item.cmetadata?.selection?.description;
    const Selection = isSelected ? MessageSelection : Empty;

    const img = item.cmetadata?.thumbnail_image;

    return <div className="group w-full flex gap-x-2 my-2 px-2">
        {canDelete && !isArchived &&
            <div className="flex items-center invisible group-hover:visible">
                <TrashIcon className="w-6 h-6 text-blue-light hover:text-orange cursor-pointer" onClick={() => setShowConfirmDelete(true)} />
                <ConfirmationDialog show={showConfirmDelete} setShow={setShowConfirmDelete} onClose={onConfirmDelete} />
            </div>
        }
        <Selection message={item} >
            <div className={classNames(
                "flex flex-1 flew-row gap-x-2 bg-blue items-center justify-between text-center rounded-lg p-2",
                { "border-azure-pastel border-[1px]": !isSelected },
                { "rounded-t-none": isSelected && selectionDescription },
            )}
            >
                {!!img && <div className="w-1/12 shrink-0">
                    <MarkdownMessage md={img} />
                </div>}
                <div className="mx-auto">
                    <MarkdownMessage md={item.body} metadata={item.cmetadata} />
                </div>
                <MessageActions message={item} />
            </div>
        </Selection>
    </div>
}

export default function Message({ userId, item, history, checkFeedbacks, editorRef }) {
    switch (item.sender_type) {
        case "banner": return <Banner item={item} />
        default: return <ChatMessage userId={userId} item={item} history={history} checkFeedbacks={checkFeedbacks} editorRef={editorRef} />
    }
}