import React, { useEffect } from "react";
import ComboBox from "../../ComboBox";
import { useIsOrgAdacto, useIsOrgMarchesini, useIsOrgZenodoto } from "../../../state/GeneralSlice";

// @ts-ignore
import { ReactComponent as SoloMeoLogo } from "./solomeo-logo.svg";
// @ts-ignore
import marchesiniLogo from "./marchesini-logo.png";
import GetDefaultLogo from "./defaultLogo";
import classNames from "classnames";

const ThemeTypes = {
    panna: "Panna (light theme)",
    blu: "Blu (dark theme)",
    default: "Default theme (system)"
};

type ThemeSelected = keyof typeof ThemeTypes;

export function useTheme() {
    const [theme, setTheme] = React.useState<ThemeSelected>("default");

    useEffect(() => {
        const initialDataTheme = document.body.getAttribute("data-theme");
        if (initialDataTheme && initialDataTheme in ThemeTypes) {
            setTheme(initialDataTheme as ThemeSelected);
        }
    }, []);

    const handleThemeChange = (theme: string) => {
        document.body.setAttribute("data-theme", theme);
        localStorage.setItem("data-theme", theme);
        setTheme(theme as ThemeSelected);
    };

    return { theme, handleThemeChange };
}

export function Logo({ isSidebar = true }) {
    // TODO: TEMPORARY IMPLEMENTATION
    // TODO: Refactor this from build stage

    const isAdactoOrg = useIsOrgAdacto();
    const isBrunelloOrg = useIsOrgZenodoto();
    const isMarchesiniOrg = useIsOrgMarchesini();

    if (isAdactoOrg) {
        return <></>;
    }

    if (isBrunelloOrg) {
        return <SoloMeoLogo className={"fill-white h-16 w-auto mx-auto"} />;
    }

    if (isMarchesiniOrg) {
        return (
            <div className={classNames("h-full mx-auto", { "p-4": isSidebar })}>
                <img src={marchesiniLogo} alt="logo" className="h-full"></img>
            </div>
        );
    }

    return <div className="flex h-16 items-center w-full">{GetDefaultLogo()}</div>;
}

export function ThemeProvider() {
    const { theme, handleThemeChange } = useTheme();
    const isOrgZenodoto = useIsOrgZenodoto();

    useEffect(() => {
        function getFromLocalStorage(): ThemeSelected {
            const theme = localStorage.getItem("data-theme");
            if (theme) {
                return theme as ThemeSelected;
            } else {
                return "default";
            }
        }

        function fromOrganizationRules(): ThemeSelected {
            if (isOrgZenodoto) return "panna";
            return "default";
        }

        function handleOnChange() {
            // se user default -> organization default else user theme else system default theme
            const user = getFromLocalStorage();
            const organization = fromOrganizationRules();

            //console.log(user, organization, "user, organization");

            if (user !== "default") return handleThemeChange(user);
            if (organization !== "default") return handleThemeChange(organization);
        }

        handleOnChange();
    }, [handleThemeChange, isOrgZenodoto, theme]);
}

export function ThemeCombobox() {
    const { theme, handleThemeChange } = useTheme();

    return (
        <ComboBox
            label="Seleziona il tema"
            initialValue={theme}
            items={Object.keys(ThemeTypes)}
            tools=""
            descriptions={Object.values(ThemeTypes)}
            onSelected={handleThemeChange}
        />
    );
}
