import Loading from "../Loading";
import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { useDeleteWorkingMemoryFileMutation, useGetWorkingMemoryDataQuery, useLazyGetWorkingMemoryFileLinkQuery, useUpdateWorkingMemoryDataMutation, useUploadWorkingMemoryFileMutation } from "state/api/collections";
import { ReactGrid } from "@silevis/reactgrid";
import "./ExcelVisualizer.scss"
import { TextAreaCellTemplate } from "./TextAreaCellTemplate";
import { getNameAndExtension } from "utils/Utils";
import { StartChatCellTemplate } from "./StartChatCellTemplate";
import { ArrowDownTrayIcon, ArrowUpTrayIcon, TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ConfirmationDialog from "../ConfirmationDialog";
import classNames from "classnames";
import { FilteringHeaderCellTemplate } from "./FilteringHeaderCellTemplate";
import { MarkdownCellTemplate } from "./MarkdownCellTemplate";
import ProgressBar from "components/ProgressBar";
import { useDocumentTask } from "state/TasksSlice";
import TaskPoller from "components/TaskPoller";

function FilterRow({ colName, colIdx, value, setValue }) {
    const updateFilter = useCallback((v) => {
        setValue(prev => {
            const tmp = [...prev];
            const col = tmp.find(v => v.colIdx === colIdx);
            col.filter = v;
            return tmp;
        })
    }, [colIdx, setValue])
    const removeFilter = useCallback(() => {
        setValue(prev => prev.filter(v => v.colIdx !== colIdx));
    }, [colIdx, setValue])

    useEffect(() => {
        document.querySelector("input#col" + colIdx).focus();
    }, [colIdx])

    return <div className="md:flex items-center bg-gray-darkest rounded-md p-1 m-1 gap-x-1">
        <div className="flex items-center md:w-1/5 mb-1 md:mb-0 gap-x-2">
            <button onClick={removeFilter} title="Remove" className="text-blue-light hover:text-white">
                <XCircleIcon className="h-6 w-6" />
            </button>
            <span className="whitespace-nowrap">{colName}</span>
        </div>
        <input
            id={"col" + colIdx}
            type="text"
            value={value}
            onChange={(e) => updateFilter(e.target.value)}
            className="max-md:w-full md:flex-1 h-9 rounded-md bg-blue py-1.5 pl-3 pr-10 text-left text-white shadow-sm border-0 focus:ring-0"
        />
    </div>
}

export default function ExcelVisualizer({ orgId, collId }) {
    const { data: file, isLoading, refetch } = useGetWorkingMemoryDataQuery({ collection_id: collId }, { refetchOnMountOrArgChange: true });

    const data = file?.data;
    const columnsNames = useMemo(() => file?.column_names ?? [], [file?.column_names]);
    const columnsWidth = useMemo(() => file?.column_default_widths ?? [], [file?.column_default_widths]);
    const columnsFiltrable = useMemo(() => file?.column_filterable ?? [], [file?.column_filterable]);

    const [filteringColumns, setFilteringColumns] = useState([]);
    const addFilter = useCallback((colIdx) => {
        if (filteringColumns.find(v => v.colIdx === colIdx)) {
            document.querySelector("input#col" + colIdx).focus();
        }
        else {
            setFilteringColumns(prev => [...prev, { colIdx, filter: "" }])
        }
    }, [filteringColumns])

    const cellsRef = useRef([]);
    const [initialized, setInitialized] = useState(false);
    const [rowHeights, setRowHeights] = useState([]);
    const onCellHeightChanged = useCallback((rowIdx, colIdx, height) => {
        if (!cellsRef.current[rowIdx]) {
            cellsRef.current[rowIdx] = new Array(6);
            cellsRef.current[rowIdx].fill(0);
        }

        const timeout = initialized ? 50 : 500;
        setInitialized(true);

        cellsRef.current[rowIdx][colIdx] = height;
        setTimeout(() => {
            setRowHeights(prev => {
                const tmp = [...prev];
                tmp[rowIdx] = Math.max(...cellsRef.current[rowIdx]);
                return tmp;
            })
        }, timeout);

    }, [initialized]);

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        const c = [];
        for (let i = 0; i < columnsWidth.length; i++) {
            c.push({
                columnId: i,
                width: columnsWidth[i],
                resizable: true,
            })
        }
        setColumns(c);
    }, [columnsWidth])

    const filteredContent = useMemo(() => {
        if (!data) return [];
        const filters = {};
        for (let c = 0; c < columnsNames.length; c++) {
            filters[c] = filteringColumns.find(el => el.colIdx === c)?.filter.toLowerCase() ?? "";
        }

        const getType = (action) => {
            switch (action) {
                case "create_thread": return "startchat";
                case "markdown": return "markdown";
                default: return "textarea"
            }
        }

        const content = [];
        data.forEach((el, i) => {
            let isToAdd = true;

            for (let j = 0; j < el.length; j++) {
                const cell = el[j];
                const filter = filters[j];
                if (!cell.value.toLowerCase().includes(filter)) {
                    isToAdd = false;
                    break;
                }
            }

            if (isToAdd) {
                content.push({
                    rowId: i,
                    height: rowHeights[i],
                    //height: 800,
                    cells: el.map((v, k) => ({
                        type: getType(v.action),
                        text: v.value,
                        filter: filters[k],
                        style: {
                            padding: 0,
                        },
                        rowIdx: i,
                        colIdx: k,
                        onHeightChanged: onCellHeightChanged,
                    })),
                })
            }
        });
        return content;
    }, [columnsNames, data, filteringColumns, onCellHeightChanged, rowHeights])

    const [rows, setRows] = useState([]);
    useEffect(() => {
        if (!data) setRows([]);
        else {
            setRows([
                {
                    rowId: "header",
                    height: 32,
                    cells: columnsNames.map((c, i) => ({
                        type: "filteringheader",
                        text: c,
                        rowIdx: -1,
                        colIdx: i,
                        filtrable: columnsFiltrable[i] ?? false,
                        addFilter: addFilter,
                        style: { "backgroundColor": "var(--gray-darkest)", padding: 0 }
                    })),
                },
                ...filteredContent
            ])
        }
    }, [addFilter, columnsFiltrable, columnsNames, data, filteredContent]);

    const handleColumnResize = useCallback((ci, width) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }, []);

    const [updateWorkingMemory] = useUpdateWorkingMemoryDataMutation();
    const handleChanges = useCallback((changes) => {
        const tmp = changes.map(el => ({ row: el.rowId, col: el.columnId, value: el.newCell.text }));
        const payload = tmp[tmp.length - 1];
        console.log("cambio valore", tmp, payload);
        updateWorkingMemory({ collection_id: collId, body: [payload] });
    }, [collId, updateWorkingMemory]);

    const [getDownloadLink] = useLazyGetWorkingMemoryFileLinkQuery();
    const downloadFile = useCallback(async () => {
        const response = await getDownloadLink({ collection_id: collId, params: { current_org_uuid: orgId } });
        if (response.data) {
            const link = response.data;
            const filename = getFilename(link);
            if (window.showSaveFilePicker) {
                try {
                    const handle = await window.showSaveFilePicker({
                        excludeAcceptAllOption: true,
                        suggestedName: filename,
                        types: [{
                            description: "Spreadsheet document",
                            accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }
                        }]
                    });
                    const writable = await handle.createWritable();
                    const file = await fetch(response.data).then(r => r.blob());
                    console.log(file);
                    await writable.write(file);
                    await writable.close();
                }
                catch (error) { }
            }
            else {
                const save = document.createElement("a");
                save.href = response.data;
                save.download = filename;
                save.click();
            }
        }
        else {
            console.log("Error", response.error);
        }
    }, [collId, getDownloadLink, orgId]);

    const [showDelete, setShowDelete] = useState(false);
    const [showReplace, setShowReplace] = useState(false);
    const [deleteData] = useDeleteWorkingMemoryFileMutation();
    const [error, setError] = useState();

    const inputRef = useRef();
    const [taskInfo, setTaskInfo] = useState();
    const isUploading = useMemo(() => !!taskInfo, [taskInfo]);
    const [uploadFile] = useUploadWorkingMemoryFileMutation();
    const onUpload = useCallback(async (e) => {
        const toUpload = e.target.files[0];
        if (toUpload) {
            setTaskInfo({});
            setError(undefined);
            if (file) await deleteData({ collection_id: collId });
            const name = getNameAndExtension(toUpload.name).name;
            const response = await uploadFile({ collection_id: collId, params: { current_org_uuid: orgId }, body: { title: name, file: toUpload } }).unwrap();
            if (response.status === "fail") {
                setError(response.message);
                setTaskInfo(undefined);
            }
            else {
                setTaskInfo(response);
            }
        }
    }, [collId, deleteData, file, orgId, uploadFile]);

    const uploadSucceeded = useCallback(async () => {
        await refetch();
        setTaskInfo(undefined);
    }, [refetch])

    return <div className="p-2 h-full max-h-full flex flex-col">
        <div className="flex justify-between items-center">
            <span className="text-3xl text-blue-lightest">Manage Your Data</span>
            {file &&
                <div className="flex items-center gap-x-4 text-blue-lightest pr-3">
                    <button title="Download File" onClick={downloadFile}>
                        <ArrowDownTrayIcon className="w-8 hover:text-white" />
                    </button>
                    <button title="Upload File" onClick={() => setShowReplace(true)}>
                        <ArrowUpTrayIcon className="w-8 hover:text-white" />
                    </button>
                    <button title="Delete Data" onClick={() => setShowDelete(true)}>
                        <TrashIcon className="w-8 hover:text-white" />
                    </button>
                </div>
            }
            <ConfirmationDialog show={showReplace} setShow={setShowReplace} onConfirm={() => inputRef.current.click()} title="Warning" text="Current data will be replaced" confirmText="Proceed" />
            <ConfirmationDialog show={showDelete} setShow={setShowDelete} onConfirm={() => deleteData({ collection_id: collId })} />
        </div>
        {isLoading || isUploading
            ? <>
                <Loading />
                {isUploading && <UploadProgress taskInfo={taskInfo} onSuccess={uploadSucceeded} />}
            </>
            : <>
                {file
                    ? <>
                        <div id="filter" className="mt-6 mb-4">
                            {filteringColumns.map(({ colIdx, filter }, idx) =>
                                <FilterRow key={idx} colIdx={colIdx} colName={columnsNames[colIdx]} value={filter} setValue={setFilteringColumns} />)
                            }
                        </div>
                        <div id="spreadsheet" className="overflow-auto bg-gray-dark flex-1 w-full">
                            <ReactGrid
                                enableFullWidthHeader
                                stickyTopRows={1}
                                rows={rows}
                                columns={columns}
                                onColumnResized={handleColumnResize}
                                onCellsChanged={handleChanges}
                                customCellTemplates={{
                                    textarea: new TextAreaCellTemplate(),
                                    startchat: new StartChatCellTemplate(),
                                    filteringheader: new FilteringHeaderCellTemplate(),
                                    markdown: new MarkdownCellTemplate(),
                                }}
                            />
                        </div>
                    </>
                    : <div className="h-full w-full flex flex-col gap-y-6 items-center justify-center">
                        <button title="Upload File" onClick={() => inputRef.current.click()} className="flex flex-col items-center gap-3 text-blue-lightest hover:text-white">
                            <ArrowUpTrayIcon className="w-34" />
                            <span className="text-3xl font-medium">Upload file</span>
                        </button>
                        <span className={classNames("text-orange-pastel", { "invisible": !error })}>{error}</span>
                    </div>
                }
            </>
        }
        <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ref={inputRef}
            onClick={() => inputRef.current.value = null}
            onChange={onUpload}
            name="file"
            id="file"
            style={{ display: 'none' }}
        />
    </div >
}

function getFilename(url) {
    const urlParams = new URLSearchParams(url);
    const search = "filename=";
    for (const param of urlParams) {
        if (param[0].includes("response-content-disposition")) {
            const idx = param[1].indexOf(search);
            return param[1].substring(idx + search.length);
        }
    }
    return "";
}

function UploadProgress({ taskInfo = {}, onSuccess }) {
    const { task_id, working_file_uuid } = taskInfo;
    const uploadingTask = useDocumentTask(working_file_uuid);

    useEffect(() => {
        if (uploadingTask.completed) onSuccess?.();
    }, [onSuccess, uploadingTask.completed])

    return <>
        <TaskPoller documentId={working_file_uuid} taskId={task_id} />
        <ProgressBar
            value={uploadingTask.progress}
            max={1.}
            showPerc={true}
            showNumber={true}
            description={uploadingTask.status}
            height="h-4"
        />
    </>
}