import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export default function DropDown({ children, title, titleContent, toolbar, alreadyOpen = false }) {
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        setShowContent(alreadyOpen);
    }, [alreadyOpen]);

    return <div className="mt-4">

        <div className="flex justify-between w-full">
            <button className="flex items-center gap-x-2 text-white truncate mr-3" onClick={() => setShowContent(prev => !prev)}>
                {showContent
                    ? <ChevronUpIcon className="w-5 h-5" />
                    : <ChevronDownIcon className="w-5 h-5" />
                }
                {title && <p className="font-bold text-lg shrink-0">{title}</p>}
                <span className="truncate flex-1">{titleContent}</span>
            </button>
            {toolbar}
        </div>


        <Transition
            show={showContent}
            enter="ease-out duration-100"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4"
        >
            <div className="text-blue-lightest rounded">
                {children}
            </div>
        </Transition>
        <div className="border-b-[1px] border-gray-dark mt-1"></div>
    </div>
}