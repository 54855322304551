import Modal from "../Modal";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import SourceDownloadLink from "./SourceDownloadLink";
import { useEffect, useMemo, useState } from "react";
import { processMarkdown } from "components/content/markdown/MarkdownUtils";
import _ from "lodash";
import RawMarkdownMessage from "../markdown/RawMarkdownMessage";
import ExternalUrlLink from "./ExteralUrlLink";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";

//************************
const RAW_SEPARATOR = "\x1F";

function groupRawPages(source) {
    const pages_raw = source?.metadata?.page_raw;
    if (!pages_raw) return [];
    const grouped = _.groupBy(pages_raw, 0);

    const rawPages = [];
    Object.keys(grouped).forEach((key) => {
        const el = grouped[key];
        const start = Math.min(...el.map((v) => v[1]));
        const end = Math.max(...el.map((v) => v[2]));
        rawPages.push({
            page: key,
            start: start,
            end: end,
        });
    });

    return rawPages;
}

function addRawPagesSeparator(theString, rawPages) {
    if (!rawPages || rawPages.length === 0) return theString;

    const strings = [];
    rawPages.forEach((p) => {
        strings.push(theString.substring(p.start, p.end));
    });
    const out = strings.join(RAW_SEPARATOR);
    return out;
}

function splitProcessedSources(theString, rawPages) {
    if (!rawPages || rawPages.length === 0) return [{ page: -1, content: theString }];
    const pages = theString.split(RAW_SEPARATOR);
    const html = [];
    pages.forEach((p, idx) => html.push({ page: rawPages[idx].page, content: fixHtml(p) }));
    return html;
}

function fixHtml(html) {
    var div = document.createElement('div');
    div.innerHTML = html
    return (div.innerHTML);
}

//************************
function SourceContent({ content, source }) {
    return <div className="group text-xs">
        <RawMarkdownMessage
            className="text-gray 
            hover:cursor-pointer 
            hover:text-white 
            hover:underline 
            decoration-solid 
            decoration-[6px]
            underline-offset-[-3px]
            decoration-[#8296b699]"
            style={{
                "text-decoration-skip-ink": "none", /* works in Firefox & Chrome, not in Safari */
                "text-decoration-skip": "none", /* works in Safari, not in Firefox & Chrome */
            }}
            content={content}
            source={source} />
    </div>
}

const Source = ({ source, idx }) => {
    const [sourcePages, setSourcePages] = useState([]);

    const content = useMemo(() => {
        const idx = source.page_content.indexOf("\n");
        return source.page_content.substring(idx + 1);
    }, [source.page_content]);

    useEffect(() => {
        const rawPages = groupRawPages(source);
        const separated = addRawPagesSeparator(content, rawPages);
        processMarkdown(separated).then((processed) => {
            setSourcePages(splitProcessedSources(processed, rawPages));
        });
    }, [content, source]);

    return <div key={idx} className="text-sm mb-4">
        <div className="flex font-bold gap-x-1 mb-1">
            <p>{idx + 1}) {source?.metadata?.title} [{source?.metadata?.filetype}]</p>
            {source?.metadata?.source_url
                ? <>
                    <span>-</span>
                    <ExternalUrlLink url={source.metadata.source_url}>
                        <div className="flex">
                            OPEN
                            <ArrowTopRightOnSquareIcon className="ml-1 w-4 h-5" />
                        </div>
                    </ExternalUrlLink></>
                : <>
                    {source?.metadata?.filetype /*!== "pdf"*/ && <>
                        <span>-</span>
                        <SourceDownloadLink fileUuid={source?.metadata?.file_uid} filePage={source?.metadata?.page}>
                            <div className="flex">
                                OPEN
                                <ArrowTopRightOnSquareIcon className="ml-1 w-4 h-5" />
                            </div>
                        </SourceDownloadLink>
                    </>
                    }
                </>
            }
        </div>
        {sourcePages.map((s, idx) =>
            <>
                {s.page === -1
                    ? <RawMarkdownMessage key={idx} className="text-gray" content={s.content} source={source} />
                    : <> {isNaN(s.page)
                        ? <ExternalUrlLink url={s.page}>
                            <SourceContent content={s.content} source={source} />
                        </ExternalUrlLink>
                        : <SourceDownloadLink key={idx} fileUuid={source?.metadata?.file_uid} filePage={Number(s.page) + 1}>
                            <SourceContent content={s.content} source={source} />
                        </SourceDownloadLink>
                    }</>
                }
            </>
        )}
    </div>
}

export default function Sources({ sources }) {
    const [showSources, setShowSources] = useState(false);

    return <>
        {sources && sources.length > 0 && <>
            <RectangleGroupIcon
                title="Sources"
                className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                onClick={() => setShowSources(true)}
            />
            <Modal showCross={true} show={showSources} setShow={setShowSources}>
                <div className="text-white sm:max-w-6xl">
                    {sources && sources.map((el, idx) => <Source key={idx} source={el} idx={idx} />)}
                </div>
            </Modal>
        </>
        }
    </>
}