import DropDown from "components/DropDown"
import CollectionPrivileges from "./CollectionPrivileges";
import ManageCollection from "../sidebar/ManageCollection";

function CollectionDropDown({ orgId, collection, users, originalPrivileges, newPrivileges, organizationPrivileges, setOrganizationPrivileges }) {
    return <DropDown
        titleContent={<div className="font-bold">{collection.name}</div>}
        toolbar={<ManageCollection collection={collection} fromPrivileges={true} organizationId={orgId} />}
    >
        <div className="pt-4">
            <CollectionPrivileges
                orgId={orgId}
                collectionId={collection.uuid}
                users={users}
                originalPrivileges={originalPrivileges}
                newPrivileges={newPrivileges}
                organizationPrivileges={organizationPrivileges}
                setOrganizationPrivileges={setOrganizationPrivileges}
            />
        </div>
    </DropDown>
}

export default function PrivilegesByCollection({ orgId, members, collections, originalPrivileges, newPrivileges, organizationPrivileges, setOrganizationPrivileges }) {
    return <>
        {collections.map((c) =>
            <div key={c.uuid} className="mb-6">
                <CollectionDropDown
                    orgId={orgId}
                    collection={c}
                    users={members}
                    originalPrivileges={originalPrivileges}
                    newPrivileges={newPrivileges}
                    organizationPrivileges={organizationPrivileges}
                    setOrganizationPrivileges={setOrganizationPrivileges}
                />
            </div>
        )}
    </>
}