// NOTE: all modules imported below may be imported from '@silevis/reactgrid'
import * as React from "react";
import { CellTemplate, Cell, Compatible, Uncertain, UncertainCompatible, getCellProperty } from "@silevis/reactgrid";
import { useMeasure } from "@uidotdev/usehooks";
import MarkdownMessage from "../markdown/MarkdownMessage";

export interface MarkdownCell extends Cell {
    type: "markdown";
    text: string;
    rowIdx: number;
    colIdx: number;
    onHeightChanged?: (rowIdx: number, colIdx: number, height: number) => void;
}

export class MarkdownCellTemplate implements CellTemplate<MarkdownCell> {
    getCompatibleCell(uncertainCell: Uncertain<MarkdownCell>): Compatible<MarkdownCell> {
        const text = getCellProperty(uncertainCell, "text", "string");
        const value = parseFloat(text); // TODO more advanced parsing for all text based cells
        const rowIdx = getCellProperty(uncertainCell, "rowIdx", "number");
        const colIdx = getCellProperty(uncertainCell, "colIdx", "number");
        return { ...uncertainCell, text, value, rowIdx, colIdx };
    }

    update(cell: Compatible<MarkdownCell>, cellToMerge: UncertainCompatible<MarkdownCell>): Compatible<MarkdownCell> {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    handleKeyDown(
        cell: Compatible<MarkdownCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key: string
    ): { cell: Compatible<MarkdownCell>; enableEditMode: boolean } {
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd(
        cell: Compatible<MarkdownCell>,
        eventData: any
    ): { cell: Compatible<MarkdownCell>; enableEditMode: boolean } {
        console.log("composition", eventData);
        return { cell, enableEditMode: false };
    }

    getClassName(cell: Compatible<MarkdownCell>, isInEditMode: boolean): string {
        return cell.className;
    }

    render(
        cell: Compatible<MarkdownCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<MarkdownCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <TextRenderer
                text={cell.text}
                rowIdx={cell.rowIdx}
                colIdx={cell.colIdx}
                onHeightChanged={cell.onHeightChanged}
            />
        );
    }
}

function TextRenderer({
    text,
    rowIdx,
    colIdx,
    onHeightChanged
}: {
    text: string;
    rowIdx: number;
    colIdx: number;
    onHeightChanged: (rowIdx: number, colIdx: number, height: number) => void;
}) {
    const [ref, { height }] = useMeasure();
    React.useEffect(() => {
        onHeightChanged?.(rowIdx, colIdx, height);
    }, [colIdx, height, onHeightChanged, rowIdx]);

    return (
        <div className="h-full">
            <p ref={ref} className="whitespace-pre-wrap text-content select-auto p-1">
                <MarkdownMessage md={text} />
            </p>
        </div>
    );
}
