import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NoneNode, OrganizationNode, UserNode, NONE_BACKGROUND, ORG_BACKGROUND, USER_BACKGROUND, } from "./PrivilegesNodes";
import _ from "lodash";
import { privilegesKey } from "./privilegesUtils";

export function UserPrivilegesSelection({ userId, collectionId, originalPrivileges, newPrivileges, organizationPrivileges }) {
    const [localPrivileges, setlocalPrivileges] = useState({});
    const key = useMemo(() => privilegesKey(userId, collectionId), [collectionId, userId]);
    const orgPrivileges = useMemo(() => organizationPrivileges[collectionId].privileges, [collectionId, organizationPrivileges]);

    useEffect(() => {
        setlocalPrivileges(newPrivileges[key] || originalPrivileges[key]);
    }, [key, newPrivileges, originalPrivileges]);

    const updateState = useCallback((idx) => {
        setlocalPrivileges((prev) => {
            const clone = _.cloneDeep(prev);
            const tmp = clone.privileges;
            for (var i = 0; i < tmp.length; i++) {
                if (i <= idx) tmp[i].access = true;
                else tmp[i].access = false;
            }
            newPrivileges[key] = clone;
            return clone;
        });
    }, [key, newPrivileges]);

    return <nav aria-label="Pivileges">
        <ol className="flex items-center px-6 mb-14">
            {localPrivileges.privileges?.map((p, idx) => (
                <li key={idx} className={classNames("flex items-center", { "flex-1": idx !== 0 })}>
                    {idx !== 0 && <div className={classNames(
                        "h-1 flex-1",
                        p.access ? USER_BACKGROUND : (orgPrivileges[idx].access ? ORG_BACKGROUND + " opacity-40" : NONE_BACKGROUND)
                    )} />}
                    {p.access
                        ? <UserNode onClick={() => updateState(idx)} text={p.name} />
                        : (orgPrivileges[idx].access
                            ? <div className="opacity-40"><OrganizationNode onClick={() => updateState(idx)} text={p.name} /></div>
                            : <NoneNode onClick={() => updateState(idx)} text={p.name} />
                        )
                    }
                </li>
            ))}
        </ol>
    </nav >
}

export function OrganizationPrivilegesSelection({ collectionId, organizationPrivileges, setOrganizationPrivileges }) {
    const orgPrivileges = useMemo(() => organizationPrivileges[collectionId].privileges, [collectionId, organizationPrivileges]);

    const updateState = useCallback((idx) => {
        setOrganizationPrivileges((prev) => {
            const clone = _.cloneDeep(prev);
            const tmp = clone[collectionId].privileges;
            for (var i = 0; i < tmp.length; i++) {
                if (i <= idx) tmp[i].access = true;
                else tmp[i].access = false;
            }
            clone[collectionId].modified = true;
            return clone;
        });
    }, [collectionId, setOrganizationPrivileges]);

    return <nav aria-label="Pivileges">
        <ol className="flex items-center px-6 mb-14">
            {orgPrivileges?.map((p, idx) => (
                <li key={idx} className={classNames("flex items-center", { "flex-1": idx !== 0 })}>
                    {idx !== 0 && <div className={classNames(
                        "h-1 flex-1",
                        p.access ? ORG_BACKGROUND : NONE_BACKGROUND
                    )} />}
                    {p.access
                        ? <OrganizationNode onClick={() => updateState(idx)} text={p.name} />
                        : <NoneNode onClick={() => updateState(idx)} text={p.name} />
                    }
                </li>
            ))}
        </ol>
    </nav >
}