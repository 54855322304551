import { useParams } from "react-router-dom";
import History from "../chat/History";
import Title from "../chat/Title";

export default function FeedbacksChat() {
    const { threadId } = useParams();
    return <div className="mx-auto max-w-7xl lg:p-2 justify-start flex flex-col items-stretch h-full max-h-full relative">
        <Title feedbackThreadId={threadId} />
        <History feedbackThreadId={threadId} />
    </div>
}
