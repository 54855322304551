import React, { } from "react";
import TaskProgressBar from "./TaskProgressBar";

export default function TasksViewer({ files, setFiles, onDelete, wf = false }) {
    const uploading = !!setFiles;

    return (
        <div>
            {files && files.length > 0 &&
                <div className="px-0 pt-0 w-full">
                    {files && Object.values(files).map((item, idx) =>
                        <TaskProgressBar
                            key={idx}
                            idx={idx}
                            task={item}
                            uploading={uploading}
                            onDelete={onDelete}
                            setFiles={setFiles}
                            wf={wf}
                        />
                    )}
                </div>
            }
        </div>
    )
}