import { Fragment, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

export default function Modal({ children, width = "lg:w-auto", showCross = false, show, setShow, onClose }) {
    const closing = useCallback(() => {
        setShow?.(false);
        onClose?.();
    }, [onClose, setShow]);

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closing} static={true}>
                <Transition.Child
                    as="div"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                    {showCross && <button className="fixed z-50 top-2 left-1/2 mx-auto" onClick={closing}>
                        <XCircleIcon className="w-8 text-blue hover:text-blue-lightest stroke-blue-lightest hover:stroke-white" />
                    </button>}
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <Transition.Child
                        as="div"
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0"
                    >
                        <Dialog.Panel className={classNames("transform transition-all relative rounded-lg bg-blue-dark px-4 pb-4 pt-5 text-left shadow-xl lg:p-6 w-full max-w-[90%] lg:min-w-[24rem]", width)}>
                            {children}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
