import { useAuth0 } from '@auth0/auth0-react';
import {Route, BrowserRouter as Router, Routes, Outlet, useNavigate, Navigate} from 'react-router-dom';
import './App.css';
import {useEffect, useState} from 'react';
import { loadUserId } from 'utils/Session';
import DirectOutTotem from 'components/content/dotec/DirectOutTotem';
import HeaderProvider from "./components/HeaderProvider";
import DotecChat from "./components/content/dotec/DotecChat";
import {
    CreateDotecProdigyMcChat,
    CreateDotecProdigyMpChat,
    CreateDotecProdigyMxChat
} from "./components/content/dotec/CreateDotecChat";
import accessTokenService from "./utils/AccessTokenService";
import Loading from "./components/content/Loading";
import DotecHomePage from "./components/content/dotec/DotecHomepage";

function PrivateOutlet(props) {
    const { isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
    const [appReady, setAppReady] = useState(false);

    useEffect(() => {
        async function triggerLoad() {
            if(isLoading) return;
            if(!getAccessTokenSilently) return;

            if(isAuthenticated) {
                await accessTokenService.AsyncSetAccessTokenFn(getAccessTokenSilently);
                await loadUserId();
                setAppReady(true);
            }
        }

        triggerLoad()
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);



    if(isLoading) return <Loading />;
    if(!isAuthenticated) {
        console.debug("The user is not authenticated")
        return <Navigate to={"/"} replace />
    }
    if(!appReady) return <Loading />;
    return (
        <Outlet />
    );
}


function RedirectIfLoggedIn(props) {
    const { isAuthenticated, isLoading} = useAuth0();

    if(isLoading) return <Loading />

    if(isAuthenticated) {
        return <Navigate to={props.to} replace />
    }

    return props.children;
}


function PublicRoutes() {
    return (
        <>
            <Route path="/" element={<RedirectIfLoggedIn to={"/platform"}><DotecHomePage /></RedirectIfLoggedIn>} />
        </>
    );
}

function PrivateRoutes() {
    return (
        <>
            <Route path="platform/*" element={<PrivateOutlet />}>
                <Route path="" element={<DirectOutTotem />} />
                <Route path=":threadId" element={<DotecChat />} />
                <Route path="prodigymc" element={<CreateDotecProdigyMcChat />} />
                <Route path="prodigymp" element={<CreateDotecProdigyMpChat />} />
                <Route path="prodigymx" element={<CreateDotecProdigyMxChat />} />
            </Route>
        </>
    )
}

function App() {
    console.debug("Loaded APP DirectOut");

    return (
        <HeaderProvider>
            <Router>
                <Routes>
                    {PublicRoutes()}
                    {PrivateRoutes()}
                </Routes>
            </Router>
        </HeaderProvider>
    );
}


export default App;