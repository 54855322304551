import { CurrencyDollarIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import DropDown from 'components/DropDown';
import React, { useCallback, useMemo, useState } from 'react';
import { useAddOrganizationBudgetMutation, useDeleteOrganizationBudgetMutation, useGetOrganizationBudgetEnabledQuery, useGetOrganizationBudgetQuery, useSetOrganizationBudgetEnabledMutation } from 'state/api/organizations';
import { BudgetTypes } from 'utils/Utils';
import ConfirmationDialog from '../ConfirmationDialog';
import classNames from 'classnames';
import Buttoon from 'components/Buttoon';
import Modal from '../Modal';
import { useGetResourceTypesQuery } from 'state/api/utils';

function AddBudgetModal({ orgId, title, resource, unit = "", type, show, setShow, setter = (value) => value }) {
  const today = new Date();
  const end = new Date();
  end.setDate(end.getDate() + 30);

  const isAffordance = useMemo(() => type === "affordance", [type]);
  const [setBudget] = useAddOrganizationBudgetMutation();
  const handleSubmit = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    const used = setter(event.target.used.value);
    const total = setter(event.target.total.value);
    const start = new Date(event.target.start.value).getTime() / 1000;
    const end = new Date(event.target.end.value).getTime() / 1000;
    setBudget({
      org_uuid: orgId,
      body: {
        resource: resource,
        budget_used: isAffordance ? null : used,
        budget_total: total,
        validity_start: start,
        validity_end: end,
      }
    });
    setShow(false);
  }, [isAffordance, orgId, resource, setBudget, setShow, setter]);

  return <Modal show={show}>
    <form onSubmit={handleSubmit} className="w-[500px]">
      <p className="text-xl lg:text-2xl mb-3 text-blue-lightest">Add budget for resource: {title}</p>
      <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm text-white">
        <div className="flex gap-x-8">
          <div className="flex-1">
            <label htmlFor="used">
              Used {unit !== "" ? "(" + unit + ")" : ""}
            </label>
            <input
              type="number"
              name="used"
              id="used"
              defaultValue={0}
              disabled={isAffordance}
              className={classNames("block w-full border-0 pt-2.5 rounded-lg mt-2 text-sm placeholder:text-blue-lightest focus:ring-0 bg-blue", { "opacity-50": isAffordance })}
              required
            />
          </div>

          <div className="flex-1">
            <label htmlFor="total">
              Total {unit !== "" ? "(" + unit + ")" : ""}
            </label>
            <input
              type="number"
              name="total"
              id="total"
              className="block w-full border-0 pt-2.5 rounded-lg mt-2 text-sm placeholder:text-blue-lightest focus:ring-0 bg-blue"
              required
            />
          </div>
        </div>

        <div className="border-t-2 border-blue mx-1 my-4"></div>

        <div className="flex gap-x-8">
          <div className="flex-1">
            <label htmlFor="start">
              Validity Start
            </label>
            <input
              type="date"
              name="start"
              id="start"
              defaultValue={today.toISOString().substring(0, 10)}
              required
              className="block w-full border-0 pt-2.5 rounded-lg mt-2 text-sm placeholder:text-blue-lightest focus:ring-0 bg-blue"
            />
          </div>

          <div className="flex-1">
            <label htmlFor="end">
              Validity End
            </label>
            <input
              type="date"
              name="end"
              id="end"
              defaultValue={end.toISOString().substring(0, 10)}
              required
              className="block w-full border-0 pt-2.5 rounded-lg mt-2 text-sm placeholder:text-blue-lightest focus:ring-0 bg-blue"
            />
          </div>
        </div>

      </div>

      <div className="flex items-center justify-end mt-4 gap-x-2">
        <Buttoon
          type="submit"
          disabled={false}
          width="w-16"
          className="shrink-0"
        >
          Add
        </Buttoon>
        <Buttoon
          type="button"
          onClick={() => setShow(false)}
          width="w-16"
          className="shrink-0"
          disabled={false}
        >
          Cancel
        </Buttoon>
      </div>
    </form>
  </Modal>
}

function AddBudgetButton({ orgId, title, resource, unit, type }) {
  const [show, setShow] = useState(false);

  return <button className="text-blue-lightest hover:text-white" title="Add Budget" onClick={() => setShow(true)}>
    <PlusIcon className="w-6 h-6" />
    <AddBudgetModal show={show} setShow={setShow} orgId={orgId} title={title} resource={resource} unit={unit} type={type} setter={unit === "MB" ? (v) => v * 1000 : undefined} />
  </button>
}

function BudgetDropDown({ orgId, title, resource, unit, type, budgets }) {
  const [deleteBudget] = useDeleteOrganizationBudgetMutation();
  const [show, setShow] = useState(false);
  const onConfirm = useCallback((confirm, budgetId) => {
    if (!confirm) return;
    deleteBudget({ org_uuid: orgId, budget_voice_uuid: budgetId });
  }, [deleteBudget, orgId]);

  return <DropDown title={title} toolbar={<AddBudgetButton orgId={orgId} title={title} resource={resource} unit={unit} type={type} />}>
    {budgets.length === 0
      ? "No budgets"
      : <table className="divide-y divide-blue-light table-fixed w-full">
        <colgroup>
          <col span={2} className="w-1/2" />
          <col span={2} className="w-3/4" />
          <col span={1} className="w-[40px]" />
        </colgroup>
        <thead className="bg-blue rounded">
          <tr>
            <th>Used</th>
            <th>Total</th>
            <th>Start</th>
            <th>End</th>
            <th><TrashIcon className="w-4 h-4" /></th>
          </tr>
        </thead>

        <tbody className="divide-y divide-blue-light">
          {budgets?.map((b, idx) =>
            <tr key={idx}>
              <td className="text-center">{b.budget_used}</td>
              <td className="text-center">{b.budget_total}</td>
              <td className="text-center">{new Date(b.validity_start * 1000).toLocaleDateString()}</td>
              <td className="text-center">{new Date(b.validity_end * 1000).toLocaleDateString()}</td>
              <td>
                <button onClick={() => setShow(true)}>
                  <TrashIcon className="w-4 h-4 text-blue-light hover:text-orange" />
                  <ConfirmationDialog show={show} setShow={setShow} onClose={(confirm) => onConfirm(confirm, b.uuid)} />
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    }
  </DropDown>
}

export default function BudgetsList({ orgId }) {
  const { data } = useGetOrganizationBudgetQuery({ org_uuid: orgId }, { skip: !orgId });
  const { data: enabled } = useGetOrganizationBudgetEnabledQuery({ org_uuid: orgId }, { skip: !orgId });
  const [enableBudget] = useSetOrganizationBudgetEnabledMutation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onConfirm = useCallback((confirm) => {
    if (!confirm) return;
    enableBudget({ org_uuid: orgId, params: { enabled: !enabled } });
  }, [enableBudget, enabled, orgId])

  const { types } = useGetResourceTypesQuery(undefined, { selectFromResult: ({ data }) => ({ types: data || [] }) });

  return <div className="mt-3 mb-2">
    <div className="flex items-center justify-between">
      <p className="text-xl lg:text-2xl text-blue-lightest">Budgets</p>
      <button
        className={classNames("hover:text-white", enabled ? "text-yellow" : "text-blue-lightest")}
        title={(enabled ? "Disable" : "Enable") + " Budgeting"}
        onClick={() => setShowConfirmation(true)} >
        <CurrencyDollarIcon className="w-6 h-6" />
        <ConfirmationDialog show={showConfirmation} setShow={setShowConfirmation} confirmText={enabled ? "Disable" : "Enable"} onClose={onConfirm} />
      </button>
    </div>
    {
      Object.keys(BudgetTypes).map((k, idx) => <div key={idx} className="mb-6">
        <BudgetDropDown
          orgId={orgId}
          title={BudgetTypes[k].description}
          resource={k}
          unit={BudgetTypes[k].unit}
          type={types[k] ? types[k]["buget-voice-type"] : ""}
          budgets={data?.filter((v) => v.resource === k) || []}
        />
      </div>)
    }
  </div >
}