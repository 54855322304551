import { useCurrentCollectionId, useCurrentOrganizationId, useCurrentUserId } from "../state/GeneralSlice";
import React, { ComponentType, FC, useCallback, useEffect, useState } from "react";
import { useToken } from "./AccessTokenService";
import jwtDecode from "jwt-decode";
import { OrganizationInfoAdmin, useGetUserOrganizationsQuery } from "../state/api/organizations";
import {
    CollectionPrivilegesInfo,
    Privilege,
    useGetCollectionPrivilegesQuery,
    useLazyGetCollectionPrivilegesQuery
} from "../state/api/collections";
import Unauthorized from "../components/Unauthorized";

const applicationConfig = require("../application.json");

//*****************/
type JwtToken = {
    aud: string[];
    permissions: string[];
};

type Permissions = {
    isUser: boolean;
    isAdmin: boolean;
    withAccess: boolean;
    withChat: boolean;
    withReadKnowledgeBase: boolean;
    withWriteKnowledgeBase: boolean;
    withWriteCollectionPrompt: boolean;
};

const DEFAULT_PERMISSIONS: Permissions = {
    isUser: false,
    isAdmin: false,
    withAccess: true,
    withChat: false,
    withReadKnowledgeBase: false,
    withWriteKnowledgeBase: false,
    withWriteCollectionPrompt: false
};

type ResourceWithLoading<T> = {
    isLoading: boolean;
    data: T;
};

function parsePermissions(perms: string[]): Permissions {
    const result = { ...DEFAULT_PERMISSIONS };

    if (!perms.includes("access:" + applicationConfig.auth0Access)) {
        result.withAccess = false;
        return result;
    }

    result.withAccess = true;

    if (perms.includes("admin")) {
        result.isAdmin = true;
    }
    if (perms.includes("admin") || perms.includes("user")) {
        result.isUser = true;
        result.withChat = true;
    }
    if (perms.includes("read:knowledge-base")) {
        result.withReadKnowledgeBase = true;
    }
    if (perms.includes("write:knowledge-base")) {
        result.withWriteKnowledgeBase = true;
    }
    if (perms.includes("write:collection-prompt")) {
        result.withWriteCollectionPrompt = true;
    }
    return result;
}

const usePermissions = () => {
    const [permissions, setPermissions] = useState<ResourceWithLoading<Permissions>>({
        isLoading: true,
        data: { ...DEFAULT_PERMISSIONS }
    });
    const token = useToken();
    useEffect(() => {
        if (!token) {
            setPermissions({ isLoading: true, data: { ...DEFAULT_PERMISSIONS } });
            return;
        }
        const decoded: JwtToken = jwtDecode(token);
        const rawPerms: string[] = decoded?.permissions ? decoded.permissions : [];
        const perms = parsePermissions(rawPerms);

        setPermissions({ isLoading: false, data: perms });
    }, [token, setPermissions]);
    return permissions;
};

//*****************/
type OrganizationPrivileges = {
    hasAccess: boolean;
    isUser: boolean;
    isAdmin: boolean;
};

const DEFAULT_ORGANIZATION_PRIVILEGES: OrganizationPrivileges = {
    hasAccess: false,
    isUser: false,
    isAdmin: false
};

function useOrganizationPrivileges(organizationId?: string): ResourceWithLoading<OrganizationPrivileges> {
    const userId = useCurrentUserId();
    const o = useCurrentOrganizationId();
    const orgId = organizationId || o;
    const {
        data: { withAccess, isUser, isAdmin },
        isLoading: isLoadingPermission
    } = usePermissions();

    const { data, isLoading: isLoadingOrganizations } = useGetUserOrganizationsQuery(
        { user_uuid: userId },
        { skip: !userId }
    );
    if (!data) {
        return {
            isLoading: true,
            data: DEFAULT_ORGANIZATION_PRIVILEGES
        };
    }
    let org: OrganizationInfoAdmin;
    for (let i = 0; i < data.length; i++) {
        if (data[i].uuid === orgId) {
            org = data[i];
            break;
        }
    }

    return {
        isLoading: isLoadingPermission || isLoadingOrganizations,
        data: {
            hasAccess: withAccess,
            isUser: isUser,
            isAdmin: org?.is_admin || isAdmin
        }
    };
}

//*****************/
export const PrivilegesType = {
    USER: "user",
    ORGANIZATION: "organization",
    BOTH: "user&organization"
};

type CollectionPrivileges = {
    chatAccess: boolean;
    readAccess: boolean;
    writeAccess: boolean;
    adminAccess: boolean;
};

const DEFAULT_COLLECTION_PRIVILEGES: CollectionPrivileges = {
    chatAccess: false,
    readAccess: false,
    writeAccess: false,
    adminAccess: false
};

function useComputeCollectionPrivilegesCallback() {
    const o = useCurrentOrganizationId();
    const u = useCurrentUserId();
    const {
        data: { isAdmin }
    } = usePermissions();

    const compute = useCallback(
        (
            privileges: CollectionPrivilegesInfo,
            userId?: string,
            organizationId?: string,
            type: string = PrivilegesType.BOTH
        ) => {
            if (!privileges) return DEFAULT_COLLECTION_PRIVILEGES;

            const usrId = userId || u;
            const orgId = organizationId || o;
            const isGod = type === PrivilegesType.BOTH && isAdmin && false;

            let orgPriv: Privilege[];
            if (type.includes(PrivilegesType.ORGANIZATION)) {
                for (let i = 0; i < privileges.org_permissions.length; i++) {
                    if (privileges.org_permissions[i].uuid === orgId) {
                        orgPriv = privileges.org_permissions[i].privilege;
                        break;
                    }
                }
            }
            let userPriv: Privilege[];
            if (type.includes(PrivilegesType.USER)) {
                for (let i = 0; i < privileges.user_permissions.length; i++) {
                    if (privileges.user_permissions[i].uuid === usrId) {
                        userPriv = privileges.user_permissions[i].privilege;
                        break;
                    }
                }
            }

            return {
                chatAccess: orgPriv?.includes("chat_access") || userPriv?.includes("chat_access") || isGod,
                readAccess: orgPriv?.includes("read_access") || userPriv?.includes("read_access") || isGod,
                writeAccess: orgPriv?.includes("write_access") || userPriv?.includes("write_access") || isGod,
                adminAccess: orgPriv?.includes("admin_access") || userPriv?.includes("admin_access") || isGod
            };
        },
        [isAdmin, o, u]
    );

    return compute;
}

function useCollectionPrivileges(collectionId?: string, userId?: string, type: string = PrivilegesType.BOTH) {
    const c = useCurrentCollectionId();
    const collId = collectionId || c;

    const computePrivileges = useComputeCollectionPrivilegesCallback();
    const response = useGetCollectionPrivilegesQuery({ collection_id: collId }, { skip: !collId });

    const privileges = computePrivileges(response.data, userId, undefined, type);

    return { ...response, data: privileges };
}

function useLazyCollectionPrivileges() {
    const c = useCurrentCollectionId();
    const computePrivileges = useComputeCollectionPrivilegesCallback();
    const [fetch, response, lastInfo] = useLazyGetCollectionPrivilegesQuery();

    const lazyFetch = useCallback(
        async (
            params: { orgId?: string; collectionId?: string; userId?: string; type?: string },
            preferChaceValue: boolean = false
        ) => {
            const ty = params.type || PrivilegesType.BOTH;
            const collId = params.collectionId || c;

            const resp = await fetch({ collection_id: collId }, preferChaceValue);
            const privileges = computePrivileges(resp.data, params.userId, params.orgId, ty);
            return { ...resp, data: privileges };
        },
        [c, computePrivileges, fetch]
    );

    return [lazyFetch, response, lastInfo];
}

//*****************/
const withPrivilegesRequired = <P extends object>(Component: ComponentType<P>, privileges: any, key: string): FC<P> => {
    return function WithPrivilegesRequired(props: P): JSX.Element {
        if (privileges[key] === undefined) throw new Error("Invalid privilege key!");
        return privileges[key] ? <Component {...props} /> : <Unauthorized />;
    };
};

const withGodPrivilegesRequired = <P extends object>(Component: ComponentType<P>): FC<P> => {
    return function WithGodPrivilegesRequired(props: P): JSX.Element {
        const { data: permissions } = usePermissions();
        const Komponent = withPrivilegesRequired(Component, permissions, "isAdmin");
        return <Komponent {...props} />;
    };
};

const withOrganizationPrivilegesRequired = <P extends object>(Component: ComponentType<P>, key: string): FC<P> => {
    return function WithOrganizationPrivilegesRequired(props: P | any): JSX.Element {
        const { data: permissions } = useOrganizationPrivileges(props.orgId || props.organizationId);
        const Komponent = withPrivilegesRequired(Component, permissions, key);
        return <Komponent {...props} />;
    };
};

const withCollectionPrivilegesRequired = <P extends object>(Component: ComponentType<P>, key: string): FC<P> => {
    return function WithCollectionPrivilegesRequired(props: P): JSX.Element {
        const { data: permissions } = useCollectionPrivileges();
        const Komponent = withPrivilegesRequired(Component, permissions, key);
        return <Komponent {...props} />;
    };
};

//*****************/
export {
    usePermissions,
    useOrganizationPrivileges,
    useCollectionPrivileges,
    useLazyCollectionPrivileges,
    withGodPrivilegesRequired,
    withOrganizationPrivilegesRequired,
    withCollectionPrivilegesRequired
};
