import { Outlet } from "react-router-dom";
import { useOrganizationPrivileges } from "../utils/Privileges";

export default function Content() {
    const { data: { hasAccess }, isLoading } = useOrganizationPrivileges();

    if (isLoading)
        return <span></span>

    if (hasAccess)
        return <Outlet />
    else
        return <div className="text-center p-5 text-orange-pastel font-bold text-2xl">NO ACCESS!</div>

}