import classNames from "classnames";

export const USER_BACKGROUND = "bg-gray-dark";
export const ORG_BACKGROUND = "bg-gray-dark";
export const NONE_BACKGROUND = "bg-gray-darkest opacity-60";

const USER_COLOR = "bg-green-pastel";
const ORG_COLOR = "bg-azure-light";
const NONE_COLOR = "bg-blue-dark";

const USER_TEXT = "text-gray";
const ORG_TEXT = "text-gray";
const NONE_TEXT = "text-gray-dark";

//******************
function Node({ onClick, background, color, textColor, text, bold = false }) {
    return <button
        type="button"
        className={classNames(
            "group relative flex h-8 w-8 items-center justify-center rounded-full hover:bg-blue-light",
            background
        )}
        onClick={onClick}
    >
        <span className={classNames(
            "h-6 w-6 rounded-full group-hover:bg-blue-lightest",
            color
        )} />
        <span className={classNames(
            "absolute top-full",
            { "font-bold": bold },
            textColor
        )} >
            {text}
        </span>
    </button>
}

export function UserNode({ onClick, text }) {
    return <Node
        onClick={onClick}
        background={USER_BACKGROUND}
        color={USER_COLOR}
        textColor={USER_TEXT}
        text={text}
        bold={true}
    />
}

export function OrganizationNode({ onClick, text }) {
    return <Node
        onClick={onClick}
        background={ORG_BACKGROUND}
        color={ORG_COLOR}
        textColor={ORG_TEXT}
        text={text}
        bold={true}
    />
}

export function NoneNode({ onClick, text }) {
    return <Node
        onClick={onClick}
        background={NONE_BACKGROUND}
        color={NONE_COLOR}
        textColor={NONE_TEXT}
        text={text}
        bold={false}
    />
}