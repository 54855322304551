import { emptyApi as api } from "./emptyApi";

const tasksRtkApi = api.enhanceEndpoints({ addTagTypes: ["token"] }).injectEndpoints({
    endpoints: (build) => ({
        getTasks: build.query<any, undefined>({
            query: (args) => ({
                url: "tasks"
            })
        }),
        submitTask: build.mutation<any, SubmitTaskArgs>({
            query: (args) => ({
                url: "tasks/submit",
                method: "POST",
                body: args,
                headers: {
                    "Content-Type": "application/json"
                }
            })
        }),
        getTaskResult: build.query<any, TaskResultArgs>({
            query: (args) => ({
                url: `tasks/results/${args.jid}`
            })
        }),
        removeTask: build.mutation<any, RemoveTaskArgs>({
            query: (args) => ({
                url: `tasks/${args.taskId}`,
                method: "DELETE"
            }),
            onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    tasksRtkApi.util.updateQueryData(
                        "getTaskResult",
                        { jid: arg.taskId },
                        (draft: any[] | undefined) => {
                            if (draft) {
                                return { ...draft, status: "REVOKED", meta: { total_progress: 1.0 } };
                            }
                        }
                    )
                );
                queryFulfilled.catch(patchResult.undo);
            }
        })
    }),
    overrideExisting: false
});

export default tasksRtkApi;

export type SubmitTaskArgs = {
    name: string;
    args: string[];
    kwargs: {};
};

export type TaskResultArgs = {
    jid: string;
};

export type TaskResultResponse = {
    status: string;
    result: {};
    meta: {};
};

export type RemoveTaskArgs = {
    taskId: string;
};

export const { useGetTaskResultQuery, useLazyGetTaskResultQuery, useRemoveTaskMutation } = tasksRtkApi;
