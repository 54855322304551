import React, {useEffect, useState} from "react";
import _ from "lodash";

type SetterCallback = (newValue: any) => void;
class MicrophoneService {
    private _isMicrophoneAvailable : boolean = true;

    isMicrophoneAvailable(): boolean {
        return this._isMicrophoneAvailable;
    }

    setMicrophoneAvailable(newValue: boolean) {
        if (_.isEqual(newValue, this._isMicrophoneAvailable))
            return;
        this._isMicrophoneAvailable = newValue;
        if (!this._observers)
            return;
        this._observers.forEach((update) => update(() => this._isMicrophoneAvailable));
    }

    private _observers: React.Dispatch<React.SetStateAction<any>>[];

    addObserver(stateSetterCallback: SetterCallback) {
        if(!this._observers){
            this._observers = [];
        }
        this._observers.push(stateSetterCallback);
    }

    removeObserver(stateSetterCallback: SetterCallback){
        if(!this._observers){
            return;
        }
        this._observers = this._observers.filter((fn) => fn !== stateSetterCallback);
    }
}

const microphoneService = new MicrophoneService();

const useMicrophone = () => {
    const [isAvailable, setAvailable] = useState(microphoneService.isMicrophoneAvailable())
    useEffect(() => {
        microphoneService.addObserver(setAvailable);
        setAvailable(microphoneService.isMicrophoneAvailable())
        return () => microphoneService.removeObserver(setAvailable);
    }, [isAvailable])
    return [isAvailable, (available: boolean) => microphoneService.setMicrophoneAvailable(available)];
}

export {
    useMicrophone
}

export default microphoneService;