import Header from "components/Header";
import { useParams } from "react-router-dom";
import BudgetsList from "./BudgetsList";

export default function BudgetDetails() {
    const { orgId } = useParams();
    return <div className="mx-auto min-w-7xl max-w-7xl pt-2 pb-4 px-2">
        <Header
            title="Budgets"
            subtitle="Set budgets for the organization"
            backTitle="Organization Overview"
            backRoute="../"
        />
        <div className="border-t border-gray mt-8">
            <BudgetsList orgId={orgId} />
        </div>
    </div>
}