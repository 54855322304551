import Buttoon from "components/Buttoon";
import { useCallback, useState } from "react";
import { useCurrentOrganizationId } from "state/GeneralSlice";
import { useIsArchivedThread, useMessageActionMutation } from "state/api/threads";
import Modal from "../Modal";
import classNames from "classnames";
import Loading from "../Loading";

function ActionButton({ message, actionName, actionContent, onClick }) {
    const { data: isArchived } = useIsArchivedThread(message.thread_uuid);
    const [messageAction] = useMessageActionMutation();
    const orgId = useCurrentOrganizationId();
    const doAction = useCallback(
        () => {
            const body = actionContent.metadata;
            return messageAction({
                thread_uuid: message.thread_uuid,
                message_uuid: message.uuid,
                action_name: actionName,
                params: { current_org_uuid: orgId },
                body: body,
                metadata: actionContent.metadata
            });
        },
        [actionContent.metadata, actionName, message.thread_uuid, message.uuid, messageAction, orgId]
    )

    return <Buttoon
        disabled={isArchived}
        type="button"
        className="bg-blue-light hover:enabled:bg-blue-lightest"
        onClick={onClick ?? doAction}
    >
        {actionContent.description}
    </Buttoon>
}

//********************************
function AddDocButton({ message, actionName, actionContent }) {
    const [messageAction, { isLoading }] = useMessageActionMutation();
    const orgId = useCurrentOrganizationId();

    const params = actionContent.metadata.params;

    const [show, setShow] = useState(false);
    const [error, setError] = useState();
    const handleSubmit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setError();

        const pars = {};
        params.forEach(p => {
            pars[p.name] = event.target[p.name].value;
        });
        const body = { source: actionContent.metadata.source, params: pars };

        const response = await messageAction({
            thread_uuid: message.thread_uuid,
            message_uuid: message.uuid,
            action_name: actionName,
            params: { current_org_uuid: orgId },
            body: body,
            metadata: actionContent.cmetadata,
        }).unwrap();

        if (response.status === "ok") setShow(false);
        else setError(response.message);
    }, [actionContent.cmetadata, actionContent.metadata.source, actionName, message.thread_uuid, message.uuid, messageAction, orgId, params]);

    return <>
        <ActionButton
            message={message}
            actionContent={actionContent}
            onClick={() => setShow(true)}
        />
        <Modal show={show} setShow={setShow}>
            <form onSubmit={handleSubmit} className="w-[500px] mx-auto">
                <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">{params[0].description}</p>
                <div className="border-t-2 border-blue my-2"></div>
                <div className="flex-1 overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                    <input
                        type="text"
                        name={params[0].name}
                        id={params[0].name}
                        required
                        className="block w-full border-0 pt-2.5 text-md font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                        placeholder="Model code..."
                    />
                </div>

                <div className="flex items-center justify-between mt-2 gap-x-2">
                    {isLoading
                        ? <Loading size="w-6 h-6" stroke="border-2" />
                        : <span className={classNames("text-orange-pastel text-sm text-justify", { "invisible": !error })}>{error}</span>
                    }
                    <div className="flex items-center gap-x-2">
                        <Buttoon
                            type="submit"
                            disabled={isLoading}
                            width="w-16"
                            className="shrink-0"
                        >
                            Add
                        </Buttoon>
                        <Buttoon
                            type="button"
                            disabled={isLoading}
                            width="w-16"
                            className="shrink-0"
                            onClick={() => setShow(false)}
                        >
                            Cancel
                        </Buttoon>
                    </div>
                </div>
            </form>
        </Modal>
    </>
}

//********************************
function MessageActionButton({ message, action }) {
    const actionName = Object.keys(action)[0];
    const actionContent = action[actionName];

    switch (actionName) {
        case "add_doc": return <AddDocButton message={message} actionName={actionName} actionContent={actionContent} />
        default: return <ActionButton message={message} actionName={actionName} actionContent={actionContent} />
    }
}

export default function MessageActions({ message }) {
    const actions = message.cmetadata?.actions ?? [];
    return <>
        {actions.map((action, idx) => <MessageActionButton key={idx} message={message} action={action} />)}
    </>
}