import { useClickAway } from "@uidotdev/usehooks";
import React, { useCallback, useEffect, useRef } from "react";

interface InputProps extends React.ButtonHTMLAttributes<HTMLInputElement> {
    onEnterPressed?: (value: string) => void;
    autofocus?: boolean;
}

function EnterAwareInput({ onEnterPressed, autofocus = false, ...props }: InputProps): React.JSX.Element {
    const keyDownWorks = useRef(false);

    const submitTitle = onEnterPressed;

    const clickRef: any = useClickAway(() => submitTitle?.(clickRef.current.value));

    useEffect(() => {
        if (autofocus) {
            clickRef.current.focus();
            clickRef.current.select();
        }
    }, [clickRef, autofocus]);

    const handleSubmit = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            keyDownWorks.current = true;
            if (e.key === "Enter") {
                submitTitle?.(e.currentTarget.value);
            }
        },
        [submitTitle]
    );

    /**fix for firefox or similar*/
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            if (!keyDownWorks.current) {
                submitTitle?.(value);
            }
        },
        [submitTitle]
    );

    return <input {...props} type="text" ref={clickRef} onKeyDown={handleSubmit} onChange={onChange} />;
}

export default EnterAwareInput;
