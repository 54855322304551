import { Switch } from "@headlessui/react";
import classNames from "classnames";

export default function Swiitch({ checked = false, onChange, id = "", disabled = false }) {
    return <Switch
        disabled={disabled}
        id={id}
        checked={checked}
        onChange={onChange}
        className={classNames(
            checked ? 'bg-switch-checked' : 'bg-switch-not-checked',
            { "opacity-50 pointer-events-none": disabled },
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-light focus:ring-offset-2'
        )}
    >
        <span
            aria-hidden="true"
            className={classNames(
                checked ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
    </Switch>
}