import ModelPicUploader from "./ModelPicUploader";
import SettingComboBox, { PipelineComboBox } from "./SettingComboBox";
import CollectionAssociation from "./CollectionAssociation";
import { SettingNumber, SettingText } from "./SettingInput";
import { PromptInput, PromptNoContextInput, PromptValhalla, PromptValhallaFinal } from "./SettingPropmpt";
import SettingBoolean from "./SettingBoolean";
import SettingAlert from "./SettingAlert";
import SettingMultipleChoice from "./SettingMultipleChoice";
import { SettingContainerWrapper } from "./SettingsContainer";
import { SettingGroupContainer, SettingRadioContainer, SettingTabsContainer, SettingsFeatureContainer } from "./SettingGroupContainer";
import { updateModelPic } from "./settingsUtils";

export const ComponentsMap = {
    "picture-picker": ModelPicUploader,
    "prompt": PromptInput,
    "prompt-noctx": PromptNoContextInput,
    "valhalla-prompt": PromptValhalla,
    "valhalla-final-prompt": PromptValhallaFinal,
    "number": SettingNumber,
    "combobox": SettingComboBox,
    "pipeline-combobox": PipelineComboBox,
    "bool": SettingBoolean,
    "associated_collections": CollectionAssociation,
    "multichoice": SettingMultipleChoice,
    "alert": SettingAlert,
    "tabs-container": SettingTabsContainer,
    "tab": SettingContainerWrapper,
    "text": SettingText,
    "feature-switch": SettingBoolean,
    "group-container": SettingGroupContainer,
    "radio-container": SettingRadioContainer,
    "feature-container": SettingsFeatureContainer,
}

export const ManualConfigs = {
    "shared.model_pic": updateModelPic,
}

export const ManualConfigsKeys = Object.keys(ManualConfigs);