import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid'

import { useCallback, useMemo } from 'react';

const maxPages = 7;

export default function Pagination({ itemsCount, itemsPerPage, pageNumber, setPageNumber }) {
    const pagesCount = Math.max(Math.ceil(itemsCount / itemsPerPage), 1);
    const pages = useMemo(() => {
        if (pagesCount <= maxPages) return [...Array(pagesCount).keys()].map(v => v + 1);

        const startCenter = 4;
        const endCenter = pagesCount - 3;
        if (pageNumber <= startCenter) {
            const p = [...Array(maxPages - 1).keys()].map(v => v + 1);
            p.push('...');
            return p;
        }
        if (pageNumber >= endCenter) {
            const end = pagesCount - maxPages + 2;
            const p = [];
            for (var i = pagesCount; i >= end; i--) {
                p.push(i);
            }
            p.push('...');
            return p.reverse();
        }

        const p = [];
        p.push('...');
        for (var k = pageNumber - 2; k <= pageNumber + 2; k++) {
            p.push(k);
        }
        p.push('...');
        return p;
    }, [pageNumber, pagesCount]);

    const changePage = useCallback((pageNumber) => {
        if (isNaN(pageNumber)) return;
        if (pageNumber < 1) pageNumber = 1;
        if (pageNumber > pagesCount) pageNumber = pagesCount;
        setPageNumber(pageNumber);
    }, [pagesCount, setPageNumber]);

    return (
        <div className="flex-shrink">
            <nav className="flex items-center justify-between border-t border-blue-lightest px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <button
                        href="#"
                        onClick={() => changePage(1)}
                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                    >
                        <ChevronDoubleLeftIcon className="mr-3 w-5" aria-hidden="true" />
                        First
                    </button>
                </div>
                <button
                    href="#"
                    onClick={() => changePage(pageNumber - 1)}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                >
                    <ChevronLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                </button>
                {/*Show pages*/}
                <div className="hidden md:-mt-px md:flex">
                    {
                        pages.map((p, idx) => {
                            return <button
                                key={idx}
                                href="#"
                                onClick={() => changePage(p)}
                                disabled={p === pageNumber}
                                className={"w-11 inline-flex items-center border-t-4 px-4 pt-4 text-sm font-medium " + (p === pageNumber
                                    ? "border-white text-white"
                                    : "border-transparent text-blue-lightest hover:text-white")}
                            >
                                {p}
                            </button>
                        })
                    }
                </div>
                {/*Show current page*/}
                <div className="flex md:-mt-px md:hidden">
                    {
                        <p
                            className={"inline-flex items-center border-t-4 px-4 pt-4 text-sm font-medium border-white text-white"}
                        >
                            {pageNumber}
                        </p>
                    }
                </div>
                <button
                    href="#"
                    onClick={() => changePage(pageNumber + 1)}
                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                >
                    <ChevronRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
                </button>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <button
                        href="#"
                        onClick={() => changePage(pagesCount)}
                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                    >
                        Last
                        <ChevronDoubleRightIcon className="ml-3 w-5" aria-hidden="true" />
                    </button>
                </div>
            </nav >
        </div >
    )
}
