import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetTaskResultQuery } from "state/api/tasks";
import { useDispatch } from "react-redux";
import { updateTaskStatus, useDocumentTask } from "state/TasksSlice";
import { updateTaskStatus as WFupdateTaskStatus, useDocumentTask as WFuseDocumentTask } from "state/WFTasksSlice";

const MAX_RETRY = 5;

export default function TaskPoller({ documentId, taskId, wf = false }) {
    const [pollingInterval, setPollingInterval] = useState(2000);
    const [retries, setRetries] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isProblem, setIsProblem] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);

    const task = useDocumentTask(documentId);
    const wfTask = WFuseDocumentTask(documentId);

    useEffect(() => {
        if (wf ? wfTask.completed : task.completed) {
            const timeout = setTimeout(() => setPollingInterval(0.0), 2000);
            return () => clearTimeout(timeout);
        }
    }, [task.completed, wf, wfTask.completed]);

    const { data, isError, requestId } = useGetTaskResultQuery({ jid: taskId }, {
        skip: !taskId,
        pollingInterval: pollingInterval,
        refetchOnMountOrArgChange: true,
    });

    const isFailed = useMemo(() => retries >= MAX_RETRY, [retries]);
    const dispatch = useDispatch();

    const updateProgress = useCallback((progress) => {
        dispatch(wf ? WFupdateTaskStatus({ documentId: documentId, progress: progress }) : updateTaskStatus({ documentId: documentId, progress: progress }));
    }, [dispatch, documentId, wf]);

    useEffect(() => {
        if (taskId) dispatch(wf ? WFupdateTaskStatus({ documentId: documentId, taskId: taskId }) : updateTaskStatus({ documentId: documentId, taskId: taskId }));
    }, [dispatch, documentId, taskId, wf])

    useEffect(() => {
        if (isFailed) {
            updateProgress(1.0);
            setPollingInterval(0);
            if (!isProblem) {
                setIsProblem(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed]);

    useEffect(() => {
        if (isError || !data || data?.status === "FAILURE") {
            setRetries((prev) => prev + 1);
            return;
        }

        if (data?.status === "STARTED") {
            updateProgress(0.0);
            return;
        }

        if (data?.status === "REVOKED") {
            setPollingInterval(0);
            updateProgress(1.0);
            if (!isRevoked) {
                setIsRevoked(true);
            }
        }

        if (data?.status === "SUCCESS") {
            setPollingInterval(0);
            updateProgress(1.0);
            if (!isSuccess) {
                const timer = setTimeout(() => {
                    setIsSuccess(true);
                }, 1000);
                return () => clearTimeout(timer)
            }
        }

        if (isError || !data) {
            setRetries((prev) => prev + 1);
        }
        else {
            if (data.meta) updateProgress(data.meta.total_progress);
            setRetries(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError, requestId])

    useEffect(() => {
        function getStatus() {
            if (isRevoked) return "REVOKED";
            if (isSuccess) return "SUCCESS";
            if (isFailed) return "ERROR";
            if (!requestId) return "UPLOADING";
            if (!data?.status) return "WAITING";
            return data?.status === "FAILURE" ? "RETRYING..." : data?.status
        }
        dispatch(wf ? WFupdateTaskStatus({ documentId: documentId, status: getStatus() }) : updateTaskStatus({ documentId: documentId, status: getStatus() }));
    }, [data?.status, dispatch, documentId, isFailed, isRevoked, isSuccess, requestId, wf]);

    return <></>
}