import { useState } from "react";
import Modal from "../Modal";

export default function MarkdownImage({ src, alt, isSource }) {
    const [show, setShow] = useState(false);
    return <div>
        <button className="flex justify-center p-2" onClick={() => setShow(true)}>
            <img src={src} alt={alt} className="w-[80%] rounded-sm shadow-xl" />
        </button>
        {!isSource &&
            <Modal show={show} onClose={() => setShow(false)}>
                <div className="mx-auto text-center flex justify-center">
                    <img src={src} alt={alt} className="rounded-sm shadow-xl" />
                </div>
            </Modal>
        }
    </div >
};