import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCurrentOrganizationId } from "state/GeneralSlice";
import { useCreateCollectionMutation, useGetCollectionInfoQuery, useUpdateCollectionNameMutation } from "state/api/collections";
import Modal from "../Modal";
import Buttoon from "components/Buttoon";
import CollectionPrivileges from "../privileges/CollectionPrivileges";
import { useGetOrganizationMembersQuery } from "state/api/organizations";
import Loading from "../Loading";
import { createPrivilegesPayload, privilegesKey, useLoadPrivilegesCallback, useSavePrivilegesCallback } from "../privileges/privilegesUtils";

export default function NewCollectionModal({ show, setShow, collectionId, onCreated, organizationId, editPrivileges = true }) {
    const oId = useCurrentOrganizationId();
    const orgId = organizationId || oId;
    const { collectionName } = useGetCollectionInfoQuery({ collection_id: collectionId }, {
        skip: !collectionId,
        selectFromResult: ({ data }) => ({ collectionName: data?.name || "" }),
    });
    const [createCollection] = useCreateCollectionMutation();
    const [updateCollectionName] = useUpdateCollectionNameMutation();

    const originalPrivileges = useRef({});
    const newPrivileges = useRef({});
    const [organizationPrivileges, setOrganizationPrivileges] = useState();

    const { data: members, isFetching: isFetchingMembers } = useGetOrganizationMembersQuery({ org_uuid: orgId }, { skip: !orgId })
    const collId = useMemo(() => ({ valid: !!collectionId, value: collectionId || Math.random().toString(16).slice(2) }), [collectionId]);

    const [loaded, setLoaded] = useState(false);
    const loadPrivileges = useLoadPrivilegesCallback();

    useEffect(() => {
        if (isFetchingMembers) return;
        if (!organizationPrivileges && editPrivileges) {
            if (collId.valid) {
                loadPrivileges(orgId, [{ uuid: collId.value }], members, (organizationPrivileges, usersPrivileges) => {
                    originalPrivileges.current = usersPrivileges;
                    setOrganizationPrivileges(organizationPrivileges);
                    setLoaded(true);
                });
            }
            else {
                const orgPrivileges = {};
                orgPrivileges[collId.value] = {
                    modified: false,
                    privileges: [
                        { name: "None", access: true, privilege: "" },
                        { name: "Chat", access: false, privilege: "chat_access" },
                        { name: "Read", access: false, privilege: "read_access" },
                        { name: "Write", access: false, privilege: "write_access" },
                        { name: "Admin", access: false, privilege: "admin_access" },
                    ],
                }
                for (var k = 0; k < members.length; k++) {
                    const userId = members[k].uuid;
                    originalPrivileges.current[privilegesKey(userId, collId.value)] = {
                        userId: userId,
                        collectionId: collId.value,
                        privileges: [
                            { name: "None", access: true, privilege: "" },
                            { name: "Chat", access: false, privilege: "chat_access" },
                            { name: "Read", access: false, privilege: "read_access" },
                            { name: "Write", access: false, privilege: "write_access" },
                            { name: "Admin", access: false, privilege: "admin_access" },
                        ],
                    }
                }
                setOrganizationPrivileges(orgPrivileges);
                setLoaded(true);
            }
        }

    }, [collId.valid, collId.value, editPrivileges, isFetchingMembers, loadPrivileges, members, orgId, organizationPrivileges])

    const savePrivileges = useSavePrivilegesCallback();
    const handleSubmit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const name = event.target.title.value;
        const privilegesPayload = createPrivilegesPayload(orgId, newPrivileges.current, organizationPrivileges)

        if (collectionId) {
            updateCollectionName({ collection_id: collectionId, collection_name: name })
                .then(() => {
                    if (editPrivileges) savePrivileges(privilegesPayload)
                });
        }
        else {
            const privs = privilegesPayload[true];
            let body = []
            if (privs) body = privs[collId.value];

            const response = await createCollection({
                params: {
                    owner_organization_uuid: orgId,
                    collection_name: name,
                },
                body: body,
            })
            if (response.data?.uuid) {
                onCreated?.(response.data.uuid);
            }
        }
        setShow(false);

    }, [collId.value, collectionId, createCollection, editPrivileges, onCreated, orgId, organizationPrivileges, savePrivileges, setShow, updateCollectionName]);

    return <Modal show={show}>
        <form onSubmit={handleSubmit} className="relative">
            <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                <label htmlFor="title" className="sr-only">
                    Title
                </label>
                <input
                    type="text"
                    name="title"
                    id="title"
                    defaultValue={collectionName}
                    required
                    className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                    placeholder="Collection Name"
                />
            </div>
            {editPrivileges && <div>
                <div className="flex items-center my-2">
                    <div className="border-b-2 flex-grow border-blue-light mx-2" />
                    <span className="text-blue-lightest font-bold text-lg">Privileges</span>
                    <div className="border-b-2 flex-grow border-blue-light mx-2" />
                </div>
                {loaded
                    ? <div className="text-blue-lightest">
                        <CollectionPrivileges
                            orgId={orgId}
                            collectionId={collId.value}
                            users={members}
                            originalPrivileges={originalPrivileges.current}
                            newPrivileges={newPrivileges.current}
                            organizationPrivileges={organizationPrivileges}
                            setOrganizationPrivileges={setOrganizationPrivileges}
                        />
                    </div>
                    : <Loading />
                }
            </div>
            }
            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    width="w-16"
                    className="shrink-0"
                >
                    {collectionId ? "Update" : "Add"}
                </Buttoon>
                <Buttoon
                    type="button"
                    width="w-16"
                    className="shrink-0"
                    onClick={() => setShow(false)}
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}