import useSize from "@react-hook/size";
import { useEffect, useRef } from "react";

export default function WaveVisualizer({ audioStream, ...props }) {
    const canvasRef = useRef();
    const containerRef = useRef();
    const [width, height] = useSize(containerRef);

    useEffect(() => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        canvasCtx.clearRect(0, 0, width, height);

        if (!audioStream) return;
        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        const source = audioCtx.createMediaStreamSource(audioStream);
        source.connect(analyser);
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        let drawVisual;
        function draw() {
            drawVisual = requestAnimationFrame(draw);
            analyser.getByteTimeDomainData(dataArray);
            canvasCtx.fillStyle = "#425572";
            canvasCtx.fillRect(0, 0, width, height);
            canvasCtx.lineWidth = 3;
            canvasCtx.strokeStyle = "rgb(0, 0, 0)";
            canvasCtx.beginPath();

            const sliceWidth = width / bufferLength;
            let x = 0;
            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = v * (height / 2);

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(width, height / 2);
            canvasCtx.stroke();
        }
        draw();

        return () => window.cancelAnimationFrame(drawVisual);
    }, [audioStream, height, width])

    return <div className="flex-none grow relative h-full" ref={containerRef}>
        <div className="h-full w-full"></div>
        <canvas ref={canvasRef} className="bg-blue-light absolute top-0 left-auto start-auto rounded-full" />
    </div>
}