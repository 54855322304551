import { useCurrentOrganizationId } from "state/GeneralSlice";
import MembersDetails from "./MembersDetails";
import Header from "components/Header";
import Separator from "components/Separator";
import { withOrganizationPrivilegesRequired } from "utils/Privileges";

function Users() {
    const orgId = useCurrentOrganizationId();

    return <div className="py-2 mx-auto min-w-7xl max-w-7xl h-full flex flex-col">
        <Header title="Users" subtitle="Manage organization users" />
        <Separator className="mt-2" />
        <div className="">
            <MembersDetails orgId={orgId} addMember={false} />
        </div>
    </div>
}

export default withOrganizationPrivilegesRequired((props) => <Users {...props} />, "isAdmin");