import { ShieldCheckIcon, UserIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import MembersTable, { TableMode } from "./MembersTable";
import React, { useCallback, useMemo, useState } from 'react';
import { useAddOrganizationMemberMutation, useCreateOrganizationMemberMutation, useGetOrganizationMembersQuery, useRemoveOrganizationMemberMutation } from '../../../state/api/organizations';
import Buttoon from '../../Buttoon';
import Modal from '../Modal';
import SearchBar from '../knowledge/SearchBar';
import { useGetAllUsersQuery } from '../../../state/api/users';
import classNames from "classnames";

function OrganizationMembers({ orgId, setShowRemoveMember, setShowAddAdmin, setClickedMember }) {
    const { members } = useGetOrganizationMembersQuery({ org_uuid: orgId }, {
        skip: !orgId,
        selectFromResult: ({ data }) => ({ members: data || [] })
    });
    const admins = useMemo(() => members.filter((m) => m.is_admin).map((m) => m.uuid), [members]);
    const onAction = useCallback((user) => {
        setClickedMember(user);
        setShowRemoveMember(true);
    }, [setClickedMember, setShowRemoveMember])
    const onAdmin = useCallback((user) => {
        setClickedMember(user);
        setShowAddAdmin(true);
    }, [setClickedMember, setShowAddAdmin])

    return <MembersTable
        users={members}
        admins={admins}
        tableMode={TableMode.EDIT}
        onAction={onAction}
        onAdmin={onAdmin}
    />
}

function CreateUser({ setShow, orgId }) {
    const [value, setValue] = useState("");
    const [admin, setAdmin] = useState(false);

    const [createUser] = useCreateOrganizationMemberMutation();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        createUser({ org_uuid: orgId, params: { user_email: value, make_admin: admin } });
        setShow(false);
    }, [admin, createUser, orgId, setShow, value]);

    return <form onSubmit={handleSubmit} className="w-[1000px] mx-auto">
        <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Create User</p>

        <div className="border-t-2 border-blue my-2"></div>

        <div className="flex items-center gap-x-2">
            <div className="flex-1 overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                <label htmlFor="title" className="sr-only">
                    Title
                </label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required
                    className="block w-full border-0 pt-2.5 text-md font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                    placeholder="User Email"
                />
            </div>
            <button type="button" title="Make Admin" onClick={() => setAdmin(prev => !prev)}>
                <ShieldCheckIcon
                    className={classNames(
                        "w-6 stroke-blue-lightest hover:stroke-white",
                        admin ? "text-green stroke-white" : "text-transparent"
                    )}
                />
            </button>
        </div>

        <div className="flex items-center justify-end mt-2 gap-x-2">
            <Buttoon
                type="submit"
                width="w-16"
                className="shrink-0"
            >
                Create
            </Buttoon>
            <Buttoon
                type="button"
                onClick={() => setShow(false)}
                width="w-16"
                className="shrink-0"
                disabled={false}
            >
                Cancel
            </Buttoon>
        </div>
    </form>
}

function AddMember({ setShow, orgId }) {
    const [filter, setFilter] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const { allUsers } = useGetAllUsersQuery({ search_query: filter }, {
        selectFromResult: ({ data }) => ({ allUsers: data || [] })
    })
    const users = useMemo(() => allUsers.filter((u) => !selectedUsers.includes(u)), [allUsers, selectedUsers]);
    const { members } = useGetOrganizationMembersQuery({ org_uuid: orgId }, {
        selectFromResult: ({ data }) => ({ members: data.map((m) => m.uuid) || [] })
    })

    const onAdd = useCallback((user) => {
        setSelectedUsers((prev) => {
            const tmp = [...prev];
            tmp.push(user);
            return tmp;
        })
    }, []);
    const onRemove = useCallback((user) => {
        setSelectedUsers((prev) => {
            const tmp = [...prev];
            const idx = tmp.indexOf(user);
            if (idx >= 0) tmp.splice(idx, 1);
            return tmp;
        })
    }, []);
    const onAdmin = useCallback((user) => {
        setAdmins((prev) => {
            const tmp = [...prev];
            const id = user.uuid
            if (tmp.includes(id)) {
                const idx = tmp.indexOf(id);
                if (idx >= 0) tmp.splice(idx, 1);
            }
            else {
                tmp.push(id);
            }
            return tmp;
        })
    }, []);

    const [addMember] = useAddOrganizationMemberMutation();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        for (let i = 0; i < selectedUsers.length; i++) {
            addMember({
                org_uuid: orgId,
                user_uuid: selectedUsers[i].uuid,
                params: {
                    add_as_admin: admins.includes(selectedUsers[i].uuid)
                }
            });
        }
        setShow(false);
    }, [addMember, admins, orgId, selectedUsers, setShow]);

    return <form onSubmit={handleSubmit} className="w-[1000px] mx-auto">
        <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Add Members</p>
        <SearchBar filterinPattern={filter} setFilteringPattern={setFilter} />

        <div className="h-56 overflow-y-auto overflow-x-hidden bg-gray-darkest mt-2">
            <MembersTable tableMode={TableMode.ADD} users={users} disableds={members} onAction={onAdd} />
        </div>

        <div className="border-t-2 border-blue my-2"></div>

        <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Selected Members</p>
        <div className="h-56 overflow-y-auto overflow-x-hidden bg-gray-darkest">
            <MembersTable tableMode={TableMode.REMOVE} users={selectedUsers} admins={admins} onAction={onRemove} onAdmin={onAdmin} />
        </div>

        <div className="flex items-center justify-end mt-2 gap-x-2">
            <Buttoon
                type="submit"
                disabled={selectedUsers.length < 1}
                width="w-16"
                className="shrink-0"
            >
                Add
            </Buttoon>
            <Buttoon
                type="button"
                onClick={() => setShow(false)}
                width="w-16"
                className="shrink-0"
                disabled={false}
            >
                Cancel
            </Buttoon>
        </div>
    </form>
}

function RemoveMember({ setShow, orgId, user }) {
    const [removeMember] = useRemoveOrganizationMemberMutation();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        removeMember({ org_uuid: orgId, user_uuid: user.uuid })
        setShow(false);
    }, [orgId, removeMember, setShow, user.uuid]);

    return <form onSubmit={handleSubmit} className="w-auto mx-auto">
        <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Remove Member</p>
        <div className="border-t-2 border-blue my-2"></div>

        <p className="mt-3 text-blue-lightest">Are you sure you want to remove the member</p>
        <p className="text-blue-lightest font-bold">{user.name}</p>
        <p className="mb-1 text-blue-lightest">from this organization?</p>

        <div className="flex items-center justify-end mt-2 gap-x-2">
            <Buttoon
                type="submit"
                width="w-16"
                className="shrink-0"
            >
                Remove
            </Buttoon>
            <Buttoon
                type="button"
                onClick={() => setShow(false)}
                width="w-16"
                className="shrink-0"
                disabled={false}
            >
                Cancel
            </Buttoon>
        </div>
    </form>
}

function SetMemberAdmin({ setShow, orgId, user }) {
    const { isAdmin } = useGetOrganizationMembersQuery({ org_uuid: orgId }, {
        skip: !orgId,
        selectFromResult: ({ data }) => ({
            isAdmin: data
                ? data.filter((v) => v.is_admin).map((v) => v.uuid).includes(user.uuid)
                : false
        })
    })

    const [addMember] = useAddOrganizationMemberMutation();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        addMember({ org_uuid: orgId, user_uuid: user.uuid, params: { add_as_admin: !isAdmin } })
        setShow(false);
    }, [addMember, isAdmin, orgId, setShow, user.uuid]);

    const action = useMemo(() => isAdmin ? "remove" : "add", [isAdmin]);
    const side = useMemo(() => isAdmin ? "from" : "to", [isAdmin]);

    return <form onSubmit={handleSubmit} className="w-auto mx-auto">
        <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Set as Administration</p>
        <div className="border-t-2 border-blue my-2"></div>

        <p className="mt-3 text-blue-lightest">Are you sure you want to {action} the member</p>
        <p className="text-blue-lightest font-bold">{user.name}</p>
        <p className="mb-1 text-blue-lightest">{side} the administrators of this organization?</p>

        <div className="flex items-center justify-end mt-2 gap-x-2">
            <Buttoon
                type="submit"
                width="w-16"
                className="shrink-0"
            >
                Proceed
            </Buttoon>
            <Buttoon
                type="button"
                onClick={() => setShow(false)}
                width="w-16"
                className="shrink-0"
                disabled={false}
            >
                Cancel
            </Buttoon>
        </div>
    </form>
}

export default function MembersDetails({ orgId, createUser = true, addMember = true }) {
    const [showCreateuser, setShowCreateUser] = useState(false);
    const [showAddMember, setShowAddMember] = useState(false);
    const [showRemoveMember, setShowRemoveMember] = useState(false);
    const [showSetMemberAdmin, setShowSetMemberAdmin] = useState(false);
    const [clickedMember, setClickedMember] = useState();

    return <div>
        <div>
            <div className="flex items-center justify-between mt-3 mb-2">
                <p className="text-xl lg:text-2xl text-blue-lightest">Members</p>
                <div className="flex items-center gap-x-2">
                    {createUser && <button className="text-blue-lightest hover:text-white" title="Create User" onClick={() => setShowCreateUser(true)}>
                        <UserIcon className="w-6 h-6" />
                    </button>
                    }
                    {addMember && <button className="text-blue-lightest hover:text-white" title="Add Members" onClick={() => setShowAddMember(true)}>
                        <UserPlusIcon className="w-6 h-6" />
                    </button>
                    }
                </div>
            </div>
            <OrganizationMembers
                orgId={orgId}
                setShowRemoveMember={setShowRemoveMember}
                setShowAddAdmin={setShowSetMemberAdmin}
                setClickedMember={setClickedMember}
            />
        </div>
        {createUser && <Modal show={showCreateuser}>
            <CreateUser setShow={setShowCreateUser} orgId={orgId} />
        </Modal>
        }
        {addMember && <Modal show={showAddMember}>
            <AddMember setShow={setShowAddMember} orgId={orgId} />
        </Modal>
        }
        <Modal show={showRemoveMember}>
            <RemoveMember setShow={setShowRemoveMember} orgId={orgId} user={clickedMember} />
        </Modal>
        <Modal show={showSetMemberAdmin}>
            <SetMemberAdmin setShow={setShowSetMemberAdmin} orgId={orgId} user={clickedMember} />
        </Modal>
    </div>
}