// TODO: TEMPORARY IMPLEMENTATION
// TODO: Refactor this from build stage

// @ts-ignore
import { ReactComponent as SymboolicLogo } from "./symboolic-logo-v2.svg";

// @ts-ignore
import PaaiLogo from "./paaiLogo";

import React from "react";

export default function GetDefaultLogo() {
  switch (process.env.REACT_APP_CONFIG) {
    case "paai":
      return <PaaiLogo className={"fill-white h-10 w-auto mx-auto"} />;
    default:
      return <SymboolicLogo className={"fill-white h-5 w-auto mx-auto"} />;
  }
}