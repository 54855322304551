import { useOrganizationPrivileges } from "../../utils/Privileges";
import { useAuth0 } from "@auth0/auth0-react";
import UsageOverview from "./UsageOverview";
import { useCurrentOrganizationId } from "../../state/GeneralSlice";

const textClassName = "text-sm text-blue-lightest py-0.5 "

export default function Home() {
    const { user } = useAuth0();
    const { data: {isAdmin}, isLoading} = useOrganizationPrivileges();
    const orgId = useCurrentOrganizationId();

    return <div className="pt-2 mx-auto max-w-7xl px-6 lg:px-8">
        <p className="text-2xl lg:text-4xl mb-1">Welcome, {user?.name ? user.name : "-"}</p>
        {!isLoading && !orgId && <p className={"text-xl text-orange-pastel py-0.5"}>You are not associated with any organization.<br/><b>Contact your administrator!</b></p>}
        {!isLoading && orgId && <p className={textClassName}>Start chatting with your knowledge base</p>}
        {!isLoading && isAdmin && orgId &&
            <>
                <p className="text-xl lg:text-2xl mb-1 mt-8">Usage overview</p>
                <UsageOverview orgId={orgId} />
            </>
        }
    </div>
}
