import { useCallback, useMemo, useState } from "react";
import Buttoon from "../../Buttoon";
import { useCurrentCollectionId } from "../../../state/GeneralSlice";
import { useCollectionPrivileges } from "../../../utils/Privileges";
import ConfirmationDialog from "../ConfirmationDialog";
import { CogIcon } from "@heroicons/react/24/outline";
import TasksModal from "../tasks/TasksModal";
import { useAddCollectionListDocumentMutation, useGetCollectionListsQuery, useGetDocumentsInUploadQuery, useRemoveCollectionListDocumentMutation, useGetListsEnabledQuery, useGetWFDocumentsInUploadQuery } from "state/api/collections";
import classNames from "classnames";
import { useWorkInProgress } from "state/TasksSlice";
import { useWorkInProgress as WFuseWorkInProgress } from "state/WFTasksSlice";
import Modal from "../Modal";
import ComboBox from "components/ComboBox";
import AddKnowledgeButton from "./AddKnowledgeButton";
import AddNoteModal from "./AddNoteModal";
import TaskPoller from "components/TaskPoller";

function AddToList({ show, setShow, selectedDocuments }) {
    const collId = useCurrentCollectionId();
    const { data } = useGetCollectionListsQuery({ collection_id: collId });
    const items = useMemo(() => data ? data.map((l) => l.uuid) : [], [data]);
    const descriptions = useMemo(() => data ? data.map((l) => l.title) : [], [data]);

    const [selectedList, setSelectedList] = useState();
    const [addToList] = useAddCollectionListDocumentMutation();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        selectedDocuments.forEach(element => {
            addToList({ collection_id: collId, list_id: selectedList, document_id: element.filename });
        });
        setShow(false);
    }, [addToList, collId, selectedDocuments, selectedList, setShow]);

    return <Modal show={show}>
        <form onSubmit={handleSubmit} className="w-[500px]">
            <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Add Documents To List</p>
            <div className="rounded-lg border-0 border-dashed border-blue-lightest shadow-sm text-white z-50 h-full">
                <ComboBox
                    name="list"
                    label="Select a list"
                    items={items}
                    descriptions={descriptions}
                    onSelected={setSelectedList}
                />
            </div>

            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    width="w-16"
                    disabled={!selectedList}
                >
                    Add
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}

export default function ControlBar({ selectedDocuments, setSelectedDocuments, deleteFile, editDocument, setEditDocument, selectedList, title = "Knowledge Base", subTitle = "Manage your knowledge base", showAddNotes = true, addDocumentThreadId = null, wf = false }) {
    const collId = useCurrentCollectionId();

    const clearSelection = useCallback(() => {
        setSelectedDocuments([]);
    }, [setSelectedDocuments]);

    const deleteDocuments = useCallback((confirm) => {
        if (!confirm) return;
        Promise
            .all(selectedDocuments.map((element) => deleteFile({ collection_id: collId, file_id: element.filename })))
            .then((r) => clearSelection());
    }, [clearSelection, collId, deleteFile, selectedDocuments]);

    const [removeDocument] = useRemoveCollectionListDocumentMutation();
    const removeFromList = useCallback((confirm) => {
        if (!confirm) return;
        Promise
            .all(selectedDocuments.map((element) => removeDocument({ collection_id: collId, list_id: selectedList, document_id: element.filename })))
            .then((r) => clearSelection());
    }, [clearSelection, collId, removeDocument, selectedDocuments, selectedList]);

    const { data: { writeAccess } } = useCollectionPrivileges();
    const [showAddToList, setShowAddToList] = useState(false);
    const { data: listsEnabled } = useGetListsEnabledQuery({ collection_id: collId }, { pollingInterval: 30000, refetchOnMountOrArgChange: true });
    const [showRemoveFromList, setShowRemoveFromList] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [showTasks, setShowTasks] = useState(false);
    const { data } = useGetDocumentsInUploadQuery({ collection_id: collId }, { pollingInterval: 10000, refetchOnMountOrArgChange: true, skip: wf });
    const { data: wfData } = useGetWFDocumentsInUploadQuery({ collection_id: collId, thread_uuid: addDocumentThreadId }, { pollingInterval: 10000, refetchOnMountOrArgChange: true, skip: !wf });
    const tasks = useMemo(() => (addDocumentThreadId ? wfData : data) || [], [addDocumentThreadId, data, wfData]);

    const isWorking = useWorkInProgress();
    const WFisWorking = WFuseWorkInProgress();

    return <div className="sm:flex pb-0">
        <div className="flex w-full justify-between pr-1 sm:pr-8">
            <div className="sm:flex-auto">
                {title && <h1 className="text-base font-semibold text-white">{title}</h1>}
                <p className="text-sm text-blue-lightest">
                    {subTitle}
                </p>
            </div>
        </div>
        {tasks.map((el) => <TaskPoller key={el.document_id} documentId={el.document_id} taskId={el.task_id} wf={wf} />)}
        <div className="mt-4 sm:mt-0">
            {writeAccess && <>
                {editDocument && <AddNoteModal show={!!editDocument} editDocument={editDocument} onClose={() => setEditDocument()} />}
                {selectedDocuments.length > 0
                    ? <div className="flex w-full h-full sm:gap-x-4 items-center justify-evenly">
                        {listsEnabled && <>
                            <Buttoon
                                onClick={() => setShowAddToList(true)}
                                disabled={selectedDocuments.length === 0}
                            >
                                Add To List
                            </Buttoon>
                            <AddToList show={showAddToList} setShow={setShowAddToList} selectedDocuments={selectedDocuments} />
                            <Buttoon
                                onClick={() => setShowRemoveFromList(true)}
                                disabled={!selectedList}
                            >
                                Remove From List
                            </Buttoon>
                            <ConfirmationDialog confirmText="Remove" show={showRemoveFromList} setShow={setShowRemoveFromList} onClose={removeFromList} />
                        </>}
                        <Buttoon
                            onClick={() => setShowConfirmation(true)}
                            disabled={selectedDocuments.length === 0}
                            className="bg-orange hover:bg-orange-pastel"
                        >
                            Delete
                        </Buttoon>
                        <ConfirmationDialog show={showConfirmation} setShow={setShowConfirmation} onClose={deleteDocuments} />
                    </div>
                    : <div className="w-full h-full sm:gap-x-4 flex items-center justify-evenly">
                        <button title="Pending Tasks" onClick={() => setShowTasks(true)} className="text-blue-lightest hover:text-white">
                            <CogIcon className={classNames("h-9 w-9", { "animate-spin-slow": wf ? WFisWorking : isWorking })} />
                        </button>
                        {showTasks && <TasksModal show={showTasks} setShow={setShowTasks} tasks={tasks} wf={wf} />}
                        <AddKnowledgeButton showAddNotes={showAddNotes} addDocumentThreadId={addDocumentThreadId} />
                    </div>
                }
            </>}
        </div>
    </div>
}