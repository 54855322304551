import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";
import Buttoon from "components/Buttoon";
import Header from "components/Header";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useGetCollectionFeedbacksQuery } from "state/api/collections";
import ArchiveFeedbackButton from "./ArchiveFeedbackButton";
import _ from "lodash";
import DropDown from "components/DropDown";
import classNames from "classnames";
import { useGetUserDetailsQuery } from "state/api/users";
import Loading from "../Loading";
import { withOrganizationPrivilegesRequired } from "utils/Privileges";

function FeedbackMessage({ feedback }) {
    const { data: user } = useGetUserDetailsQuery({ user_uuid: feedback.thread_user_uuid });
    const date = useMemo(() => new Date(feedback.created).toLocaleDateString(), [feedback.created])
    const time = useMemo(() => new Date(feedback.created).toLocaleTimeString(), [feedback.created])
    const navigate = useNavigate();

    return <div className={classNames(
        "border-2 p-2 my-5 rounded bg-blue-dark",
        { "opacity-50": feedback.feedback_read },
        feedback.feedback_value ? "border-green-pastel" : "border-orange-pastel"
    )}>
        <div className="flex gap-x-2 items-center">
            <div className="px-2">
                {feedback.feedback_value
                    ? <HandThumbUpIcon className="h-6 w-6 text-green-pastel stroke-blue-lightest" />
                    : <HandThumbDownIcon className="h-6 w-6 text-orange-pastel stroke-blue-lightest" />
                }
            </div>
            <div className="w-full">
                <p className="text-justify">
                    {feedback.body}
                </p>
                <div className="border-t border-dashed border-gray-dark my-2" />
                <p className="text-justify text-white">
                    {feedback.feedback_string}
                </p>
            </div>
        </div>
        <div className="border-t border-gray-dark mt-3 mb-2" />
        <div className="sm:flex items-center justify-between">
            <div className="flex items-center gap-x-2 text-sm w-full mr-3">
                <div className="flex gap-x-1 items-center flex-grow">
                    <img src={user?.picture} className="h-6 w-6 rounded-full" alt="Avatar" />
                    <span className="font-bold">{user?.name}</span>
                    <span className="truncate">({user?.email})</span>
                </div>
                <div className="flex gap-x-2 items-center">
                    <span>{date}</span>
                    <span>{time}</span>
                </div>
            </div>
            <div className="sm:hidden my-1" />
            <div className="flex gap-x-2">
                <ArchiveFeedbackButton messageId={feedback.uuid} archived={feedback.feedback_read} />
                <Buttoon className="max-sm:flex-grow flex gap-x-2 items-center justify-center w-32" onClick={() => navigate(feedback.thread_uuid)}>
                    <ChatBubbleLeftRightIcon className="w-5" />
                    <span className="">Go to thread</span>
                </Buttoon>
            </div>
        </div>
    </div>
}

function Feedbacks() {
    const { data: feedbacks, isFetching } = useGroupedFeedbacks();

    return <div className="py-2 mx-auto max-w-7xl">
        <Header title="Feedbacks" subtitle="Check users feedbacks" />
        <div className="border-t border-gray mt-4 mb-1" />
        {isFetching
            ? <Loading />
            : <>
                {feedbacks[false] &&
                    <DropDown title="Unread" alreadyOpen={true} toolbar={<span className="font-bold mr-4">{feedbacks[false].length}</span>}>
                        {feedbacks[false].map((f) => <FeedbackMessage key={f.uuid} feedback={f} />)}
                    </DropDown>
                }
                <div className="my-8" />
                {feedbacks[true] &&
                    <DropDown title="Archived" toolbar={<span className="font-bold mr-4">{feedbacks[true].length}</span>}>
                        {feedbacks[true].map((f) => <FeedbackMessage key={f.uuid} feedback={f} />)}
                    </DropDown>
                }
            </>
        }
    </div>
}


function useGroupedFeedbacks() {
    const collId = useCurrentCollectionId();
    const response = useGetCollectionFeedbacksQuery({ collection_id: collId }, { skip: !collId });

    const grouped = useMemo(() => {
        const feedbacks = response.data ? [...response.data] : [];
        feedbacks.sort((a, b) => b.created - a.created);
        return _.groupBy(feedbacks, "feedback_read");
    }, [response.data]);

    return { ...response, data: grouped };
}

export default withOrganizationPrivilegesRequired((props) => <Feedbacks {...props} />, "isAdmin");