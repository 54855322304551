import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from "lexical";

import { $applyNodeReplacement, DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const QuoteComponent = React.lazy(
    // @ts-ignore
    () => import("./QuoteComponent")
);

export interface QuotePayload {
    text: string;
    messageId: string;
    key?: NodeKey;
}

function convertQuoteElement(domNode: Node): null | DOMConversionOutput {
    if (domNode instanceof HTMLQuoteElement) {
        const { textContent: text, id: messageId } = domNode;
        const node = $createQuoteNode({ text, messageId });
        return { node };
    }
    return null;
}

export type SerializedQuoteNode = Spread<
    {
        text: string;
        messageId: string;
    },
    SerializedLexicalNode
>;

export class QuoteNode extends DecoratorNode<JSX.Element> {
    __text: string;
    __messageId: string;

    static getType(): string {
        return "quote";
    }

    static clone(node: QuoteNode): QuoteNode {
        return new QuoteNode(node.__text, node.__messageId, node.__key);
    }

    static importJSON(serializedNode: SerializedQuoteNode): QuoteNode {
        const { text, messageId } = serializedNode;
        const node = $createQuoteNode({ text, messageId });
        return node;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement("blockquote");
        element.setAttribute("textContent", this.__text);
        return { element };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            blockquote: () => ({
                conversion: convertQuoteElement,
                priority: 0
            })
        };
    }

    constructor(text: string, messageId: string, key?: NodeKey) {
        super(key);
        this.__text = text;
        this.__messageId = messageId;
    }

    exportJSON(): SerializedQuoteNode {
        return {
            text: this.getText(),
            messageId: this.getMessageId(),
            type: "quote",
            version: 1
        };
    }

    // View
    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement("span");
        span.className = "editor-quote";
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getText(): string {
        return this.__text;
    }

    getMessageId(): string {
        return this.__messageId;
    }

    getTextContent(): string {
        return "\n>[" + this.__messageId + "]>" + this.__text + "\n\n";
    }

    decorate(): JSX.Element {
        return (
            <Suspense fallback={null}>
                <QuoteComponent text={this.__text} nodeKey={this.getKey()} />
            </Suspense>
        );
    }
}

export function $createQuoteNode({ text, messageId, key }: QuotePayload): QuoteNode {
    return $applyNodeReplacement(new QuoteNode(text, messageId, key));
}

export function $isQuoteNode(node: LexicalNode | null | undefined): node is QuoteNode {
    return node instanceof QuoteNode;
}
