import { ChatBubbleLeftIcon, InformationCircleIcon, LinkIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { Thread } from "./Thread";
import { useNavigate } from "react-router-dom";
import { UseSortedThreads } from "../../../state/api/threads";
import { useCurrentCollectionId, useCurrentOrganizationId } from "../../../state/GeneralSlice";
import { useIsExcel } from "state/api/collections";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useUrl } from "utils/Utils";

function ThreadsList({ title, items }) {
    return <>
        {(items && items.length > 0) && <>
            <div className="flex items-center gap-x-2 mr-2">
                <div className="border-t border-blue flex-1"></div>
                <div className="text-sm text-blue-light">{title}</div>
            </div>
            {items.map((element) =>
                <Thread key={element.uuid} element={element} />
            )}
        </>
        }
    </>
}

export function Threads() {
    const navigate = useNavigate();
    const orgId = useCurrentOrganizationId();
    const collId = useCurrentCollectionId();
    const { data: threads } = UseSortedThreads(collId);
    const isExcel = useIsExcel();

    const path = `chat/${orgId}/${collId}`;

    const { baseUrl } = useUrl();
    const copyMessage = useCallback(() => {
        navigator.clipboard.writeText(baseUrl + "/" + path + "/new");
        toast("Link copied!", { position: "top-left" });
    }, [baseUrl, path])

    return <div className="flex flex-col h-full">
        <div className="text-blue-lightest flex justify-between p-2 text-sm leading-6 font-semibold select-none">
            {isExcel
                ? <>
                    <button className="flex flex-1 items-center gap-x-3 hover:text-white" onClick={() => navigate(path)} title="New Chat">
                        <TableCellsIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        <span>Your Data</span>
                    </button>
                </>
                : <>
                    <div className="flex items-center gap-x-3">
                        <button className="flex items-center gap-x-3 hover:text-white" onClick={() => navigate(path + "/new")} title="New Chat">
                            <ChatBubbleLeftIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            <span>New Chat</span>
                        </button>
                        <button className="flex items-center hover:text-white" onClick={copyMessage} title="Copy Link">
                            <LinkIcon className="h-3 w-3 shrink-0" />
                        </button>
                    </div>
                    <button className="flex items-center gap-x-3 hover:text-white" onClick={() => navigate(path)}>
                        <InformationCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    </button>
                </>
            }
        </div>

        <div className="relative h-full">
            <div className="absolute ml-2 overflow-auto bottom-0 top-0 left-0 right-0">
                <ThreadsList title="Today" items={threads.today} />
                <ThreadsList title="Yesterday" items={threads.yesterday} />
                <ThreadsList title="Last Week" items={threads.lastWeek} />
                <ThreadsList title="Older" items={threads.older} />
                <ThreadsList title="Archived" items={threads.archived} />
            </div>
        </div>
    </div>
}