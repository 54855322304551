import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
    $createParagraphNode,
    $insertNodes,
    $isRootOrShadowRoot,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand
} from "lexical";
import { useEffect } from "react";
import { $createQuoteNode, QuoteNode, QuotePayload } from "./QuoteNode";

export type InsertQuotePayload = Readonly<QuotePayload>;

export const INSERT_QUOTE_COMMAND: LexicalCommand<InsertQuotePayload> = createCommand("INSERT_QUOTE_COMMAND");

export default function QuotePlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const container = document.querySelectorAll(".ContentEditable__root")[0];
        const observer = new ResizeObserver(() => (container.scrollTop = Math.pow(10, 10)));
        const root = document.querySelectorAll(".ContentEditable__root p")[0];
        observer.observe(root);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (!editor.hasNodes([QuoteNode])) {
            throw new Error("QuotePlugin: QuoteNode not registered on editor");
        }

        return mergeRegister(
            editor.registerCommand<InsertQuotePayload>(
                INSERT_QUOTE_COMMAND,
                (payload) => {
                    const quoteNode = $createQuoteNode(payload);
                    $insertNodes([quoteNode]);
                    if ($isRootOrShadowRoot(quoteNode.getParentOrThrow())) {
                        $wrapNodeInElement(quoteNode, $createParagraphNode).selectEnd();
                    }
                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            )
        );
    }, [editor]);

    return null;
}
