export default function Agents(){
    // const {data} = useGetUserDetailsUsersMeGetQuery(undefined, {
    //     pollingInterval: 1000,
    //     selectFromResult: result => {
    //         return {
    //             ...result,
    //             data: result?.data?.full_name
    //         }
    //     }
    // })
    // console.log("data", data)

    // const [trigger] = useEditUserConfigUsersMeConfigPutMutation({
    //     selectFromResult: () => ({})
    // })
    // console.log(trigger)

    return <div>Agents</div>
}