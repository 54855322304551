import { useEffect, useRef, useState } from "react"

export default function ReactQuote({ content }) {
    const [text, setText] = useState("");

    const ref = useRef();
    useEffect(() => {
        const txt = ref.current.textContent.trim();
        if (txt.startsWith("[")) {
            const idx = txt.indexOf("]>");
            if (idx !== -1) {
                setText(txt.substring(idx + 2));
                return;
            }
        }
        setText(txt);
    }, [text])

    return <blockquote className="rounded-md py-1 px-2 my-1 text-gray bg-gray-darkest italic">
        <span ref={ref} className="hidden">{content}</span>
        {text}
    </blockquote>
}