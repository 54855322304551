import React, { useMemo } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import configuration from "../application.json";
import {publicResource} from "../utils/Utils";

// Map configuration_name to the Header Detail Provider
const HEADER_CONFIG: Record<string, React.JSX.Element> = {
    "": <>
        <title>{configuration.applicationName}</title>
        <link rel="icon" href={publicResource("favicon.ico")} />
        <meta name="description" content={configuration.applicationName} />
        <link rel="apple-touch-icon" href={publicResource("logo192.png")} />
    </>,
    "ask_directout": <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <script type="text/javascript">
            {`var _iub = _iub || [];
                _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"enableFadp":true,"enableLgpd":true,"enableUspr":false,"fadpApplies":true,"floatingPreferencesButtonDisplay":"anchored-center-right","lang":"en","perPurposeConsent":true,"siteId":3418899,"usprApplies":true,"whitelabel":false,"cookiePolicyId":16666463, "banner":{"acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true,"showTitle":false}};`}
        </script>
        <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3418899.js"></script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stable/stub.js"></script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/stable/iubenda_cs.js" charSet="UTF-8" async></script>
    </>
};


type Props = {
    children?: React.ReactNode
}

function HeaderProvider(props: Props) {
    const extraHeader = useMemo(() => {
        if(HEADER_CONFIG.hasOwnProperty(process.env.REACT_APP_CONFIG)) {
            console.debug(`Adding extra header -> ${process.env.REACT_APP_CONFIG}`)
            return HEADER_CONFIG[process.env.REACT_APP_CONFIG];
        }

        return undefined;
    }, [])


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    {HEADER_CONFIG[""]}
                    {extraHeader !== undefined ? extraHeader : null}
                </Helmet>
                {props.children}
            </HelmetProvider>
        </>
    )
}

export default HeaderProvider;