import { useCurrentCollectionId } from "state/GeneralSlice";
import { useGetDocumentsQuery } from "state/api/collections";

export default function MarkdownDocument({ uuid }) {
    const collId = useCurrentCollectionId();
    const { doc } = useGetDocumentsQuery(
        { collection_id: collId },
        {
            selectFromResult: ({ data }) => ({ doc: data?.find(d => d.filename === uuid) })
        }
    );
    return doc && <span className="italic text-gray">{doc.title}.{doc.filetype}</span>
}