import { SquaresPlusIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddOrganizationMutation, useGetAllOrganizationsQuery } from '../../../state/api/organizations';
import Buttoon from '../../Buttoon';
import DropDown from '../../DropDown';
import Modal from '../Modal';
import UsageOverview from '../UsageOverview';
import Header from 'components/Header';
import { withGodPrivilegesRequired } from 'utils/Privileges';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

function Toolbar({ orgId }) {
    const navigate = useNavigate();

    return <div>
        <button className="text-white" title="Manage" onClick={() => navigate("/admin/" + orgId)}>
            <ChevronRightIcon className="w-8 h-8 text-blue-lightest hover:text-white" />
        </button>
    </div>
}

function AddOrganization({ setShow }) {
    const [addOrganization] = useAddOrganizationMutation();

    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        const name = event.target.name.value;
        const description = event.target.description.value;
        const domain = event.target.domain.value;
        addOrganization({ name: name, description: description, domain: domain === "" ? null : domain });
        setShow(false);
    }, [addOrganization, setShow]);

    return <form onSubmit={handleSubmit} className="w-[500px]">
        <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">New Organization</p>
        <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm text-white">
            <label htmlFor="name" className="sr-only">
                Name
            </label>
            <input
                type="text"
                name="name"
                id="name"
                required
                className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                placeholder="Name"
            />
            <label htmlFor="description" className="sr-only">
                Description
            </label>
            <div className="border-t-2 border-blue-dark mx-2"></div>
            <textarea
                rows={2}
                name="description"
                id="description"
                required
                className="block w-full h-24 resize-none border-0 rounded-b-lg placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                placeholder="Description..."
            />
            <div className="border-t-2 border-blue-dark mx-2"></div>
            <label htmlFor="domain" className="sr-only">
                Domain
            </label>
            <input
                type="text"
                name="domain"
                id="domain"
                className="block w-full border-0 pt-2.5 rounded-lg mt-2 text-sm placeholder:text-blue-lightest focus:ring-0 bg-blue"
                placeholder="Domain"
            />
        </div>

        <div className="flex items-center justify-end mt-2 gap-x-2">
            <Buttoon
                type="submit"
                disabled={false}
                width="w-16"
                className="shrink-0"
            >
                Add
            </Buttoon>
            <Buttoon
                type="button"
                onClick={() => setShow(false)}
                width="w-16"
                className="shrink-0"
                disabled={false}
            >
                Cancel
            </Buttoon>
        </div>
    </form>
}

function Administration() {
    const [showAddOrganization, setShowAddOrganization] = useState(false);
    const { organizations } = useGetAllOrganizationsQuery(undefined, {
        selectFromResult: ({ data }) => ({ organizations: data || [] })
    });

    return <div className="xl:w-1/2 xl:px-0 w-full mx-auto px-4 py-2">
        <Header
            title="Administration"
            subtitle="Manage organizations"
            backTitle="Dashboard"
            backRoute="/"
            toolComponent={
                <button
                    className="text-blue-lightest hover:text-white"
                    title="Add Organization"
                    onClick={() => setShowAddOrganization(true)}>
                    <SquaresPlusIcon className="w-8 h-8" />
                </button>
            }
        />
        <div className="mt-8">
            <div className="block">
                <div className="border-t border-gray">
                    {organizations.map((org) =>
                        <DropDown key={org.uuid} title={org.name} titleContent={"| " + org.description} toolbar={<Toolbar orgId={org.uuid} />}>
                            <div className="px-3">
                                <UsageOverview orgId={org.uuid} />
                            </div>
                        </DropDown>
                    )}
                </div>
            </div>
        </div>
        <Modal show={showAddOrganization}>
            <AddOrganization setShow={setShowAddOrganization} />
        </Modal>
    </div>
}

export default withGodPrivilegesRequired((props) => <Administration {...props} />)