import classNames from "classnames";

export default function Loading({ size = "w-8 h-8", stroke = "border-4", center = true, className }) {
    return <div className={classNames("text-center text-blue-lightest", className)}>
        <div
            className={classNames(
                "animate-spin rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
                size,
                stroke,
                { "inline-block": center }
            )}
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >
                Loading...
            </span>
        </div>
    </div>;
}