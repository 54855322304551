import { OrganizationPrivilegesSelection, UserPrivilegesSelection } from "./PrivilegesSelection"
import UserInfo from "./UserInfo"
import { useGetOrganizationQuery } from "state/api/organizations";

export default function CollectionPrivileges({ orgId, collectionId, users, originalPrivileges, newPrivileges, organizationPrivileges, setOrganizationPrivileges }) {
    const { data } = useGetOrganizationQuery({ org_uuid: orgId }, { skip: !orgId });
    return <>
        <div className="px-3">
            <div className="mb-2 font-bold text-md">Whole Organization <span className="font-normal">({data?.name})</span></div>
            <OrganizationPrivilegesSelection
                collectionId={collectionId}
                organizationPrivileges={organizationPrivileges}
                setOrganizationPrivileges={setOrganizationPrivileges}
            />
        </div>
        {
            users.map((u) => <div key={u.uuid} className="px-3">
                <div className="mb-2"><UserInfo user={u} /></div>
                <UserPrivilegesSelection
                    collectionId={collectionId}
                    userId={u.uuid}
                    originalPrivileges={originalPrivileges}
                    newPrivileges={newPrivileges}
                    organizationPrivileges={organizationPrivileges}
                />
            </div>)
        }
    </>
}