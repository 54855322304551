import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export default function Header({ title, subtitle, backTitle, backRoute, toolComponent }) {
    const navigate = useNavigate();

    return <>
        {backTitle &&
            <button className="flex items-center gap-x-2 text-blue-lightest hover:text-white"
                title="Back"
                onClick={() => navigate(backRoute)}>
                <ChevronLeftIcon className="w-6 h-6" />
                <p className="text-xl lg:text-2xl mb-2 mt-1">{backTitle}</p>
            </button>
        }
        <div className="flex items-center justify-between">
            <div className="">
                <h1 className="text-base font-semibold leading-6 text-white">
                    {title}
                </h1>
                <p className="mt-2 text-sm text-blue-lightest">
                    {subtitle}
                </p>
            </div>
            {toolComponent}
        </div>
    </>
}