import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { RESET_VALUE, ResetSettingButton, useFetchSetting } from "./settingsUtils";
import ReactTextareaAutosize from "react-textarea-autosize";

const MAX_CHAR = Infinity;

function ControlledTextArea({ idx, name, isArray, value, textChanged, isLast }) {
    /*Workaround to fix textarea size */
    const [rendered, setRendered] = useState(false);
    useLayoutEffect(() => {
        setTimeout(() => setRendered(true), 500);
    }, [])

    return <ReactTextareaAutosize
        name={name}
        id={name}
        minRows={isArray ? 1 : 8}
        maxRows={Infinity}
        value={value}
        onChange={textChanged}
        className={classNames(
            "block w-full overflow-hidden h-full resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6",
            { "rounded-md": !isArray },
            { "rounded-t-md": isArray && idx === 0 },
            { "rounded-b-md": isArray && isLast },
            idx % 2 === 0 ? "bg-blue" : "bg-blue-light"
        )}
        placeholder="Insert prompt..."
    />
}

function PromptTextArea({ setting, value, setValue, onLoaded, isValid, setValid }) {
    const isArray = useMemo(() => Array.isArray(value), [value]);

    const contents = useMemo(() => {
        if (!value) return [""];
        if (isArray) return value;
        else return [value];
    }, [isArray, value]);

    const validate = useCallback((string) => {
        const plc = setting.requested_placeholders;
        if (!plc || plc.length === 0) return true;
        for (let i = 0; i < plc.length; i++) {
            if (!string.includes(plc[i])) return false;
        }
        return true;
    }, [setting.requested_placeholders])

    const textChanged = useCallback((idx, v) => {
        if (idx === -1) { //fetch dal server
            if (validate) {
                if (Array.isArray(v)) setValid(validate(v.join()));
                else setValid(validate(v));
            }
            else setValid(true);
        }
        else {
            if (validate) {
                if (!isArray) setValid(validate(v));
                else {
                    const tmp = [...contents];
                    tmp[idx] = v;
                    setValid(validate(tmp.join()));
                }
            }
            else setValid(true);
        }
        if (contents.join().length <= MAX_CHAR) {
            if (idx === -1 || !isArray) setValue(v);
            else setValue((prev) => {
                const tmp = [...prev];
                tmp[idx] = v;
                return tmp;
            });
        }
    }, [contents, isArray, setValid, setValue, validate]);

    const checkFetched = useCallback((fetched) => textChanged(-1, fetched), [textChanged]);

    useFetchSetting(setting, value, checkFetched, onLoaded);

    return <div className="w-full">
        <label htmlFor="prompt" className="block text-sm font-medium leading-6 mb-2">
            <Title isValid={isValid} setting={setting} />
        </label>
        <div className={classNames("overflow-y-auto rounded-md", isArray ? "max-h-64 h-64" : "max-h-52 h-52")}>
            {contents.map((el, idx) => <ControlledTextArea
                key={idx}
                idx={idx}
                setting={setting}
                name={setting.config_name}
                isArray={isArray}
                value={el}
                textChanged={(e) => textChanged(idx, e.target.value)}
                isLast={idx === contents.length - 1}
            />)}
        </div>
        <div className="flex justify-between items-center content-center mt-1 px-1">
            {MAX_CHAR !== Infinity ? <span className="text-xs">{contents.join().length} / {MAX_CHAR}</span> : <span></span>}
            {setting.reset_button && <ResetSettingButton setting={setting} onReset={() => setValue(RESET_VALUE)} />}
        </div>
    </div>
}

function Title({ isValid, setting }) {
    const placeholders = useMemo(() => {
        const plc = setting.requested_placeholders;
        if (!plc || plc.length === 0) return "";
        let str = "(must contain ";
        for (let i = 0; i < plc.length; i++) {
            str += plc[i]
            if (plc.length > 1 && i < plc.length - 1) {
                if (i < plc.length - 2) str += ", ";
                else str += " and ";
            }
            else str += " ";
        }
        str += "placeholder";
        if (plc.length > 1) str += "s";
        str += ")";
        return str;
    }, [setting.requested_placeholders]);

    return <>
        {setting.description} <span className={classNames({ "text-orange": !isValid })}>{placeholders}</span>
        {setting.show_language && <p className="font-normal text-xs">You can use {"{language}"} placeholder to indicate the preferred model language</p>}
    </>
}

//*************************************** 
export const PromptInput = (props) => {
    return <PromptTextArea {...props} />
}

export const PromptNoContextInput = (props) => {
    return <PromptTextArea {...props} />
}

export const PromptValhalla = (props) => {
    return <PromptTextArea {...props} />
}

export const PromptValhallaFinal = (props) => {
    return <PromptTextArea {...props} />
}