import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useState } from "react";

export function useLexicalEditorContainer() {
    const [editor] = useLexicalComposerContext();

    const [container, setContainer] = useState<Element>();
    useEffect(() => {
        const root = editor.getRootElement();
        if (root) {
            setContainer(root.closest(".editor-container"));
        }
    }, [editor]);

    return container;
}
