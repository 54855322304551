import { useGetHomescreenTypeQuery } from "state/api/collections";
import NewChat from "./NewChat";
import Loading from "../Loading";
import ExcelVisualizer from "./ExcelVisualizer";
import { useParams } from "react-router-dom";

export default function Homescreen() {
    const { orgId, collectionId } = useParams();
    const { data } = useGetHomescreenTypeQuery({ collection_id: collectionId }, { skip: !collectionId });

    switch (data) {
        case "excel": return <ExcelVisualizer orgId={orgId} collId={collectionId} />
        case "chat": return <NewChat orgId={orgId} collId={collectionId} />
        default: <Loading />
    }
}