import { CloudArrowUpIcon, DocumentArrowUpIcon, DocumentTextIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import AddDocumentModal from "./AddDocumentModal";
import AddVoiceModal from "./AddVoiceModal";
import AddNoteModal from "./AddNoteModal";
import { Popover } from "@headlessui/react";
import Separator from "components/Separator";

function KnowledgeButton({ idx, setActiveModal, Icon, text }) {
    return <>
        {idx > 0 && <Separator className="my-[3px]" />}
        <button
            type="button"
            title="Add Knowledge"
            className="flex w-full rounded-md gap-x-3 items-center px-2 py-1 text-sm text-white hover:bg-blue-light"
            onClick={() => setActiveModal(idx)}
        >
            <Icon className="h-6 w-6 shrink-0" aria-hidden="true" />
            <span className="whitespace-nowrap">{text}</span>
        </button>
    </>
}

function KnowledgeModal({ idx, activeModal, setActiveModal, Modal, ...props }) {
    const show = useMemo(() => idx === activeModal, [activeModal, idx])

    return <>
        {show &&
            <Modal show={show} onClose={() => setActiveModal(-1)} {...props} />
        }
    </>
}

export default function AddKnowledgeButton({ showAddNotes, addDocumentThreadId }) {
    const [activeModal, setActiveModal] = useState(-1);

    return <>
        <Popover className="relative flex items-center">
            <Popover.Button className="text-blue-lightest hover:text-white"><CloudArrowUpIcon className="h-9 w-9" /></Popover.Button>

            <Popover.Panel className="absolute top-[110%] rounded-md p-1 right-0 z-10 bg-blue border-blue-light border-[1px]">
                <KnowledgeButton Icon={DocumentArrowUpIcon} text="Upload File" idx={0} setActiveModal={setActiveModal} />
                {showAddNotes && <>
                    <KnowledgeButton Icon={DocumentTextIcon} text="Write Text Note" idx={1} setActiveModal={setActiveModal} />
                    <KnowledgeButton Icon={MicrophoneIcon} text="Record Voice Note" idx={2} setActiveModal={setActiveModal} />
                </>}
            </Popover.Panel>
        </Popover >
        <KnowledgeModal Modal={AddDocumentModal} idx={0} activeModal={activeModal} setActiveModal={setActiveModal} threadId={addDocumentThreadId} />
        {
            showAddNotes && <>
                <KnowledgeModal Modal={AddNoteModal} idx={1} activeModal={activeModal} setActiveModal={setActiveModal} />
                <KnowledgeModal Modal={AddVoiceModal} idx={2} activeModal={activeModal} setActiveModal={setActiveModal} />
            </>
        }
        {/* <KnowledgeModal Modal={AddWebUrlModal} idx={3} activeModal={activeModal} setActiveModal={setActiveModal} />*/}
    </>
}