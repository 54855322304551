import _ from "lodash";
import { useCallback, useRef } from "react";
import { useAddCollectionPrivilegesMutation, useRemoveCollectionPrivilegesMutation } from "state/api/collections";
import { PrivilegesType, useLazyCollectionPrivileges } from "utils/Privileges";

function addToPayload(payload, uuid, collectionId, privileges, add, org) {
    if (privileges.length === 0) return;
    const userOrOrg = org ? "org_privileges" : "user_privileges";

    if (!payload[add]) payload[add] = {};
    const type = payload[add];

    if (!type[collectionId]) type[collectionId] = {};
    const collection = type[collectionId];

    if (!collection[userOrOrg]) collection[userOrOrg] = [];
    const list = collection[userOrOrg];

    list.push({
        uuid: uuid,
        privilege: privileges,
    })
}

function readPrivileges(payload, uuid, collectionId, privileges, org) {
    const toAdd = [];
    const toRemove = [];
    for (var k = 1; k < privileges.length; k++) {
        if (privileges[k].access) toAdd.push(privileges[k].privilege);
        else toRemove.push(privileges[k].privilege);
    }

    addToPayload(payload, uuid, collectionId, toAdd, true, org);
    addToPayload(payload, uuid, collectionId, toRemove, false, org);
}

export function createPrivilegesPayload(orgId, newPrivileges, organizationPrivileges) {
    const payload = {};

    const grouped = _.groupBy(Object.values(newPrivileges), "collectionId");
    for (const collectionId in grouped) {
        const users = grouped[collectionId];
        for (var i = 0; i < users.length; i++) {
            const usr = users[i];
            const privileges = usr.privileges;
            readPrivileges(payload, usr.userId, collectionId, privileges, false);
        }
    }

    for (const collectionId in organizationPrivileges) {
        const org = organizationPrivileges[collectionId];
        if (!org.modified) continue;
        const privileges = org.privileges;
        readPrivileges(payload, orgId, collectionId, privileges, true);
    }

    return payload;
}

//**************************
export function privilegesKey(userId, collectionId) { return userId + "-" + collectionId };

export function useLoadPrivilegesCallback() {
    const [getCollectionPrivileges] = useLazyCollectionPrivileges();
    const loadPrivileges = useCallback((orgId, collections, members, onCompleted = () => { }) => {
        if (collections.length === 0) {
            onCompleted({}, {});
            return;
        }
        const originalPrivileges = {};
        const orgPrivileges = {};
        const loadedCollections = Array.from(collections, () => false);
        for (var i = 0; i < collections.length; i++) {
            const loadedMembers = Array.from(members, () => false);
            const collectionId = collections[i].uuid;
            const ji = i;
            getCollectionPrivileges({ orgId: orgId, collectionId: collectionId, userId: members[0].uuid, type: PrivilegesType.ORGANIZATION }, false)
                .then(({ data: org }) => {
                    orgPrivileges[collectionId] = {
                        modified: false,
                        privileges: [
                            { name: "None", access: true, privilege: "" },
                            { name: "Chat", access: org.chatAccess, privilege: "chat_access" },
                            { name: "Read", access: org.readAccess, privilege: "read_access" },
                            { name: "Write", access: org.writeAccess, privilege: "write_access" },
                            { name: "Admin", access: org.adminAccess, privilege: "admin_access" },
                        ],
                    }
                    for (var k = 0; k < members.length; k++) {
                        const jk = k;
                        const userId = members[k].uuid;
                        getCollectionPrivileges({ orgId: orgId, collectionId: collectionId, userId: userId, type: PrivilegesType.USER }, false)
                            .then(({ data: user }) => {
                                originalPrivileges[privilegesKey(userId, collectionId)] = {
                                    userId: userId,
                                    collectionId: collectionId,
                                    privileges: [
                                        { name: "None", access: true, privilege: "" },
                                        { name: "Chat", access: user.chatAccess, privilege: "chat_access" },
                                        { name: "Read", access: user.readAccess, privilege: "read_access" },
                                        { name: "Write", access: user.writeAccess, privilege: "write_access" },
                                        { name: "Admin", access: user.adminAccess, privilege: "admin_access" },
                                    ],
                                }
                                loadedMembers[jk] = true;
                                if (loadedMembers.every((v) => v)) {
                                    loadedCollections[ji] = true;
                                    if (loadedCollections.every((v) => v)) {
                                        onCompleted(orgPrivileges, originalPrivileges);
                                    }
                                }
                            });
                    }
                });
        }

    }, [getCollectionPrivileges]);

    return loadPrivileges;
}

//**************************
function checkResults(addResults, removeResults, completed, onCompleted) {
    if (addResults.every((v) => v.completed) && removeResults.every((v) => v.completed)) {
        if (!completed.current) {
            completed.current = true;
            if (addResults.every((v) => v.status) && removeResults.every((v) => v.status)) onCompleted(true);
            else onCompleted(false);
        }
    }
}

export function useSavePrivilegesCallback() {
    const completed = useRef();
    const [addPrivileges] = useAddCollectionPrivilegesMutation();
    const [removePrivileges] = useRemoveCollectionPrivilegesMutation();

    const callback = useCallback((privilegesPayload, onCompleted = () => { }) => {
        completed.current = false;
        const payload = privilegesPayload;

        const toAdd = payload[true];
        const toAddKeys = Object.keys(toAdd || {});
        const addResults = Array(toAddKeys.length);
        _.fill(addResults, { completed: false, status: undefined });

        const toRemove = payload[false];
        const toRemoveKeys = Object.keys(toRemove || {});
        const removeResults = Array(toRemoveKeys.length);
        _.fill(removeResults, { completed: false, status: undefined });

        toAddKeys.forEach((collectionId, idx) => {
            const body = toAdd[collectionId];
            addPrivileges({ collection_id: collectionId, body: body }).then((r) => {
                addResults[idx].completed = true;
                addResults[idx].status = !r.error;
                checkResults(addResults, removeResults, completed, onCompleted);
            });
        })

        toRemoveKeys.forEach((collectionId, idx) => {
            const body = toRemove[collectionId];
            removePrivileges({ collection_id: collectionId, body: body }).then(r => {
                removeResults[idx].completed = true;
                removeResults[idx].status = !r.error;
                checkResults(addResults, removeResults, completed, onCompleted);
            });
        });

        checkResults(addResults, removeResults, completed, onCompleted);
    }, [addPrivileges, removePrivileges]);

    return callback;
}