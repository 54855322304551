import { emptyApi as api } from "./emptyApi";

const utilsRtkApi = api.enhanceEndpoints({}).injectEndpoints({
    endpoints: (build) => ({
        getPipelineTypes: build.query<ValueDescriptionCouples, undefined>({
            query: () => ({
                url: "utils/pipeline-types"
            })
        }),
        getLlmModels: build.query<ValueDescriptionCouples, undefined>({
            query: () => ({
                url: "utils/llm-models"
            })
        }),
        getResourceTypes: build.query<ValueDescriptionCouples, undefined>({
            query: () => ({
                url: "utils/resource-types"
            })
        }),
        getBudgetTypes: build.query<ValueDescriptionCouples, undefined>({
            query: () => ({
                url: "utils/buget-voice-types"
            })
        }),
        getExcelMock: build.query<string, undefined>({
            query: () => ({
                url: "utils/mock-excel"
            })
        })
    }),
    overrideExisting: false
});

export default utilsRtkApi;

export type ValueDescriptionCouples = Record<string, string>;

export const {
    useGetPipelineTypesQuery,
    useGetLlmModelsQuery,
    useGetResourceTypesQuery,
    useGetBudgetTypesQuery,
    useGetExcelMockQuery
} = utilsRtkApi;
