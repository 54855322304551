import { Transition } from "@headlessui/react";
import { useMemo, useRef } from "react";

export default function ProgressBar({
    value = 12.2,
    max = 100,
    unit = "",
    description = "",
    showPerc = false,
    transition = true,
    showValue = true,
    showDescription = true,
    showNumber = true,
    height = "h-5",
    color = "bg-azure",
    onSeeked = (percentage) => { }
}) {
    const valuePerc = useMemo(() => Math.min(value / max * 100, 100), [max, value])
    const show = useMemo(() => valuePerc > 0 && valuePerc < Infinity, [valuePerc]);
    const container = useRef();

    const onClick = (e) => {
        const bcr = container.current.getBoundingClientRect();
        const percentage = (e.clientX - bcr.left) / bcr.width;
        onSeeked(percentage);
    }

    return <button
        type="button"
        className="relative w-full bg-transparent rounded-full text-xs font-medium text-white text-center h-full"
        ref={container}
        disabled={showValue}
        onClick={onClick}
    >
        <div className={"absolute left-0 right-0 top-0 bottom-0 my-auto w-full bg-blue rounded-full text-xs font-medium text-white text-center " + height}>
            <Transition
                appear={transition}
                show={!transition ? true : show}
                enter="transition-[width] duration-[2000ms]"
                enterFrom="w-0"
                enterTo="w-full"
                className="absolute left-0 right-0 top-0 bottom-0 my-auto"
            >
                <div
                    className={"p-0.5 leading-none rounded-full h-full " + color}
                    style={{ width: valuePerc + "%" }}
                >
                </div>
            </Transition>
        </div>
        {showValue && <div className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center">
            {showDescription &&
                <span className="absolute left-2 text-xs align-start select-none">
                    {description}
                </span>
            }
            {showNumber &&
                <span className={"align-center select-none" + (showPerc ? " absolute right-2" : "")}>
                    {showPerc
                        ? <>{valuePerc.toFixed(0)} %</>
                        : <>{value} / {max} {unit}</>
                    }
                </span>
            }
        </div>
        }
    </button>
}