import { useMemo } from "react";
import { useGetOrganizationBudgetSummaryQuery } from "../../state/api/organizations";
import ProgressBar from "../ProgressBar";

function useBudget(orgId, resource) {
    return useGetOrganizationBudgetSummaryQuery({ org_uuid: orgId }, {
        skip: !orgId,
        pollingInterval: 30000,
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ data, isLoading }) => ({
            budget: data && data[resource] ?
                data[resource] :
                {
                    used: 0,
                    total: 0,
                    expires: 0
                },
            isLoading: isLoading
        })
    });
}

function Counter({ orgId, title, resource, unit = "", converter = (value) => value }) {
    const { budget, isLoading } = useBudget(orgId, resource);

    const { used, total, expires } = useMemo(() => {
        if (!budget) return {
            total: 0,
            used: 0,
            expires: new Date(0),
        }

        const total = converter(budget.total);
        const used = converter(budget.used);
        return {
            total: Number.isInteger(total) ? total : total.toFixed(2),
            used: Number.isInteger(used) ? used : used.toFixed(2),
            expires: budget?.expires ? new Date(budget.expires * 1000).toLocaleDateString() : null,
        };
    }, [budget, converter]);

    return <div className="py-2">
        <div className="flex items-baseline justify-between">
            <div className="flex items-center gap-x-2">
                <p className="mb-1 text-blue-lightest">{title}</p>
            </div>
            {expires && <p className="mb-1 text-blue-lightest text-xs">{expires}</p>}
        </div>
        <ProgressBar value={used} max={total} unit={unit} showValue={!isLoading}></ProgressBar>
    </div>
}

function getCounters(orgId) {
    return [
        <Counter orgId={orgId} title="Requests" resource="adv_query" />,
        <Counter orgId={orgId} title="Speech to Text" resource="stt_seconds" unit="Seconds" />,
        <Counter orgId={orgId} title="Embedding" resource="embedding_kb" unit="MB" converter={(v) => v / 1000} />,
        <Counter orgId={orgId} title="Storage" resource="storage_kb" unit="MB" converter={(v) => v / 1000} />,
        <Counter orgId={orgId} title="Collections" resource="collections" />,
        <Counter orgId={orgId} title="User Creations" resource="user_creations" />,
    ]
}


export default function UsageOverview({ orgId, hide = [] }) {
    return <>
        {getCounters(orgId).map((el, idx) => !hide.includes(el.props.title) ? <div key={idx}>{el}</div> : undefined)
        }
    </>
}