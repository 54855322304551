import { useCallback, useEffect, useState } from "react";
import Pagination from "./knowledge/Pagination";
import ControlBar from "./knowledge/ControlBar";
import Table from "./knowledge/Table";
import Loading from "./Loading";
import SearchBar from "./knowledge/SearchBar";
import { useDeleteFileMutation, useFilteredDocuments } from "../../state/api/collections";
import { useCurrentCollectionId } from "../../state/GeneralSlice";
import ListSelector from "./knowledge/ListSelector";
import { withCollectionPrivilegesRequired } from "utils/Privileges";
export const DOCUMENTS_PER_PAGE = 20;

function KnowledgeBase() {
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [filteringPattern, setFilteringPattern] = useState('');
    const [filteringIgnoreCase, setFilteringIgnoreCase] = useState(true);
    const [sortingProperty, setSortingProperty] = useState("title");
    const [sortingDescending, setSortingDescending] = useState(true);
    const [editDocument, setEditDocument] = useState();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();
    const [selectedList, setSelectedList] = useState();

    const collId = useCurrentCollectionId();
    const { isLoading, data: { documents, documentsCount } } = useFilteredDocuments(
        collId,
        DOCUMENTS_PER_PAGE,
        pageNumber,
        filteringPattern,
        filteringIgnoreCase,
        sortingProperty,
        sortingDescending,
        selectedList,
    );

    useEffect(() => {
        setPageNumber(1);
    }, [filteringPattern]);

    const onSelectedList = useCallback((listId) => {
        setSelectedList(listId);
        setSelectedDocuments([]);
        setFilteringPattern("");
        setPageNumber(1);
    }, []);

    return <div className="py-2 mx-auto max-w-7xl flex flex-col items-stretch h-full max-h-full">
        <ControlBar
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            deleteFile={deleteFile}
            editDocument={editDocument}
            setEditDocument={setEditDocument}
            selectedList={selectedList}
        />
        <div className="border-t-[1px] border-blue my-2"></div>
        <ListSelector selectedList={selectedList} onSelected={onSelectedList} />
        {(isLoading || isDeleting)
            ? <Loading />
            : <>
                <div className="mt-2 mb-2">
                    <SearchBar filterinPattern={filteringPattern} setFilteringPattern={setFilteringPattern} />
                </div>
                <Table
                    documents={documents}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    sortingProperty={sortingProperty}
                    setSortingProperty={setSortingProperty}
                    sortingDescending={sortingDescending}
                    setSortingDescending={setSortingDescending}
                    onEditDocument={(doc) => setEditDocument(doc)}
                />
                <Pagination
                    itemsCount={documentsCount}
                    itemsPerPage={DOCUMENTS_PER_PAGE}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            </>}
    </div>
}

export default withCollectionPrivilegesRequired((props) => <KnowledgeBase {...props} />, "readAccess")
