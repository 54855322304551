import { ArrowDownTrayIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import Buttoon from "components/Buttoon";
import { useCallback } from "react";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useSetCollectionFeedbackReadMutation } from "state/api/collections";

export default function ArchiveFeedbackButton({ threadId, messageId, archived }) {
    const collId = useCurrentCollectionId();
    const [archiveFeedback] = useSetCollectionFeedbackReadMutation();
    const handleClick = useCallback(() => {
        archiveFeedback({
            collection_id: collId,
            message_uuid: messageId,
            thread_uuid: threadId,
            params: { feedback_read: !archived }
        });
    }, [archiveFeedback, archived, collId, messageId, threadId]);

    return <Buttoon className="max-sm:flex-grow flex gap-x-2 items-center justify-center w-32" onClick={handleClick}>
        {archived
            ? <><ArrowUpTrayIcon className="w-5" /><span className="">Unarchive</span></>
            : <><ArrowDownTrayIcon className="w-5" /><span className="">Archive</span></>
        }
    </Buttoon>
}