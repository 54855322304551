import Buttoon from "components/Buttoon";
import { useCallback } from "react";
import { createPrivilegesPayload, useSavePrivilegesCallback } from "./privilegesUtils";

export default function SavePrivilegesButton({ orgId, newPrivileges, organizationPrivileges, disabled, onClick, onCompleted }) {
    const savePrivileges = useSavePrivilegesCallback();

    const handleClick = useCallback(() => {
        onClick();
        savePrivileges(createPrivilegesPayload(orgId, newPrivileges, organizationPrivileges), onCompleted)
    }, [newPrivileges, onClick, onCompleted, orgId, organizationPrivileges, savePrivileges]);

    return <Buttoon onClick={handleClick} disabled={disabled}>
        Save
    </Buttoon>
}