import { CameraIcon, FolderOpenIcon } from "@heroicons/react/24/solid";
import Modal from "../Modal";
import Buttoon from "components/Buttoon";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { useState, useRef, useCallback, useMemo } from "react";
import EnterAwareInput from "components/EnterAwareInput";
import { getNameAndExtension } from "utils/Utils";
import Loading from "../Loading";

function ImageRow({ idx, image, src, setImages, setSrcs }) {
    const title = image.name;
    const type = image.extension;

    const [edit, setEdit] = useState(false);
    const onRename = useCallback((title) => {
        setEdit(false);
        setImages(prev => {
            const tmp = [...prev];
            tmp[idx].name = title;
            return tmp;
        })
    }, [idx, setImages]);
    const deleteFile = useCallback(() => {
        setImages(prev => prev.filter((el, i) => idx !== i));
        setSrcs(prev => prev.filter((el, i) => idx !== i));
    }, [idx, setImages, setSrcs]);

    return <div className="flex items-center justify-between gap-x-5 text-xs text-xs text-white">
        <div className="flex items-center flex-grow gap-x-2">
            <span className="w-6 text-right">{idx + 1} -</span>
            {src
                ? <img src={src} alt={title} className="h-6 w-6 object-cover rounded-sm" />
                : <Loading size="w-6 h-6" stroke="border-[3px]" />
            }
            {edit
                ? <div className="flex items-center flex-grow gap-x-1">
                    <EnterAwareInput
                        name="title"
                        id="title"
                        defaultValue={title}
                        className="border-0 focus:ring-0 bg-blue w-full flex-1 text-xs px-1 rounded-md"
                        placeholder="Title"
                        onEnterPressed={onRename}
                        autofocus
                    />
                    <span>.{type}</span>
                </div>
                : <p className={"flex-grow py-2 truncate block"}>
                    {title}.{type}
                </p>
            }
        </div>

        <div className="flex gap-x-1">
            <button title="Rename" onClick={() => setEdit(true)}>
                <PencilIcon className="w-5 h-5 text-blue-light hover:text-white" />
            </button>
            <button title="Delete" onClick={() => deleteFile()}>
                <TrashIcon className="w-5 h-5 text-orange-pastel hover:text-orange" />
            </button>
        </div>
    </div>
}

export default function AttachImageModal({ show, setShow, onSubmit }) {
    const [images, setImages] = useState([]);
    const [srcs, setSrcs] = useState([]);

    const inputRef = useRef();
    const onFilesBrowsed = useCallback((event) => {
        const files = event.target.files;
        const newImages = [];
        const length = images.length;
        for (let i = 0; i < files.length; i++) {
            const img = files[i];

            newImages.push(getNameAndExtension(img.name));

            const j = length + i;
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onloadend = () => {
                const base64 = reader.result;
                setSrcs(prev => {
                    const tmp = [...prev];
                    tmp[j] = base64;
                    return tmp;
                });
            }
        }
        setImages(prev => [...prev, ...newImages]);
    }, [images.length]);

    const isLoaded = useMemo(() => images.length === srcs.length && srcs.every(el => el !== undefined), [images.length, srcs])

    const handleSubmit = useCallback(() => {
        const imgs = images.map((el, idx) => ({ title: el.name + "." + el.extension, src: srcs[idx] }));
        console.log(imgs);
        onSubmit?.(imgs);
        setShow(false);
    }, [images, onSubmit, setShow, srcs])

    return <Modal width="lg:w-[50%]" show={show} setShow={setShow}>
        <p className="text-xl lg:text-2xl text-blue-lightest">Attach Images</p>
        <p className="text-sm mb-2 text-blue-lightest">You will be able to rearrange them within the message</p>

        <div className="flex items-center justify-evenly text-blue-light">
            <button title="From Camera" className="hover:bg-blue-light hover:text-white flex-1 rounded-md">
                <CameraIcon className="w-14 my-2 mx-auto" />
            </button>
            <button title="From Storage" onClick={() => inputRef.current.click()} className="hover:bg-blue-light hover:text-white flex-1 rounded-md">
                <FolderOpenIcon className="w-14 my-2 mx-auto" />
            </button>
            <input
                type="file"
                accept=".jpg,.jpeg,.png"
                ref={inputRef}
                onChange={onFilesBrowsed}
                multiple
                name="browse"
                id="browse"
                className="hidden"
            />
        </div>
        <div className="min-h-[50px] w-full">
            {images.map((img, idx) => <ImageRow key={idx} idx={idx} image={img} src={srcs[idx]} setImages={setImages} setSrcs={setSrcs} />)}
        </div>
        <form onSubmit={handleSubmit}>
            <div className="relative flex items-baseline justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    disabled={images.length === 0 || !isLoaded}
                    width="w-16"
                    className="shrink-0"
                >
                    Attach
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow?.(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}