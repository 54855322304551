import collectionsRtkApi from "../state/api/collections";
import organizationsRtkApi from "../state/api/organizations";
import usersRtkApi from "../state/api/users";
import { setCurrentCollectionId, setCurrentOrganizationId, setCurrentUserId, setLoaded } from "../state/GeneralSlice";
import store from "../state/store";
import { readCollectionId, readOrganizationId } from "./Storage";

export async function loadUserId() {
    let userId = store.getState().general.currentUserId;
    if (userId) return;

    const response = await store.dispatch(usersRtkApi.endpoints.getCurrentUserDetails.initiate());
    const data = response?.data;
    userId = data?.uuid;
    store.dispatch(setCurrentUserId(userId));
}

async function loadOrganizationId() {
    const orgId = store.getState().general.currentOrganizationId;
    const prefOrgId = orgId || readOrganizationId();
    const response = await store.dispatch(organizationsRtkApi.endpoints.getCurrentUserOrganizations.initiate());
    const data = response?.data;

    let selectedOrgId = undefined;
    for (let idx = 0; idx < (data ? data.length : 0); idx++) {
        const orgUuid = data[idx]?.uuid;
        if (selectedOrgId === undefined || (prefOrgId && orgUuid === prefOrgId))
            selectedOrgId = orgUuid;
    }
    store.dispatch(setCurrentOrganizationId(selectedOrgId));
}

export async function loadCollectionId() {
    const orgId = store.getState().general.currentOrganizationId;
    if (!orgId)
        return;

    const collId = store.getState().general.currentCollectionId;
    const prefCollectionId = collId || readCollectionId();
    const response = await store.dispatch(collectionsRtkApi.endpoints.getAllCollections.initiate({ org: orgId }, { forceRefetch: true }));
    const data = response?.data;
    //console.log(data);
    let selectedCollectionId = undefined;
    for (let idx = 0; idx < (data ? data.length : 0); idx++) {
        const orgUuid = data[idx]?.uuid;
        if (selectedCollectionId === undefined || (prefCollectionId && orgUuid === prefCollectionId))
            selectedCollectionId = orgUuid;
    }
    store.dispatch(setCurrentCollectionId(selectedCollectionId));
}

export async function load() {
    store.dispatch(setLoaded(false));
    await loadUserId();
    await loadOrganizationId();
    await loadCollectionId();
    store.dispatch(setLoaded(true));
}