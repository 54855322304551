import classNames from "classnames";
import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    width?: string;
    height?: string;
    padding?: string;
    backgroud?: string;
    classNameOverride?: string;
}

function Buttoon({
    children,
    width = "w-32",
    height = "h-9",
    padding = "py-2",
    backgroud = "bg-blue",
    classNameOverride,
    ...props
}: ButtonProps): React.JSX.Element {
    if (!props.type) props.type = "button";
    return (
        <button
            {...props}
            className={classNames(
                { "bg-blue text-sm hover:enabled:bg-blue-light text-white rounded-md": !classNameOverride },
                { [classNameOverride]: classNameOverride },
                { [width]: !classNameOverride },
                { [height]: !classNameOverride },
                { [backgroud]: !classNameOverride },
                { [padding]: !classNameOverride },
                "block text-center font-semibold disabled:opacity-30",
                props.className || ""
            )}
        >
            {children}
        </button>
    );
}

export default Buttoon;
