import { useCallback } from "react";
import Modal from "../Modal";
import TasksViewer from "./TasksViewer";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useRemoveDocumentsInUploadMutation } from "state/api/collections";
import Buttoon from "components/Buttoon";
import { CogIcon } from "@heroicons/react/24/solid";
import { removeTask, useCompletedTasks } from "state/TasksSlice";
import { removeTask as WFremoveTask, useCompletedTasks as WFuseCompletedTasks } from "state/WFTasksSlice";
import { useDispatch } from "react-redux";

export default function TasksModal({ tasks, show, setShow, wf = false }) {
    const collId = useCurrentCollectionId();
    // const actualRemoveTask = wf ? WFremoveTask : removeTask;
    // const actualUseCompletedTasks = wf ? WFuseCompletedTasks : useCompletedTasks;


    const [removeUpload] = useRemoveDocumentsInUploadMutation();
    const completed = useCompletedTasks();
    const completedWF = WFuseCompletedTasks();
    const dispatch = useDispatch();
    const clearAll = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        if (wf) {
            completed.forEach((el) => {
                removeUpload({ collection_id: collId, doc_uid: el });
                dispatch(wf ? WFremoveTask(el) : removeTask(el));
            });
        } else {
            completedWF.forEach((el) => {
                removeUpload({ collection_id: collId, doc_uid: el });
                dispatch(wf ? WFremoveTask(el) : removeTask(el));
            });
        }
    }, [collId, completed, completedWF, dispatch, removeUpload, wf]);


    return <Modal width="lg:w-[50%]" show={show} setShow={setShow}>
        {tasks.length === 0
            ? <div className="text-gray-dark flex flex-col justify-center items-center">
                <CogIcon className="h-28 w-28 opacity-20" />
                <span className="opacity-40 text-lg">Nothing to show</span>
            </div>
            : <TasksViewer files={tasks} wf={wf} />
        }
        <form onSubmit={clearAll} className="text-center mt-4">
            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    disabled={wf ? completedWF.length === 0 : completed.length === 0}
                    width="w-16"
                    className="shrink-0"
                >
                    Clear All
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                    disabled={false}
                >
                    Close
                </Buttoon>
            </div>
        </form>
    </Modal>
}