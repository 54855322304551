import DropDown from "components/DropDown"
import { OrganizationPrivilegesSelection, UserPrivilegesSelection } from "./PrivilegesSelection"
import UserInfo from "./UserInfo"
import { useGetOrganizationQuery } from "state/api/organizations"

function UserDropDown({ collections, user, originalPrivileges, newPrivileges, organizationPrivileges }) {
    return <DropDown titleContent={<UserInfo user={user} />}>
        <div className="pt-4">
            {collections.map((c) => <div key={c.uuid} className="px-3">
                <div className="mb-2 text-blue-lightest font-bold">{c.name}</div>
                <UserPrivilegesSelection
                    collectionId={c.uuid}
                    userId={user.uuid}
                    originalPrivileges={originalPrivileges}
                    newPrivileges={newPrivileges}
                    organizationPrivileges={organizationPrivileges}
                />
            </div>)}
        </div>
    </DropDown>
}

function OrganizationDropDown({ orgId, collections, organizationPrivileges, setOrganizationPrivileges }) {
    const { data } = useGetOrganizationQuery({ org_uuid: orgId }, { skip: !orgId });

    return <DropDown titleContent={<div className="font-bold text-md">Whole Organization <span className="font-normal">({data?.name})</span></div>} >
        <div className="pt-4">
            {
                collections.map((c) => <div key={c.uuid} className="px-3">
                    <div className="mb-2 text-blue-lightest font-bold">{c.name}</div>
                    <OrganizationPrivilegesSelection
                        collectionId={c.uuid}
                        organizationPrivileges={organizationPrivileges}
                        setOrganizationPrivileges={setOrganizationPrivileges}
                    />
                </div>)
            }
        </div>
    </ DropDown>
}

export default function PrivilegesByUser({ orgId, members, collections, originalPrivileges, newPrivileges, organizationPrivileges, setOrganizationPrivileges }) {
    return <>
        <OrganizationDropDown
            orgId={orgId}
            collections={collections}
            organizationPrivileges={organizationPrivileges}
            setOrganizationPrivileges={setOrganizationPrivileges}
        />
        <div className="mb-6" />
        {members.map((m) =>
            <div key={m.uuid} className="mb-6">
                <UserDropDown
                    collections={collections}
                    user={m}
                    originalPrivileges={originalPrivileges}
                    newPrivileges={newPrivileges}
                    organizationPrivileges={organizationPrivileges}
                />
            </div>
        )}
    </>
}