import { ShieldCheckIcon, UserCircleIcon, UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

function TableHeader({ children, center = false, className }) {
    return <th
        className={classNames("px-3 py-3 text-sm font-semibold text-blue-lightest", className)}
    >
        <div className={classNames("flex", { "justify-center": center })}>
            {children}
        </div>
    </th>
}

function RowContent({ children, className }) {
    return <td className={classNames("relative px-3 py-1 text-blue-lightest", className)}>
        {children}
    </td>
}

export const TableMode = {
    VIEW: 0,
    ADD: 1,
    REMOVE: 2,
    EDIT: 3,
}

export default function MembersTable({ users, admins = [], disableds = [], tableMode = TableMode.VIEW, onAction = () => { }, onAdmin = () => { } }) {
    return <table className="divide-y divide-blue-light table-fixed w-full">
        <colgroup>
            {tableMode === TableMode.ADD &&
                <col span={1} className="w-[40px]" />
            }
            {tableMode === TableMode.REMOVE &&
                <col span={1} className="w-[40px]" />
            }
            <col span={1} className="w-[55px]" />
            <col span={1} className="xl:w-[330px] max-w-[330px]" />
            <col span={1} className="" />
            {(tableMode === TableMode.REMOVE || tableMode === TableMode.VIEW || tableMode === TableMode.EDIT) &&
                <col span={1} className="w-[40px]" />
            }
            {tableMode === TableMode.EDIT &&
                <col span={1} className="w-[40px]" />
            }
        </colgroup>
        <thead className="bg-blue rounded">
            <tr>
                {tableMode === TableMode.ADD &&
                    <TableHeader>
                        <UserPlusIcon title="Add" className="w-4 h-4" />
                    </TableHeader>
                }
                {tableMode === TableMode.REMOVE &&
                    <TableHeader>
                        <UserMinusIcon title="Remove" className="w-4 h-4" />
                    </TableHeader>
                }
                <TableHeader>
                    <div className='mx-auto'>
                        <UserCircleIcon title="Add" className="w-4 h-4" />
                    </div>
                </TableHeader>
                <TableHeader>Email</TableHeader>
                <TableHeader>Username</TableHeader>
                {(tableMode === TableMode.REMOVE || tableMode === TableMode.VIEW || tableMode === TableMode.EDIT) &&
                    <TableHeader >
                        <ShieldCheckIcon title="Admin" className="w-4 h-4" />
                    </TableHeader>
                }
                {tableMode === TableMode.EDIT &&
                    <TableHeader >
                        <UserMinusIcon title="Remove" className="w-4 h-4" />
                    </TableHeader>
                }
            </tr>
        </thead>

        <tbody className="divide-y divide-blue-light">
            {
                users.map((member) => {
                    const disabled = disableds.includes(member.uuid);
                    return <tr key={member.uuid} className={classNames("hover:bg-blue", { "opacity-50": disabled })}>
                        {tableMode === TableMode.ADD &&
                            <RowContent>
                                <button
                                    type="button"
                                    onClick={() => onAction(member)}
                                    disabled={disabled}
                                    title={disabled ? "Already Member" : "Add"}
                                >
                                    <UserPlusIcon className="mt-1 w-4 text-transparent stroke-blue-lightest hover:stroke-white" />
                                </button>
                            </RowContent>
                        }
                        {tableMode === TableMode.REMOVE &&
                            <RowContent>
                                <button
                                    type="button"
                                    onClick={() => onAction(member)}
                                    title="Remove"
                                >
                                    <UserMinusIcon className="mt-1 w-4 text-transparent stroke-blue-lightest hover:stroke-white" />
                                </button>
                            </RowContent>
                        }
                        <RowContent>
                            <img src={member.picture} className="h-7 w-7 rounded-full stroke-azure stroke-2" alt='Avatar' />
                        </RowContent>
                        <RowContent className="truncate">{member.email}</RowContent>
                        <RowContent className="truncate">{member.name}</RowContent>
                        {(tableMode === TableMode.REMOVE || tableMode === TableMode.VIEW || tableMode === TableMode.EDIT) &&
                            <RowContent>
                                <button type="button" onClick={() => onAdmin(member)} disabled={tableMode === TableMode.VIEW}>
                                    <ShieldCheckIcon title="Admin"
                                        className={classNames(
                                            "mt-1 w-4 stroke-blue-lightest",
                                            { "hover:stroke-white": tableMode !== TableMode.VIEW },
                                            admins.includes(member.uuid) ? "text-green stroke-white" : "text-transparent"
                                        )}
                                    />
                                </button>
                            </RowContent>
                        }
                        {tableMode === TableMode.EDIT &&
                            <RowContent>
                                <button type="button" onClick={() => onAction(member)}>
                                    <UserMinusIcon className="mt-1 w-4 text-transparent stroke-blue-lightest hover:stroke-white" />
                                </button>
                            </RowContent>
                        }
                    </tr>
                })
            }
        </tbody>
    </table>
}