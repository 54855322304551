import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function SearchBar({ filterinPattern, setFilteringPattern }) {

    return <div className="w-full flex rounded-md border-0 ring-1 ring-blue-light focus:ring-inset focus:ring-blue-lightest">
        <input
            type="text"
            name="title"
            id="title"
            value={filterinPattern}
            onChange={(e) => setFilteringPattern(e.target.value)}
            className="block w-full rounded-l-md border-0 placeholder:text-blue-lightest text-white focus:ring-0 bg-blue"
            placeholder="Search..."
        />
        <div className="right-0 items-center inset-y-0 flex bg-blue rounded-r-md">
            <button type="button"
                className="inline-flex items-center justify-center rounded-r-md bg-blue transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none">

                <div className="flex lg:ml-6 p-2 text-blue-lightest hover:text-white">
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                </div>
            </button>
        </div>
    </div>
}