import { Fragment, useCallback, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames';

export default function ComboBox({ label = 'Select an item', name = '', initialValue = '', items = [], descriptions, tools, onSelected = (_) => { } }) {
    const [selected, setSelected] = useState("");
    const desc = descriptions || items;

    useEffect(() => {
        setSelected(initialValue);
    }, [initialValue])

    const onChange = useCallback((value) => {
        setSelected(value);
        onSelected(value);
    }, [onSelected]);

    return (
        <Listbox name={name} value={selected} onChange={onChange}>
            {({ open }) => (
                <div className="w-full">
                    <div className="flex items-center">
                        {label && <Listbox.Label className="text-sm font-medium leading-6 text-white mb-2">{label}</Listbox.Label>}
                        {tools}
                    </div>
                    <div className="w-full relative">
                        <Listbox.Button className="flex w-full items-center justify-between h-9 cursor-default rounded-md bg-blue py-1.5 px-2 text-white text-left shadow-sm sm:text-sm">
                            <div className="truncate">{desc[items.indexOf(selected)]}</div>
                            <ChevronUpDownIcon className="h-5 w-5 text-white shrink-0" aria-hidden="true" />
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-[5000] mt-1 max-h-60 w-full max-w-[98vw] overflow-auto rounded-md bg-blue py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {items.map((item, idx) => (
                                    <Listbox.Option
                                        key={idx}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-blue-light text-white' : 'text-gray',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {desc[idx]}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}
