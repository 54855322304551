// NOTE: all modules imported below may be imported from '@silevis/reactgrid'
import * as React from "react";
import { CellTemplate, Cell, Compatible, Uncertain, UncertainCompatible, getCellProperty } from "@silevis/reactgrid";
import { FunnelIcon } from "@heroicons/react/24/outline";

export interface FilteringHeaderCell extends Cell {
    type: "filteringheader";
    text: string;
    rowIdx: number;
    colIdx: number;
    filtrable: boolean;
    addFilter?: (colIdx: number) => void;
}

export class FilteringHeaderCellTemplate implements CellTemplate<FilteringHeaderCell> {
    getCompatibleCell(uncertainCell: Uncertain<FilteringHeaderCell>): Compatible<FilteringHeaderCell> {
        const text = getCellProperty(uncertainCell, "text", "string");
        const value = parseFloat(text); // TODO more advanced parsing for all text based cells
        const rowIdx = getCellProperty(uncertainCell, "rowIdx", "number");
        const colIdx = getCellProperty(uncertainCell, "colIdx", "number");
        const filtrable = getCellProperty(uncertainCell, "filtrable", "boolean");
        return { ...uncertainCell, text, value, rowIdx, colIdx, filtrable };
    }

    update(
        cell: Compatible<FilteringHeaderCell>,
        cellToMerge: UncertainCompatible<FilteringHeaderCell>
    ): Compatible<FilteringHeaderCell> {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    handleKeyDown(
        cell: Compatible<FilteringHeaderCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key: string
    ): { cell: Compatible<FilteringHeaderCell>; enableEditMode: boolean } {
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd(
        cell: Compatible<FilteringHeaderCell>,
        eventData: any
    ): { cell: Compatible<FilteringHeaderCell>; enableEditMode: boolean } {
        console.log("composition", eventData);
        return { cell, enableEditMode: false };
    }

    getClassName(cell: Compatible<FilteringHeaderCell>, isInEditMode: boolean): string {
        return cell.className;
    }

    render(
        cell: Compatible<FilteringHeaderCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<FilteringHeaderCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <TextRenderer
                text={cell.text}
                rowIdx={cell.rowIdx}
                colIdx={cell.colIdx}
                filtrable={cell.filtrable}
                addFilter={cell.addFilter}
            />
        );
    }
}

function TextRenderer({
    text,
    rowIdx,
    colIdx,
    filtrable,
    addFilter
}: {
    text: string;
    rowIdx: number;
    colIdx: number;
    filtrable: boolean;
    addFilter: (colIdx: number) => void;
}) {
    return (
        <div className="w-full flex items-center justify-between p-1">
            <p className="whitespace-no-wrap text-content select-auto flex-1 truncate">{text}</p>
            {filtrable && (
                <button
                    title="Filter"
                    onClick={() => addFilter(colIdx)}
                    className="shrink-0 text-blue-lightest hover:text-white"
                >
                    <FunnelIcon className="h-5 w-5 " />
                </button>
            )}
        </div>
    );
}
