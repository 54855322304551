import { useCurrentCollectionId } from "state/GeneralSlice";
import { emptyApi as api } from "./emptyApi";

const settingsRtkApi = api.enhanceEndpoints({ addTagTypes: ["template", "settings"] }).injectEndpoints({
    endpoints: (build) => ({
        getSettingsTemplate: build.query<SettingInfo[], CollectionSettingsArgs>({
            query: (args) => ({
                url: `/collections/${args.collectionId}/settings`
            }),
            providesTags: ["template"]
        }),
        getDynamicSettingsTemplate: build.mutation<SettingInfo[], DynamicCollectionSettingsArgs>({
            query: (args) => ({
                url: `/collections/${args.collectionId}/settings`,
                method: "POST",
                body: args.body
            })
        }),
        getValuesList: build.query<Record<string, string>, ValuesListArgs>({
            query: (args) => ({
                url: args.url
            })
        }),
        fetchSetting: build.query<any, FetchSettingArgs>({
            query: (args) => {
                return {
                    url: getUrl(args),
                    params: args.params,
                    body: args.body
                };
            },
            providesTags: (result, error, args) => [{ type: "settings", id: args.config_name }]
        }),
        updateSetting: build.mutation<any, UpdateSettingArgs>({
            query: (args) => ({
                url: getUrl(args),
                method: "PUT",
                params: args.payload,
                body: args.body
            }),
            invalidatesTags: (result, error, args) => [{ type: "settings", id: args.config_name }]
        }),
        resetSetting: build.mutation<any, FetchSettingArgs>({
            query: (args) => ({
                url: getUrl(args),
                method: "DELETE"
            }),
            invalidatesTags: (result, error, args) => [{ type: "settings", id: args.config_name }]
        }),
        setMultipleCollectionConfigs: build.mutation<any, SetMultipleCollectionConfigArgs>({
            query: (args) => ({
                url: `v2/collections/${args.collectionId}/configs`,
                method: "PUT",
                body: args.body
            }),
            invalidatesTags: (result, error, args) => [
                ...Object.keys(args.body).map((k) => ({ type: "settings" as const, id: k })),
                "template"
            ]
        }),
        setMultipleUserConfigs: build.mutation<any, SetMultipleUserConfigArgs>({
            query: (args) => ({
                url: `v2/users/me/configs`,
                method: "PUT",
                body: args.body
            }),
            invalidatesTags: (result, error, args) => [
                ...Object.keys(args.body).map((k) => ({ type: "settings" as const, id: k })),
                "template"
            ]
        })
    }),
    overrideExisting: false
});

function getUrl(args: FetchSettingArgs) {
    switch (args.scope) {
        case "user": {
            return `v2/users/me/configs/${args.config_name}`;
        }
        case "collection":
        default: {
            return `v2/collections/${args.collectionId}/configs/${args.config_name}`;
        }
    }
}

export type ValuesListArgs = {
    url: string;
};

export type CollectionSettingsArgs = {
    collectionId: string;
};

export type DynamicCollectionSettingsArgs = CollectionSettingsArgs & {
    body: string;
};

export type FetchSettingArgs = CollectionSettingsArgs & {
    scope: string;
    config_name: string;
    params?: any;
    body?: any;
};

export type UpdateSettingArgs = FetchSettingArgs & {
    payload?: {
        value?: string;
    };
    body?: any;
};

export type SetMultipleCollectionConfigArgs = CollectionSettingsArgs & {
    body: Record<string, any>;
};

export type SetMultipleUserConfigArgs = {
    body: Record<string, any>;
};

export type SettingInfo = {
    type: string;
    reset_button: boolean;
    description: string;
    endpoint: string;
    values_list_endpoint?: string;
    lower_bound?: number;
};

export default settingsRtkApi;
export const {
    useGetSettingsTemplateQuery,
    useGetDynamicSettingsTemplateMutation,
    useGetValuesListQuery,
    useFetchSettingQuery,
    useUpdateSettingMutation,
    useResetSettingMutation,
    useSetMultipleCollectionConfigsMutation,
    useSetMultipleUserConfigsMutation
} = settingsRtkApi;

export function useSpellcheckEnabled() {
    const collId = useCurrentCollectionId();
    const { data } = useFetchSettingQuery({
        collectionId: collId,
        config_name: "features.spell_check.enabled",
        scope: "user"
    });
    return data?.["features.spell_check.enabled"] ?? false;
}

export function useSpellcheckLanguage() {
    const collId = useCurrentCollectionId();
    const { data } = useFetchSettingQuery({
        collectionId: collId,
        config_name: "features.spell_check.default_language",
        scope: "user"
    });
    return data ?? "";
}
