import Modal from "../Modal";
import Buttoon from "components/Buttoon";
import { useState, useCallback, useEffect, useMemo } from "react";
import Loading from "../Loading";
import { useArchiveThreadMutation, useGetThreadFromUuidQuery } from "state/api/threads";
import { useNavigate } from "react-router-dom";

export default function ApproveThreadModal({ threadId, show, setShow }) {
    const { data, isFetching } = useGetThreadFromUuidQuery({ thread_uuid: threadId }, { refetchOnMountOrArgChange: true });

    const [content, setContent] = useState("");
    useEffect(() => {
        if (data?.local_memory?.draft) setContent(data.local_memory.draft)
    }, [data?.local_memory?.draft])


    const [archive, { isLoading: isApproving }] = useArchiveThreadMutation();
    const navigate = useNavigate();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        archive({ thread_uuid: threadId, body: { approved_content: content } }).then(() => {
            setShow(false);
            navigate("../");
        })
    }, [archive, content, navigate, setShow, threadId])

    const isLoading = useMemo(() => isFetching || isApproving, [isApproving, isFetching]);

    return <Modal width="lg:w-[50%]" show={show} setShow={setShow}>
        <p className="text-xl lg:text-2xl text-blue-lightest">Approve Thread</p>
        <p className="text-sm mb-2 text-blue-lightest">You can do the final changes to the draft</p>

        <form onSubmit={handleSubmit} className="relative">
            {isLoading
                ? <Loading />
                : <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                    <label htmlFor="description" className="sr-only">
                        Content
                    </label>
                    <textarea
                        rows={2}
                        name="content"
                        id="content"
                        value={content}
                        required
                        onChange={(e) => setContent(e.target.value)}
                        className="block w-full h-[50vh] resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                        placeholder="Write your text..."
                    />
                </div>
            }

            <div className="relative flex items-baseline justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    disabled={content.length === 0 || isLoading}
                    width="w-16"
                    className="shrink-0"
                >
                    Approve
                </Buttoon>
                <Buttoon
                    type="button"
                    disabled={isLoading}
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}