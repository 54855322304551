import { useFetchSetting } from "./settingsUtils";
import { useCallback, useMemo } from "react";
import _ from "lodash";

function ChoiceCheckbox({ value, setValue, current }) {

    const updateSelection = useCallback((e) => {
        if (e.target.checked) setValue(prev => [...prev, current.key]);
        else setValue(prev => prev.filter(v => v !== current.key));
    }, [current, setValue])

    return <div className="w-1/2 flex gap-x-1 items-center">
        <input
            name={current}
            id={current}
            type="checkbox"
            checked={value.includes(current.key)}
            onChange={updateSelection}
            className="h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
        />
        <label htmlFor="prompt" className="font-normal text-xs truncate pr-2">
            {current.value}
        </label>
    </div>
}

export default function SettingMultipleChoice({ setting, value, setValue, onLoaded }) {
    const choices = useMemo(() => _.transform(
        setting.values_list,
        (result, value, key) => {
            result.push({ key, value });
        },
        []),
        [setting.values_list]);

    useFetchSetting(setting, value, setValue, onLoaded);

    return <div className="w-full">
        <label htmlFor="prompt" className="block text-sm font-medium leading-6 mb-2">
            {setting.description}
        </label>
        <div className="flex flex-wrap gap-y-1">
            {choices.map((c, idx) => <ChoiceCheckbox key={idx} value={value} setValue={setValue} current={c} />)}
        </div>
    </div>
}