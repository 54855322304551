import { BookOpenIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Modal from "../Modal";
import Separator from "components/Separator";
import classNames from "classnames";

export function Recommendation({ recommendation, idx }) {

    const { cover, title, author, reason } = recommendation;

    return <div className={classNames("flex gap-x-4", { "mt-7": idx !== 0 })}>
        <div className="w-1/12 flex justify-center">
            <div className="w-full">
                <img src={cover} alt="cover" />
            </div>
        </div>
        <div className="flex-1 flex flex-col gap-y-1">
            <h2 className="font-bold text-xl">{title}</h2>
            <h1 className="font-medium text-lg">{author}</h1>
            <Separator />
            <p className="mt-2">{reason}</p>
        </div>
    </div>
}


export default function Recommendations({ recommendations }) {
    const [show, setShow] = useState(false);

    return <>
        {recommendations && recommendations.length > 0 && <>
            <BookOpenIcon
                title="Recommendations"
                className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                onClick={() => setShow(true)}
            />
            <Modal showCross={true} show={show} setShow={setShow}>
                <div className="text-white sm:max-w-6xl">
                    {recommendations && recommendations.map((el, idx) => <Recommendation key={idx} recommendation={el} idx={idx} />)}
                </div>
            </Modal>
        </>
        }
    </>
}