import { useAuth0 } from "@auth0/auth0-react";

export default function Avatar() {
    const { user } = useAuth0()
    return <>
        <span className="sr-only">Your profile</span>
        <img
            className="h-9 w-9 rounded-full stroke-azure stroke-2"
            src={user.picture}
            alt="Avatar"
        />
    </>
}