import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrganizationQuery } from '../../../state/api/organizations';
import MembersDetails from './MembersDetails';
import Header from 'components/Header';
import UsageOverview from '../UsageOverview';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { withGodPrivilegesRequired } from 'utils/Privileges';
import CollectionsList from './CollectionsList';

function OrganizationDetails() {
    const { orgId } = useParams();
    const { data } = useGetOrganizationQuery({ org_uuid: orgId }, { skip: !orgId });

    const navigate = useNavigate();

    return <div className="mx-auto min-w-7xl max-w-7xl pt-2 pb-4">
        <Header
            title={data?.name}
            subtitle={data?.description}
            backTitle="All Organizations"
            backRoute="/admin"
        />
        <div>
            <div className="border-t border-gray mt-8">
                <MembersDetails orgId={orgId} />
            </div>
            <div className="border-t border-gray mt-8">
                <div className="flex items-center justify-between mt-3">
                    <p className="text-xl lg:text-2xl text-blue-lightest">Collections</p>
                    <button title="Manage" onClick={() => navigate("collections")} >
                        <ChevronRightIcon className="w-8 h-8 text-blue-lightest hover:text-white" />
                    </button>
                </div>
                <CollectionsList orgId={orgId} />
            </div>
            <div className="border-t border-gray mt-8">
                <div className="flex items-center justify-between mt-3">
                    <p className="text-xl lg:text-2xl text-blue-lightest">Usage Overview</p>
                    <button title="Manage" onClick={() => navigate("budgets")} >
                        <ChevronRightIcon className="w-8 h-8 text-blue-lightest hover:text-white" />
                    </button>
                </div>
                <UsageOverview orgId={orgId} />
            </div>
        </div>
    </div>
}

export default withGodPrivilegesRequired((props) => <OrganizationDetails {...props} />)