import { emptyApi as api } from "./emptyApi";

const usersRtkApi = api.enhanceEndpoints({ addTagTypes: ["user"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getAllUsers:
                build.query<UserDetails[], GetAllUsersArgs>({
                    query: (args) => ({
                        url: 'users/all',
                        params: args,
                    })
                }),
            getCurrentUserDetails:
                build.query<UserDetails, undefined>({
                    query: () => ({
                        url: 'users/me'
                    }),
                }),
            getUserDetails:
                build.query<UserDetails, UserIdArgs>({
                    query: (args) => ({
                        url: `users/${args.user_uuid}`,
                    }),
                }),
        }),
        overrideExisting: false,
    });

export default usersRtkApi;

export type GetAllUsersArgs = {
    search_query?: string,
}

export type UserDetails = {
    uuid: string,
    sid: string,
    name: string,
    email: string,
    picture: string
}

export type UserIdArgs = {
    user_uuid: string
}

export const {
    useGetAllUsersQuery,
    useGetCurrentUserDetailsQuery,
    useGetUserDetailsQuery,
} = usersRtkApi;