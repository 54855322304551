import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useMemo, useState } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import _ from "lodash";

export default function MarkdownCode({ children, className }) {
    const isMultiline = useMemo(() => children && _.isString(children) && children.includes("\n"), [children]);
    const language = useMemo(() => {
        if (!className) return "";
        const idx = className.indexOf("language-");
        if (idx === -1) return "";
        return className.substring(className.indexOf("-", idx) + 1);
    }, [className]);

    const [wrap, setWrap] = useState(false);
    const [showNumbers, setShowNumbers] = useState(false);

    return <>
        {isMultiline
            ? <div className={classNames("relative bg-gray-darkest rounded-md p-0 my-2 text-xs")}>
                <div className="flex items-center justify-between p-1">
                    <div className="flex items-center gap-x-4">
                        <div className="flex items-center gap-x-1">
                            <input
                                type="checkbox"
                                className="h-3 w-3 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
                                checked={wrap}
                                onChange={() => setWrap(prev => !prev)}
                            />
                            <label className="font-normal text-xs truncate pr-2">
                                Wrap lines
                            </label>
                        </div>
                        <div className="flex items-center gap-x-1">
                            <input
                                type="checkbox"
                                className="h-3 w-3 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
                                checked={showNumbers}
                                onChange={() => setShowNumbers(prev => !prev)}
                            />
                            <label className="font-normal text-xs truncate pr-2">
                                Show line numbers
                            </label>
                        </div>
                    </div>
                    <button title="Copy" className="">
                        <ClipboardDocumentListIcon
                            className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                            onClick={() => { navigator.clipboard.writeText(children) }}
                        />
                    </button>
                </div>
                <SyntaxHighlighter
                    language={language}
                    wrapLongLines={wrap}
                    showLineNumbers={showNumbers}
                    style={tomorrowNight}
                    customStyle={{ overflowX: wrap ? "hidden" : "auto", borderBottomRightRadius: "0.375rem", borderBottomLeftRadius: "0.375rem" }}
                >
                    {children}
                </SyntaxHighlighter>
            </div >
            : <code className={classNames(className, "font-bold text-gray-dark")}>
                {children}
            </code>
        }
    </>
} 