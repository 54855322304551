import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ComponentsMap } from "./SettingComponentesMap";
import classNames from "classnames";
import { RESET_VALUE, useSettingState } from "./settingsUtils";
import useBus, { dispatch } from "use-bus";
import Loading from "../Loading";
import { generateId } from "utils/Utils";

function Empty() {
    return <></>
}

const SettingComponentInternal = memo(function SettingComponentInternal(props) {
    const { setting, value, setValue, onLoad, onValid, idx } = props;

    const requestResetRef = useRef(false);
    useBus("request-reset", () => requestResetRef.current = true, []);

    useEffect(() => {
        if (requestResetRef.current && value !== RESET_VALUE) {
            //console.log("RESETTIAMO", setting.config_name);
            dispatch("apply-reset");
            requestResetRef.current = false;
        }
    }, [setting.config_name, value]);

    const onLoaded = useCallback(() => {
        onLoad(idx);
    }, [idx, onLoad])

    const setValid = useCallback((value) => {
        onValid(idx, value);
    }, [idx, onValid])

    const Component = useMemo(() => ComponentsMap[props.setting.type] ?? Empty, [props.setting.type]);

    return <div className={classNames(props.setting.type, props.className)}>
        <Component {...props} setting={setting} value={value} setValue={setValue} onLoaded={onLoaded} setValid={setValid} />
    </div>
});

const SettingComponent = ({ setting, idx, isReady, isLoading, onChange, onLoaded, isInputValid, onValid, className }) => {
    const [key, setKey] = useState(generateId());
    useEffect(() => {
        setKey(generateId());
    }, [setting.config_name])

    const { value, setValue } = useSettingState(setting);
    const charging = useMemo(() => !setting.contents && setting.config_name && (value === undefined || value === RESET_VALUE), [setting.config_name, setting.contents, value])

    return <div className={classNames(className, "relative")}>
        {charging && <Loading className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50" />}
        <SettingComponentInternal
            key={key}
            className={classNames(className, { "invisible": charging })}
            idx={idx}
            setting={setting}

            value={value}
            setValue={setValue}
            onChange={onChange}

            isValid={isInputValid[idx] === undefined ? true : isInputValid[idx]}
            onValid={onValid}
            onLoad={onLoaded}
        />
    </div>
}

export default function RenderComponents({ contents, classNameCallback, ...props }) {
    const callback = useCallback((idx, setting) => classNameCallback?.(idx, setting) ?? "", [classNameCallback]);

    const components = useMemo(() => {
        return contents
            ? contents.map((setting, idx) => <SettingComponent key={idx} idx={idx} setting={setting} className={callback(idx, setting)} {...props} />)
            : undefined
    },
        [callback, contents, props]
    )
    return <>{components}</>
}