import classNames from "classnames";
import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { ComponentsMap } from "./SettingComponentesMap";
import { SettingsContext, getConfigFromState } from "./settingsUtils";
import RenderComponents from "./SettingsComponent";

export default function SettingsContainer({ template, onChange, onLoadingComplete, onValidInput, className, Renderer = ContainerRender, ...props }) {
    const validInputs = useMemo(() => template ? template.filter(t => Object.keys(ComponentsMap).includes(t.type)) : undefined, [template]);

    const [isLoaded, setLoaded] = useState([false]);
    const isLoading = useMemo(() => isLoaded.some((v) => !v), [isLoaded]);
    const isReady = useMemo(() => validInputs && validInputs.length === isLoaded.length, [isLoaded.length, validInputs]);

    //console.log("IsReady", path, isReady);
    //console.log("loaded", path, isLoaded);

    const { values: state } = useContext(SettingsContext);
    useEffect(() => {
        if (validInputs) {
            const arr = new Array(validInputs.length);
            for (let i = 0; i < arr.length; i++) {
                if (validInputs[i].contents) {
                    arr[i] = false;
                    continue;
                }
                if (!validInputs[i].config_name) {
                    arr[i] = true;
                    continue;
                }
                if (getConfigFromState(state, validInputs[i].config_name) !== undefined) {
                    arr[i] = true;
                }
                else {
                    arr[i] = false;
                }
            }
            setLoaded(arr);
        }
    }, [state, validInputs]);

    const updateLoading = useCallback((idx) => {
        //console.log("LOADED", idx);
        setLoaded((prev) => {
            const tmp = [...prev];
            tmp[idx] = true;
            return tmp;
        });
    }, []);

    //console.log("loading...", isLoaded);

    useEffect(() => {
        if (!isLoading) {
            //console.log("LOADING COMPLETE", isLoaded);
            onLoadingComplete?.();
        }
    }, [isLoaded, isLoading, onLoadingComplete]);

    //**************************
    const [isInputValid, setInputValid] = useState([]);
    const onValid = useCallback((idx, value) => {
        setInputValid(prev => {
            const tmp = [...prev];
            tmp[idx] = value;
            return tmp;
        })
    }, []);
    const isValid = useMemo(() => !isInputValid.some(el => el === false), [isInputValid]);
    //console.log("VALID", isInputValid);

    useEffect(() => {
        onValidInput?.(isValid);
    }, [isValid, onValidInput])

    return <Renderer
        contents={validInputs}
        isReady={isReady}
        isLoading={isLoading}

        onChange={onChange}
        onLoaded={updateLoading}

        isInputValid={isInputValid}
        onValid={onValid}

        className={className}
        {...props}
    />
}

function ContainerRender({ contents, isReady, className, ...childProps }) {
    return <div className={classNames("flex h-full flex-col gap-y-4", className)}>
        <RenderComponents contents={contents} {...childProps} />
    </div>
}

export function SettingContainerWrapper(props) {
    const { setting, onLoaded, onChange, setValid, Renderer, className, others } = props;
    //console.log("wrapper", props, others);

    return <SettingsContainer
        template={setting.contents}
        onLoadingComplete={onLoaded}
        onChange={onChange}
        onValidInput={setValid}
        Renderer={Renderer}
        className={className}
        {...others}
    />
}