import { useMeasure } from "@uidotdev/usehooks";
import ReactTextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import React from "react";

export const triggerKey = { key: "\x06", keyCode: 6, bubbles: true };

export function useTriggerEditCallback(rowIdx: number, colIdx: number, cellType: string) {
    const triggerEdit = React.useCallback(() => {
        //occhio che con header rowidx parte da 1
        const el = [...document.querySelectorAll(`.rg-${cellType}-cell`)];
        const r = (rowIdx + 1).toString();
        const c = colIdx.toString();
        const f = el.find((e) => {
            const element = e as HTMLDivElement;
            return element.dataset.cellRowidx === r && element.dataset.cellColidx === c;
        }) as HTMLElement;

        f.dispatchEvent(new KeyboardEvent("keydown", triggerKey));
    }, [cellType, colIdx, rowIdx]);

    return triggerEdit;
}

export interface EditorRenderProps extends TextareaAutosizeProps {
    rowIdx: number;
    colIdx: number;
    onHeightChanged: (rowIdx: number, colIdx: number, height: number) => void | undefined;
    cellType: string;
    isHeader?: boolean;
}

export function EditorRenderer({
    rowIdx,
    colIdx,
    onHeightChanged,
    cellType,
    isHeader = false,
    ...props
}: EditorRenderProps) {
    const [ref, { height }] = useMeasure();

    React.useEffect(() => {
        onHeightChanged?.(rowIdx, colIdx, height);
    }, [colIdx, height, onHeightChanged, rowIdx]);

    const txtRef = React.useRef<HTMLTextAreaElement>();
    React.useEffect(() => {
        const input = txtRef.current;
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
    }, [ref]);

    React.useEffect(() => {
        //occhio che con header rowidx parte da 1
        const el = [...document.querySelectorAll(`.rg-${cellType}-cell`)];
        const r = (rowIdx + 1).toString();
        const c = colIdx.toString();
        const f = el.find((e) => {
            const element = e as HTMLDivElement;
            return element.dataset.cellRowidx === r && element.dataset.cellColidx === c;
        }) as HTMLElement;

        let parent: HTMLElement = txtRef.current.parentElement;
        while (!parent?.className.includes(`rg-${cellType}-celleditor`)) {
            parent = parent.parentElement;
        }

        //con sticky row aggiungere offset
        const offset = isHeader ? 0 : document.querySelector(".rg-pane-top")?.clientHeight;
        console.log("DDDFF", offset, isHeader);

        parent.style.top = (Number(f.style.top.replace("px", "")) + offset).toString() + "px";
        parent.style.left = f.style.left;
        parent.style.visibility = "visible";
    }, [cellType, colIdx, isHeader, rowIdx]);

    return (
        <ReactTextareaAutosize
            className="p-1 resize-none overflow-hidden min-h-full w-full"
            ref={(input) => {
                ref(input);
                txtRef.current = input;
            }}
            {...props}
        />
    );
}
